import React, { useState, useEffect } from 'react'
import './index.scss'
import { useSharedData } from 'bossjob-remote/dist/hooks'
import { getCountryId } from 'helpers/country'
import { uniqBy } from 'lodash-es'
import { SingleCascaderSelect } from 'app/(new-pages)/components/allSelect/baseSelect/pc/SingleCascaderSelect'

type Variant = 'standard' | 'borderLess'
interface initProps {
  label?: string
  variant?: Variant
  required?: boolean
  error?: any
  onChange?: (any) => void
  preference?: any
  placeholder?: string
  suffixIcon?: any
}

const getNodeById = (opts, id) => {
  function findNodeById(node, targetId) {
    if (node.id === targetId) {
      return node
    }

    if (node.children && node.children.length > 0) {
      for (let child of node.children) {
        const result = findNodeById(child, targetId)
        if (result) {
          return result
        }
      }
    }

    return null
  }

  let target

  for (let index = 0; index < opts.length; index++) {
    const element = opts[index]
    target = findNodeById(element, id)

    if (target) {
      break
    }
  }

  return target
}

export const CascaderComponentNew = ({
  label = '',
  variant,
  required = false,
  error,
  onChange,
  preference,
  suffixIcon: SuffixIcon,
  placeholder,
  ...rest
}: initProps) => {
  const configCountryLists = useSharedData('CONFIG')?.country_lists ?? []
  const AllText = useSharedData('DICTIONARY')?.companyDetail?.overview?.All
  const countryId = getCountryId()
  const showFirstLevel = /^(?:193|229|38|29|154|227|203|190)$/.test(String(countryId))
  const findLists = configCountryLists?.find((e) => e.id === countryId)
  const [value, setValue] = useState<any[]>([])
  const [countryLists, setCountryLists] = useState<any[]>([])

  useEffect(() => {
    if (findLists?.id) {
      const regionLists = findLists.region_lists.map((e) => ({
        ...e,
        type: 'region'
      }))
      regionLists?.unshift({
        id: findLists.id,
        key: findLists.key,
        value: showFirstLevel ? AllText + ' ' + findLists.value : findLists.value,
        type: 'country'
      })

      const uniArr = uniqBy(regionLists, 'id')
      const list = []

      uniArr.map((e) => {
        const item = {
          id: e.id,
          key: e.key,
          label: e.value || e.display_name,
          value: e.id,
          type: e.type || 'region',
          children: []
        }

        if (e.location_lists?.length > 0 && !showFirstLevel) {
          item.children.push({
            id: e.id,
            value: e.id,
            key:
              e.location_lists.length === 1 && e.location_lists[0]?.key === 'overseas'
                ? 'overseas'
                : e.key,
            type: 'region',
            label: `${AllText} ${item.label}`
            // <span className='reset-label'>
            //   {AllText} {item.label}
            // </span>
          })
          e.location_lists.map((k) => {
            item.children.push({
              id: k.id,
              value: k.id,
              key: k.key,
              type: 'location',
              label: k.value //<span className='reset-label'>{k.value}</span>
            })
          })
        } else {
          item.label = showFirstLevel
            ? e.value || e.display_name
            : // <span className='reset-label'>{e.value || e.display_name}</span>
              e.value || e.display_name
        }

        list.push(item)
      })
      if (!showFirstLevel) {
        list[0].children = [
          {
            id: findLists.id,
            key: findLists.key,
            value: findLists.id,
            label: `${AllText + ' ' + findLists.value}`, // <span className='reset-label'>{AllText + ' ' + findLists.value}</span>,
            type: 'country'
          }
        ]
      }
      setCountryLists(list)
    }
  }, [findLists, AllText, showFirstLevel])

  useEffect(() => {
    if (preference?.id && countryLists?.length > 0) {
      const { pref_country_id, pref_region_id, location_id } = preference || {}
      let arr = []
      if (pref_country_id) {
        arr = showFirstLevel ? [pref_country_id] : [pref_country_id, pref_country_id]
      } else if (pref_region_id) {
        arr = showFirstLevel ? [pref_region_id] : [pref_region_id, pref_region_id]
      } else if (location_id) {
        for (const country of countryLists) {
          const location = country.children.find((k) => k.id === location_id)
          if (location) {
            arr = [country.id, location_id]
            break
          }
        }
      }

      setValue(arr)

      // TODO: need refactor @taylor
      if (countryLists.length) {
        countryLists.forEach((country) => {
          const _id = pref_country_id || pref_region_id
          if ((pref_country_id || pref_region_id) && country.id == _id) {
            onChange(country)
          } else {
            const children = country.children
            if (location_id && children) {
              const country = children.find((e) => e.id === location_id)
              if (country) {
                onChange(country)
              }
            }
          }
        })
      }
    }
  }, [preference, countryLists, showFirstLevel])

  const handleChange = (value, selectedOptions) => {
    // console.log('value__', value, selectedOptions)
    if (selectedOptions?.length > 0) {
      const result = selectedOptions[selectedOptions?.length - 1]
      let originalTarget = getNodeById(countryLists, result.value)

      onChange(originalTarget)
      setValue(value || [])
    } else {
      onChange('')
      setValue(value || [])
    }
  }

  // console.log('countryListscountryLists', countryLists)

  return (
    <>
      <SingleCascaderSelect
        style={{ width: '100%' }}
        placeholder={placeholder}
        options={countryLists}
        value={value}
        onChange={handleChange}
        onlySearchInLastedLayer
        cascaderStyles={{ maxHeight: 300, overflow: 'hidden' }}
      />
    </>
  )
}
