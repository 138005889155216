'use client'
import React, { useEffect, useCallback, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import styled from '@emotion/styled'
import { usePathname } from 'next/navigation'

import { Button } from 'antd'
import classNames from 'classnames'
import { isMobileDevice } from 'helpers/utilities'

import { getCookie, setCookie, removeCookie } from 'helpers/cookies'
import configuredAxios from 'helpers/configuredAxios'
import { getDeviceUuid } from 'helpers/guest'
import { postNotification } from 'bossjob-remote/dist/clientStorage'
import { useSharedData } from 'bossjob-remote/dist/hooks'

import bkgd from './bkgd.png'
import { path } from 'ramda'

const IconClose = ({ onClick }) => (
  <svg
    className='close'
    xmlns='http://www.w3.org/2000/svg'
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    onClick={onClick}
  >
    <path
      d='M15.3033 5.87523C15.6288 5.54979 15.6288 5.02215 15.3033 4.69671C14.9779 4.37128 14.4502 4.37128 14.1248 4.69671L10 8.8215L5.87523 4.69671C5.54979 4.37128 5.02215 4.37128 4.69671 4.69671C4.37128 5.02215 4.37128 5.54979 4.69671 5.87523L8.8215 10L4.69671 14.1248C4.37128 14.4502 4.37128 14.9779 4.69671 15.3033C5.02215 15.6288 5.54979 15.6288 5.87523 15.3033L10 11.1785L14.1248 15.3033C14.4502 15.6288 14.9779 15.6288 15.3033 15.3033C15.6288 14.9779 15.6288 14.4502 15.3033 14.1248L11.1785 10L15.3033 5.87523Z'
      fill='black'
      fillOpacity='0.2'
    />
  </svg>
)

const IconStar = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'>
    <path
      d='M5.95184 3.79656C6.28094 2.93362 7.50174 2.93362 7.83085 3.79656L8.95358 6.74047C9.05812 7.0146 9.27718 7.2294 9.55331 7.32854L12.5006 8.38671C13.3883 8.7054 13.3883 9.96074 12.5006 10.2794L9.55331 11.3376C9.27718 11.4367 9.05812 11.6515 8.95358 11.9257L7.83085 14.8696C7.50174 15.7325 6.28094 15.7325 5.95184 14.8696L4.82911 11.9257C4.72456 11.6515 4.5055 11.4367 4.22938 11.3376L1.28207 10.2794C0.394421 9.96074 0.394421 8.7054 1.28207 8.38671L4.22938 7.32854C4.5055 7.2294 4.72456 7.0146 4.82911 6.74048L5.95184 3.79656Z'
      fill='black'
    />
    <path
      d='M12.9506 0.383954C13.0554 0.10932 13.4439 0.10932 13.5486 0.383954L14.0477 1.69254C14.081 1.77978 14.1507 1.84814 14.2386 1.87969L15.5596 2.35399C15.8421 2.45541 15.8421 2.85493 15.5596 2.95636L14.2386 3.43065C14.1507 3.4622 14.081 3.53056 14.0477 3.61781L13.5486 4.92639C13.4439 5.20102 13.0554 5.20102 12.9506 4.92639L12.4516 3.61781C12.4183 3.53056 12.3486 3.4622 12.2607 3.43065L10.9397 2.95636C10.6572 2.85493 10.6572 2.45541 10.9397 2.35399L12.2607 1.87969C12.3486 1.84814 12.4183 1.77978 12.4516 1.69254L12.9506 0.383954Z'
      fill='black'
    />
  </svg>
)

const NotifyRender = ({ dictionary }) => {
  const pathname = usePathname()
  const { newGetStarted } = dictionary || {}

  const sessionid = getCookie('sessionid')
  const [initAnimate, setInitAnimate] = useState(false)
  const timerRef = useRef()
  const nowRef = useRef(null)
  const isMobile = isMobileDevice()

  const metricsRequest = useCallback(
    async ({ type, page, click_type, click_time }) => {
      try {
        const axios = await configuredAxios('jobseeker')
        const device_udid = await getDeviceUuid()
        const data = await axios.post(`/metrics/web/jobseeker-login-signup`, {
          type,
          device_udid,
          page,
          click_type,
          click_time
        })
      } catch (err) {
        throw new Error(err)
      }
    },
    [configuredAxios, getDeviceUuid]
  )

  const handleCloseClick = () => {
    const firstOpenTimestamp = localStorage?.getItem('__firstOpenTimestamp')
    sessionStorage.setItem('__keepHideBeforeExit', 'yes')
    setInitAnimate(false)
    metricsRequest({
      type: 'click',
      click_type: 'close_pop_up',
      click_time: Math.round((Date.now() - Number(firstOpenTimestamp)) / 1000)
    })
  }

  const handleShowLoginModalClick = () => {
    const firstOpenTimestamp = localStorage?.getItem('__firstOpenTimestamp')
    postNotification('SHOW_LOGIN_MODAL')
    localStorage.setItem('clickSource', 'sign_in_now')
    metricsRequest({
      type: 'click',
      click_type: 'sign_in_now',
      click_time: Math.round((Date.now() - Number(firstOpenTimestamp)) / 1000)
    })
  }
  const needRender = !sessionid && !isMobile && !/\/(get-started|activity-page|talent?s|404)/.test(pathname)

  // useEffect(() => {
  //   const startTimestamp = getCookie('__startTimestamp')
  //   const firstOpenTimestamp = localStorage?.getItem('__firstOpenTimestamp')
  //   const clear = () => {
  //     clearTimeout(timerRef.current)
  //     timerRef.current = undefined
  //   }
  //   const timer = (limit = 12000) => {
  //     nowRef.current = Date.now()
  //     if (nowRef.current - startTimestamp > limit) {
  //       clear()
  //       setInitAnimate(true)

  //       if (!firstOpenTimestamp && needRender) {
  //         metricsRequest({
  //           // type: 'click',
  //           type: 'pop_up',
  //           page: pathname
  //         })
  //         localStorage?.setItem('__firstOpenTimestamp', nowRef.current)

  //       }

  //       return
  //     }
  //     timerRef.current = setTimeout(() => timer(limit), 1)
  //   }
  //   timer()
  //   return () => {
  //     clear()
  //     localStorage?.removeItem('clickSource')
  //   }
  // }, [needRender])

  useEffect(() => {
    const startTimestamp = getCookie('__startTimestamp')
    const firstOpenTimestamp = localStorage?.getItem('__firstOpenTimestamp')
    const keepHideBeforeExit = sessionStorage?.getItem('__keepHideBeforeExit')
    const clickSource = localStorage?.getItem('clickSource')
    const clear = () => {
      clearTimeout(timerRef.current)
      timerRef.current = undefined
    }
    const timer = (limit = 12000) => {
      nowRef.current = Date.now()
      if (nowRef.current - startTimestamp > limit) {
        clear()
        setInitAnimate(true)

        if (!firstOpenTimestamp && needRender) {
          metricsRequest({
            // type: 'click',
            type: 'pop_up',
            page: pathname
          })
          localStorage?.setItem('__firstOpenTimestamp', nowRef.current)
        }

        return
      }
      timerRef.current = setTimeout(() => timer(limit), 1)
    }
    if (sessionid) {
      clickSource === 'sign_in_now' &&
        metricsRequest({
          type: 'signed_in',
          page: 'pop_up_login'
        })
      setInitAnimate(false)
      removeCookie('__startTimestamp')
      localStorage?.removeItem('clickSource')
      sessionStorage?.removeItem('__keepHideBeforeExit')
      localStorage?.removeItem('__firstOpenTimestamp')
    } else {
      timer()
      if (keepHideBeforeExit === 'yes') {
        setInitAnimate(false)
      }
      //localStorage.removeItem('clickSource')
    }
    return () => {
      clear()
      // localStorage?.removeItem('clickSource')
    }
  }, [sessionid])

  if (needRender) {
    return (
      <NotifyRenderWrapped
        className={classNames({
          animIn: initAnimate,
          animOut: !initAnimate
        })}
      >
        <div className='content'>
          <div className='head'>
            <h3>
              {newGetStarted?.registerGuide?.title}
              <IconStar />
            </h3>
            <IconClose onClick={handleCloseClick} />
          </div>

          <p>{newGetStarted?.registerGuide?.desc}</p>
          <Button type='primary' onClick={handleShowLoginModalClick}>
            {newGetStarted?.registerGuide?.btnText}
          </Button>
        </div>
      </NotifyRenderWrapped>
    )
  }
  return null
}
const NotifyRenderWrapped = styled.div`
  position: fixed;
  z-index: 998;
  bottom: 30px;
  right: 31px;
  border-radius: 16px;
  width: 420px;
  padding: 30px 30px 25px;
  box-shadow: 0px 12px 24px 0px rgba(162, 183, 203, 0.3);
  background-image: url(${bkgd.src});
  background-size: 100% 100%;
  background-color: #fff;
  opacity: 0;
  transform: translateX(100%);
  transition: opacity 0.4s ease-in, transform 0.3s ease-in;
  &.animIn {
    opacity: 1;
    transform: translateX(0);
  }
  &.animOut {
    opacity: 0;
    transform: translateX(100%);
    transition: transform 0.4s ease-in, opacity 0.3s ease-in;
  }

  .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
    .close {
      cursor: pointer;
    }
    h3 {
      font-size: 18px;
      font-weight: 600;
      line-height: 28px;
      svg {
        margin-left: 4px;
      }
    }
  }
  p {
    color: #3b3c3c;
    font-size: 16px;
    font-weight: 500;
    line-height: 28px;
    margin-bottom: 8px;
  }
  & .ant-btn {
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    height: 40px;
    padding: 12px 24px;
    background-color: #1c70ee;
    border-radius: 10px;
    box-shadow: none;
    &:hover {
      background-color: #0d6eff;
    }
  }
`

export default function RegisterGuideNotify({ dictionary }) {
  if (typeof window !== 'undefined') {
    return createPortal(<NotifyRender dictionary={dictionary} />, document?.body)
  }
  return null
}
