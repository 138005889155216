'use client'
import React from 'react'
// import Image from 'next/image'
import classNames from 'classnames'
import { usePathname } from 'next/navigation'
/* Style */
import styles from '../Header.module.scss'

/* components */
import Link from 'components/Link'
// import MaterialButton from 'components/MaterialButton'

/* Images */
import linkToHunt from 'helpers/linkToHunt'

/* help */
import { addEventTag } from 'helpers/utilities'
import { jpUrl } from 'helpers/constants'
import {
  // efficiencySvg,
  // accuracySvg,
  // qualitySvg,
  // angleSvg,
  chatSvg,
  recommendationsSvg,
  informationSvg,
  personSvg
} from './svgFile'
import { styled } from '@mui/material/styles'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
// import dynamic from 'next/dynamic'
import CountryLanguage from './countryLanguage'
import { postNotification } from 'bossjob-remote/dist/clientStorage'
// import { getCountryKey } from 'helpers/country'
// const CountryLanguage = dynamic(() => import('./countryLanguage'), {
//   ssr: true,
//   loading: () => <div className={styles.skeleton}></div>
// })
import qualityImgPart1 from '../imgs/quality-part1.png'
import qualityImgPart2 from '../imgs/quality-part2.png'
import accuracyImgPart1 from '../imgs/accuracy-part1.png'
import accuracyImgPart2 from '../imgs/accuracy-part2.png'
import efficiencyImgPart1 from '../imgs/efficiency-part1.png'
import efficiencyImgPart2 from '../imgs/efficiency-part2.png'
import { getStartedRecord } from 'store/services/eventTrack/record'
import { setCookie } from 'helpers/cookies'

interface IProps {
  lang: any
  langKey: string
  countryKey: string
}

const NavRight = (props: IProps) => {
  const { lang, langKey, countryKey } = props
  const {
    getStarted,
    hiring,
    efficiency,
    efficiencyForJp,
    chatWithTalentOnline,
    accuracy,
    accuracyForJp,
    matchingPositionsAndTalents,
    highQuality,
    highQualityForJp,
    highQualityTalentRecommendations,
    afterRegistrationOrLogin,
    chatWithBossOnline,
    accurateJobRecommendations,
    getMoreJobInformation,
    signUp,
    signUpFor,
    notLoggedin,
    loginBtn
  } = lang || {}
  const pathname = usePathname()
  // const [open, setOpen] = useState(false)
  const isShow = !(countryKey === 'jp')
  const employerAdv = (
    <div className={styles.mainBox}>
      {/* <span className={styles.angle}>{angleSvg}</span> */}
      <div className={styles.efficiency}>
        <Link
          title='Employer'
          to={linkToHunt('', langKey)}
          external
          aTag
          className={styles.hoverBox}
        >
          <div className={styles.svgBox}>
            <div className={styles.svg}>
              <div className={styles.svgPart1}>
                <img src={efficiencyImgPart1.src} alt='' />
              </div>
              <div className={styles.svgPart2}>
                <img src={efficiencyImgPart2.src} alt='' />
              </div>
            </div>
            <div className={styles.svgLine}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='176'
                height='93'
                viewBox='0 0 176 93'
                fill='none'
              >
                <g clipPath='url(#clip0_5712_34936)'>
                  <path
                    d='M-57.207 9.30182L90.936 93.3887L175.5 44.5637L252.071 0.353516'
                    stroke='white'
                    strokeOpacity='0.16'
                    strokeWidth='2'
                  />
                </g>
                <defs>
                  <clipPath id='clip0_5712_34936'>
                    <rect width='176' height='93' fill='white' />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
          <h5>{isShow ? efficiency : efficiencyForJp}</h5>
          <p>{isShow && chatWithTalentOnline}</p>
        </Link>
      </div>
      <div className={styles.accuracy}>
        <Link
          title='Employer'
          to={isShow ? linkToHunt('', langKey) : jpUrl}
          external
          aTag
          className={styles.hoverBox}
        >
          <div className={styles.svgBox}>
            <div className={styles.svg}>
              <div className={styles.svgPart1}>
                <img src={accuracyImgPart1.src} alt='' />
              </div>
              <div className={styles.svgPart2}>
                <img src={accuracyImgPart2.src} alt='' />
              </div>
            </div>
            <div className={styles.svgLine}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='176'
                height='93'
                viewBox='0 0 176 93'
                fill='none'
              >
                <g clipPath='url(#clip0_5712_34936)'>
                  <path
                    d='M-57.207 9.30182L90.936 93.3887L175.5 44.5637L252.071 0.353516'
                    stroke='white'
                    strokeOpacity='0.40'
                    strokeWidth='2'
                  />
                </g>
                <defs>
                  <clipPath id='clip0_5712_34936'>
                    <rect width='176' height='93' fill='white' />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
          <h5>{isShow ? accuracy : accuracyForJp}</h5>
          <p>{isShow && matchingPositionsAndTalents}</p>
        </Link>
      </div>
      <div className={styles.quality}>
        <Link
          title='Employer'
          external
          to={isShow ? linkToHunt('', langKey) : 'https://bossjobjapan.com/inquiry.html '}
          aTag
          className={styles.hoverBox}
        >
          <div className={styles.svgBox}>
            <div className={styles.svg}>
              <div className={styles.svgPart1}>
                <img src={qualityImgPart1.src} alt='' />
              </div>
              <div className={styles.svgPart2}>
                <img src={qualityImgPart2.src} alt='' />
              </div>
            </div>
            <div className={styles.svgLine}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='176'
                height='93'
                viewBox='0 0 176 93'
                fill='none'
              >
                <g clipPath='url(#clip0_5712_34936)'>
                  <path
                    d='M-57.207 9.30182L90.936 93.3887L175.5 44.5637L252.071 0.353516'
                    stroke='white'
                    strokeOpacity='0.40'
                    strokeWidth='2'
                  />
                </g>
                <defs>
                  <clipPath id='clip0_5712_34936'>
                    <rect width='176' height='93' fill='white' />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
          <h5>{isShow ? highQuality : highQualityForJp}</h5>
          <p>{isShow && highQualityTalentRecommendations}</p>
        </Link>
      </div>
    </div>
  )
  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: 'transparent',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 600,
      fontSize: theme.typography.pxToRem(12),
      border: 'none'
    }
  }))

  const startedAdv = (
    <>
      {!pathname?.includes?.('/get-started') && (
        <div className={styles.loginBox}>
          <div className={styles.loginInfo}>
            <div className={styles.personIcon}>{personSvg}</div>
            <div className={styles.loginInfoRight}>
              <p className={styles.notLogin}>{notLoggedin || 'Not logged in'}</p>
              <p>
                <span
                  className={styles.signUp}
                  onClick={() => {
                    postNotification('SHOW_LOGIN_MODAL')
                    sessionStorage.removeItem('chatJobId')
                    handleRecordTag('click')
                    handleStartedRecord({ source: 'Click_Get_Started' })
                  }}
                >
                  {signUp || 'Sign up'}
                </span>{' '}
                {signUpFor || 'for personalized job matches!'}
              </p>
            </div>
          </div>
          <div
            className={styles.loginBtn}
            onClick={() => {
              postNotification('SHOW_LOGIN_MODAL')
              sessionStorage.removeItem('chatJobId')
              handleRecordTag('click')
              handleStartedRecord({ source: 'Click_Get_Started' })
            }}
          >
            <span className={styles.loginBtnTxt}>{loginBtn || 'Find Your Dream Job Now!'}</span>
          </div>
        </div>
      )}

      <div className={styles.mainBox}>
        {/* <span className={styles.angle}>{angleSvg}</span> */}
        <h5>{afterRegistrationOrLogin}</h5>
        <div className={styles.item}>
          <span>{chatSvg}</span>
          {chatWithBossOnline}
        </div>
        <div className={styles.item}>
          <span>{recommendationsSvg}</span>
          {accurateJobRecommendations}
        </div>
        <div className={styles.item}>
          <span>{informationSvg}</span>
          {getMoreJobInformation}
        </div>
      </div>
    </>
  )

  const handleRecordTag = (act) => {
    if (act === 'hover') {
      addEventTag('hp_get_started_hover')
      return
    }

    if (act === 'click') {
      addEventTag('hp_get_started_click')
      return
    }
  }

  const handleStartedRecord = (params = {}) => {
    getStartedRecord(params)
    const oneHourFromNow = new Date(Date.now() + 60 * 60 * 1000)
    setCookie('loginBtnClick', 'Click_Get_Started', oneHourFromNow)
  }

  return (
    <>
      <ul className={styles.headerLinksList}>
        <React.Fragment>
          <li
            className={classNames([
              styles.headerLink,
              styles.headerLinkRightItem,
              styles.headerLinkRightText
            ])}
            style={{ marginRight: '6px' }}
            {...(pathname.endsWith('/remote-jobs') && {
              'data-gtag-event': 'rl_active_employer'
            })}
          >
            <HtmlTooltip
              placement='bottom'
              title={
                <React.Fragment>
                  <div className={styles.employerAdv}>{employerAdv}</div>
                </React.Fragment>
              }
            >
              <div>
                <Link
                  to={linkToHunt('', langKey, countryKey)}
                  aTag
                  className={styles.hoverBox}
                  onClick={() => {
                    addEventTag('hp_employer_click')
                  }}
                  onMouseOver={() => {
                    addEventTag('hp_employer_hover')
                  }}
                >
                  <span
                    className={classNames([styles.headerLinkText, styles.headerRightLinkText])}
                    style={{ padding: '0 20px' }}
                  >
                    {hiring}
                  </span>
                </Link>
              </div>
            </HtmlTooltip>
          </li>
          <li
            className={classNames([styles.headerLink, styles.headerLinkRightItem])}
            {...(pathname.endsWith('/remote-jobs') && {
              'data-gtag-event': 'rl_active_country_language'
            })}
          >
            <div
              className={styles.countryItem}
              onClick={() => {
                // postNotification('SHOW_SIDE_GUIDE_MODAL')
              }}
            >
              <CountryLanguage countryKey={countryKey} />
            </div>
          </li>

          <li
            className={classNames([styles.headerLink, styles.headerLinkRightItem])}
            {...(pathname.endsWith('/remote-jobs') && {
              'data-gtag-event': 'rl_active_get_started'
            })}
          >
            <HtmlTooltip
              placement='bottom'
              title={
                <React.Fragment>
                  <div className={styles.startedAdv}>{startedAdv}</div>
                </React.Fragment>
              }
            >
              <div>
                {!pathname?.includes?.('/get-started') ? (
                  <div
                    className={styles.getStartedButton}
                    onClick={() => {
                      // showLoginModal()
                      postNotification('SHOW_LOGIN_MODAL')
                      sessionStorage.removeItem('chatJobId')
                      handleRecordTag('click')
                      handleStartedRecord({ source: 'Click_Get_Started' })
                    }}
                    onMouseOver={() => {
                      handleRecordTag('hover')
                    }}
                  >
                    <span>{getStarted}</span>
                  </div>
                ) : (
                  <div
                    className={styles.getStartedButton}
                    onClick={() => {
                      handleRecordTag('click')
                      handleStartedRecord({ source: 'Click_Get_Started' })
                    }}
                    onMouseOver={() => {
                      handleRecordTag('hover')
                    }}
                  >
                    <span>{getStarted} </span>
                  </div>
                )}
              </div>
            </HtmlTooltip>
          </li>
        </React.Fragment>
      </ul>
    </>
  )
}

export default NavRight
