'use client'
import React, { useState, useEffect, useMemo, useContext } from 'react'
import { message, Radio, Select, Space } from 'antd'
import classNames from 'classnames'

import { getCountryKey } from 'helpers/country'
import { getCountryId } from 'helpers/country'
import { addParams } from 'helpers/utilities'
import { getValueById } from 'helpers/config/getValueById'
import { getLang, countryForCurrency } from 'helpers/country'
import {
  getSalaryOptions,
  getCountryList,
  getCurrencyList,
  initSalaryTypeLists
} from 'helpers/jobPayloadFormatter'

import {
  createUserPreferencesService,
  updateUserPreferencesService
} from 'store/services/users/addUserPreferences'

import Cascader from 'components/Cascader'
// import { getUrlSearchString, isChatProcess, jumpPage } from '../utils'

import JobFunction from '../JobFunction'
import styles from './index.pc.module.scss'
import { ArrowIcon } from './icons'
import { removeEmptyOrNullValues } from 'helpers/formatter'
import { cloneDeep } from 'lodash-es'
import { CustomRadio } from '../CustomRadio'
import { Button } from 'app/(new-pages)/components/Button/pc/index.pc'

const initType = {
  1: 'salary_ranges_lists',
  2: 'daily_salary_range_lists',
  3: 'hourly_salary_range_lists'
}

const formatData = (data, label, value) => {
  return data?.map((item) => ({
    label: item[label],
    value: item[value]
  }))
}

const DesiredJob = (props: {
  lang: any
  config: any
  onCancel: () => void
  getPreferences: (e: number) => void
  preferences: Array<any>
  jobDetail: any
}) => {
  const { lang = {}, config, onCancel, getPreferences, preferences = [], jobDetail = {} } = props
  const profile = lang.profile || {}
  const country = getCountryKey()
  const preferenceData = useMemo(() => preferences[0] || {}, [preferences])
  const isJobPreferences = true

  const [currencyLists] = useMemo(() => {
    return [getCurrencyList(config), getCountryList(config)]
  }, [config])
  const salaryTypeLists = useMemo(() => {
    return initSalaryTypeLists(config?.salary_type_lists || []).filter((item) => item?.id != 4)
  }, [config])
  const arrangementData = formatData(config?.work_arrangement_lists, 'name', 'id')

  const [preference, setPreference] = useState(null)
  const [loading, setLoading] = useState(false)
  const [maxSalaryOptions, setMaxSalaryOptions] = useState([])
  const localCurrency = countryForCurrency(country) || ''
  const [bindMinSalary, setBindMinSalary] = useState(0)

  const [formData, setFormData] = useState({
    minSalary: null,
    maxSalary: null,
    workLocation: null,
    salaryCurrency: null,
    jobFunction: { id: undefined, value: '' },
    arrangement: 1,
    salaryType: null
  })

  const disabledSubmit = useMemo(() => {
    // console.log('formData', formData)
    const checkJobFunction = !formData.jobFunction.id
    if (checkJobFunction) return true

    if (!formData.arrangement) return true

    if (Number(formData.arrangement) != 3 && !formData.workLocation) return true

    if (Number(formData.arrangement) != 3 && formData.salaryType) {
      if (!formData.minSalary || !formData.maxSalary) return true
    }

    if (Number(formData.arrangement) == 3 && formData.salaryCurrency) {
      if (!formData.salaryType || !formData.minSalary || !formData.maxSalary) return true
    }

    return false
  }, [formData])

  // console.log('disabledSubmit', disabledSubmit)

  const getMaxSalaryOptions = (minSalary) => {
    if (!formData.salaryType) return []
    const salaryCurrency = formData.salaryCurrency || localCurrency
    const maxSalaryOptions = getSalaryOptions(
      config[initType[formData.salaryType]].find(
        (el) => el.currency_id === currencyLists.find((el) => el.key === salaryCurrency).id
      ),
      Number(minSalary),
      true
    )

    const maxSalaryOrg = maxSalaryOptions?.length > 0 ? maxSalaryOptions[0].value : null
    setFormData({ ...formData, maxSalary: maxSalaryOrg })
    setMaxSalaryOptions(maxSalaryOptions)
  }

  const minSalaryOptions = useMemo(() => {
    if (!formData.salaryType) return []
    const salaryCurrency = formData.salaryCurrency || localCurrency
    return getSalaryOptions(
      config[initType[formData.salaryType]].find(
        (el) => el.currency_id === currencyLists.find((el) => el.key === salaryCurrency)?.id
      )
    )
  }, [
    JSON.stringify(currencyLists),
    JSON.stringify(config.salary_ranges_lists),
    formData.salaryCurrency,
    formData.salaryType
  ])

  useEffect(() => {
    if (preferenceData?.id) {
      getJobsPreferences(preferenceData)
    } else {
      setFormData({ ...formData, arrangement: jobDetail?.is_remote_job ? 3 : 1 })
    }
  }, [preferenceData])

  const getJobsPreferences = (_preference) => {
    setPreference(_preference)

    const {
      salary_range_from,
      salary_range_to,
      location_id,
      work_arrangement,
      currency_key,
      salary_type,
      daily_salary_range_from,
      daily_salary_range_to,
      hourly_salary_range_from,
      hourly_salary_range_to,
      pref_country_id,
      pref_region_id,
      function_job_title_id
    } = _preference

    const { id = '' } = salary_type || {}
    const from =
      id === 1 ? salary_range_from : id == 2 ? daily_salary_range_from : hourly_salary_range_from
    const to = id === 1 ? salary_range_to : id == 2 ? daily_salary_range_to : hourly_salary_range_to

    const _formData = {
      ...formData,
      minSalary: parseInt(from) || null,
      maxSalary: parseInt(to) || null,
      salaryType: isJobPreferences ? salary_type?.id : null,
      arrangement: work_arrangement?.id || null,
      salaryCurrency: isJobPreferences ? currency_key : null,
      jobFunction: {
        id: function_job_title_id,
        value: getValueById(config, function_job_title_id, 'function_job_title_id') ?? ''
      }
    }

    if (work_arrangement?.id === 3) {
      _formData.salaryCurrency = currency_key
    }
    // _formData.workLocation = {
    //   id: pref_country_id || pref_region_id || location_id,
    // }
    // console.log('_formData', _formData)
    setFormData({ ...formData, ..._formData })
  }

  useEffect(() => {
    if (formData.minSalary) {
      getMaxSalaryOptions(formData.minSalary)
    }
  }, [formData.minSalary])
  useEffect(() => {
    if (bindMinSalary && minSalaryOptions?.length) {
      setBindMinSalary(0)
      setFormData((pre) => {
        return { ...pre, minSalary: minSalaryOptions[0].value }
      })
    }
  }, [bindMinSalary, minSalaryOptions])

  const getSalaryRangeKey = (salaryType) => {
    switch (salaryType) {
      case 1:
        return 'salary_range'
      case 2:
        return 'daily_salary_range'
      case 3:
        return 'hourly_salary_range'
      default:
        return ''
    }
  }

  const getSubmitData = () => {
    let params = {
      job_title: formData.jobFunction.value || '',
      function_job_title_id: formData.jobFunction.id,
      currency_key: formData.salaryCurrency,
      country_id: Number(formData.arrangement) < 3 ? getCountryId() : 241,
      work_arrangement_id: formData.arrangement || undefined
    }

    if (formData.salaryType) {
      params['salary_type_id'] = formData.salaryType
    }

    if (Number(formData.arrangement) == 3) {
      params['language_ids'] = '1'
    }

    if (Number(formData.arrangement) != 3) {
      params = addParams(params, formData.workLocation)
    }

    const salaryRangeKey = getSalaryRangeKey(formData.salaryType)
    if (salaryRangeKey) {
      params[`${salaryRangeKey}_from`] = Number(formData.minSalary)
      params[`${salaryRangeKey}_to`] = Number(formData.maxSalary)
    }

    const _params = cloneDeep(removeEmptyOrNullValues(params))

    if (!formData.salaryType && preference?.salary_type?.id) {
      const salaryRangeKey = getSalaryRangeKey(preference?.salary_type?.id)
      if (salaryRangeKey) {
        _params[`${salaryRangeKey}_from`] = null
        _params[`${salaryRangeKey}_to`] = null
        _params['salary_type_id'] = preference?.salary_type?.id
      }
    }

    return _params
  }

  const onSubmit = async () => {
    try {
      const params = getSubmitData()
      setLoading(true)
      if (preference?.id) {
        const updateRes = await updateUserPreferencesService({
          preferenceId: preference?.id,
          params
        })
        if (updateRes?.data) {
          message.success(updateRes?.data?.message)
          getPreferences(updateRes?.data?.data.id)
        }
      } else {
        const createRes: any = await createUserPreferencesService({ params })
        if (createRes?.data) {
          message.success(createRes?.data?.message)
          getPreferences(createRes?.data?.data.id)
        }
      }
    } catch (error) {
      console.log('error', error)
    } finally {
      setLoading(false)
    }
  }

  // console.log('minSalaryOptions', minSalaryOptions)

  const workLen = preferences.reduce(
    (accumulator, currentValue) => {
      if (currentValue.is_remote) {
        accumulator.remote++
      } else {
        accumulator.onsite++
      }
      return accumulator
    },
    { onsite: 0, remote: 0 }
  )

  return (
    <>
      <div className={classNames(styles.desired, 'yoloJobseekerCompletedSelect')}>
        <div className={styles.desiredContainer}>
          <div className={styles.container}>
            <div className={styles.wrapper}>
              <div className={styles.content}>
                <div className={styles.title}>
                  {lang?.editJobPreferencesModal?.chatTitle || 'Supplemental work preferences'}
                </div>
                <div className={styles.subtitle}>
                  {lang?.editJobPreferencesModal?.chatSubtitle ||
                    'You do not have a current country job preference'}
                </div>
                <div className={styles.form}>
                  <div className={styles.form_item}>
                    <div className={styles.form_label}>{profile?.JobTitleText}</div>
                    <div className={styles.form_value}>
                      <JobFunction
                        lang={lang}
                        value={formData.jobFunction}
                        onChange={(value) => setFormData({ ...formData, jobFunction: value })}
                      />
                    </div>
                  </div>

                  <div className={styles.form_item}>
                    <div className={styles.form_label}>{profile?.workArrangement}</div>
                    <div className={styles.form_value}>
                      <div className={styles.radioGroup}>
                        {arrangementData?.map((item) => {
                          let disabled =
                            item.value === 3 ? workLen.remote >= 3 : workLen.onsite >= 3

                          return (
                            <CustomRadio
                              checked={item.value == formData.arrangement && !disabled}
                              onChange={(event) => {
                                // let salaryCurrency = isJobPreferences ? localCurrency : null
                                let isRemote = Number(event.target.value) === 3

                                setFormData({
                                  ...formData,
                                  arrangement: event.target.value,
                                  minSalary: null,
                                  maxSalary: null,
                                  salaryType: null,
                                  salaryCurrency: null,
                                  // clear workLocation when user opt remote job
                                  workLocation: isRemote ? null : formData.workLocation
                                })
                              }}
                              label={item.label}
                              value={item.value}
                              key={item.value}
                              disabled={disabled}
                            />
                          )
                        })}
                      </div>
                    </div>
                  </div>

                  {formData.arrangement != 3 && (
                    <div className={styles.form_item}>
                      <div className={styles.form_label}>{profile.desired?.workLocation}</div>
                      <div className={styles.form_value}>
                        <Cascader
                          placeholder={profile.desired?.workLocationPlaceholder}
                          preference={preference}
                          onChange={(location) => {
                            setTimeout(() => {
                              console.log('location123', location)
                              setFormData({ ...formData, workLocation: location })
                            }, 0)
                          }}
                          suffixIcon={ArrowIcon}
                        />
                      </div>
                    </div>
                  )}

                  <div className={styles.form_item}>
                    <div className={styles.form_label}>
                      {profile.desired?.salaryRange}{' '}
                      <span className={styles.form_optional}>({profile.optional})</span>
                    </div>
                    <div className={styles.form_value + ' ' + styles.form_salary}>
                      {formData.arrangement == 3 && (
                        <Select
                          style={{ height: '42px' }}
                          options={currencyLists}
                          suffixIcon={<ArrowIcon />}
                          placeholder={profile.desired?.salaryCurrencyPlaceholder}
                          value={formData.salaryCurrency}
                          className={styles.form_salaryType}
                          popupClassName='selectGlobalPopupClassName'
                          allowClear
                          onChange={(value) => {
                            setBindMinSalary(value ? Math.random() : 0)
                            setFormData({
                              ...formData,
                              salaryCurrency: value,
                              salaryType: value ? 1 : null,
                              minSalary: null,
                              maxSalary: null
                            })
                          }}
                        />
                      )}
                      <Select
                        style={{ height: '42px' }}
                        options={salaryTypeLists}
                        disabled={!formData.salaryCurrency && formData.arrangement == 3}
                        suffixIcon={<ArrowIcon />}
                        placeholder={profile.desired?.salaryType}
                        className={styles.form_salaryType}
                        value={formData.salaryType}
                        popupClassName='selectGlobalPopupClassName'
                        allowClear
                        onChange={(value) => {
                          setBindMinSalary(Math.random())
                          setFormData({
                            ...formData,
                            salaryType: value,
                            minSalary: null,
                            maxSalary: null
                          })
                        }}
                      />
                      {formData.salaryType && (
                        <div className={styles.form_salaryRange}>
                          <Select
                            disabled={!minSalaryOptions?.length}
                            style={{ height: '42px' }}
                            options={minSalaryOptions}
                            suffixIcon={<ArrowIcon />}
                            placeholder={profile.desired?.salaryFrom}
                            className={styles.form_minSalary}
                            value={formData.minSalary}
                            popupClassName='selectGlobalPopupClassName'
                            onChange={(value) => {
                              setFormData({ ...formData, minSalary: value })
                            }}
                          />
                          <Select
                            disabled={
                              !maxSalaryOptions?.length ||
                              !maxSalaryOptions?.length ||
                              !formData.minSalary
                            }
                            style={{ height: '42px' }}
                            options={maxSalaryOptions}
                            popupClassName='selectGlobalPopupClassName'
                            suffixIcon={<ArrowIcon />}
                            placeholder={profile.desired?.salaryTo}
                            className={styles.form_maxSalary}
                            value={formData.maxSalary}
                            onChange={(value) => {
                              setFormData({ ...formData, maxSalary: value })
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </div>

                  <div className={styles.footer}>
                    <Button variant='whiteBoxShadowBtn' btnSize='size40' onClick={onCancel}>
                      {lang?.comm?.cancel}
                    </Button>
                    <Button
                      variant='primaryBtn'
                      btnSize='size40'
                      disabled={disabledSubmit}
                      loading={loading}
                      onClick={onSubmit}
                    >
                      {lang?.comm?.confirm}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DesiredJob
