'use client'
import { Modal } from 'antd'
import { useEffect, useState, useMemo } from 'react'
import { useSharedData } from 'bossjob-remote/dist/hooks'
import styles from './index.module.scss'
import DesiredForm from './DesiredForm/index.pc'
import { fetchUserOwnDetailService } from 'store/services/users/fetchUserOwnDetail'
import { fetchUserOwnDetailSuccess } from 'store/actions/users/fetchUserOwnDetail'
import { postNotification, publishSharedData } from 'bossjob-remote/dist/clientStorage'

export default function RemotePrefModal({
  lang,
  open,
  onClose,
  onFinish,
  configs = null,
  userDetail = {},
  jobDetail = {}
}) {
  const config = useSharedData('CONFIG') ?? configs ?? {}
  // @ts-ignore
  const preferences = useMemo(() => userDetail?.job_preferences || [], [userDetail])
  // console.log('config', { config, userDetail, jobDetail })

  const getPreferences = async (id) => {
    try {
      if (id) {
        const response = await fetchUserOwnDetailService()
        postNotification('DISPATCH', fetchUserOwnDetailSuccess(response?.data?.data))
        publishSharedData('ME', response?.data?.data)

        setTimeout(() => {
          onFinish?.(response?.data?.data)
        }, 1000)
      }
    } catch (error) {}
  }

  return (
    <Modal
      wrapClassName='myJobModal'
      open={open}
      onCancel={onClose}
      width={500}
      zIndex={1040}
      title={null}
      footer={null}
    >
      <div className={styles.preferenceModal}>
        {config && (
          <DesiredForm
            lang={lang}
            config={config}
            onCancel={onClose}
            jobDetail={jobDetail}
            preferences={preferences}
            getPreferences={getPreferences}
          />
        )}
      </div>
    </Modal>
  )
}
