'use client'
import styled from '@emotion/styled'
import React from 'react'
import Image from 'next/image'
import Link from 'components/Link'

/* Images */
import appstore from '../../images/appstore.png'
import googleplay from '../../images/googleplay.png'
import appstoreHover from '../../images/appstore_hover.png'
import googleplayHover from '../../images/googleplay_hover.png'
import { ReactComponent as QrcodeIcon } from '../../images/icons/qrcode.svg'
import adjustUrlList from 'helpers/adjustUrlList'
import QrCodeDraw from 'components/QrCodeDraw/QrCodeDraw'
import { getCountryKey } from 'helpers/country'

const DownloadApp = (props: any) => {
  const { data } = props
  const { downloadApp, scanDownloadApp } = data?.foot || {}
  const countryKey = getCountryKey()
  return (
    <DownloadAppStyle>
      <div className='download_btn_wrap'>
        <span className='download_btn'>
          <span className='download_btn_text'>{downloadApp}</span>
          <svg
            width='22'
            height='22'
            viewBox='0 0 22 22'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <rect
              x='0.333496'
              y='0.333374'
              width='21.3333'
              height='21.3333'
              rx='5.33333'
              fill='#202021'
            />
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M7.6665 9.94281L8.60931 9L11.4712 11.8619L14.3332 9L15.276 9.94281L11.4712 13.7475L7.6665 9.94281Z'
              fill='white'
            />
          </svg>
          <div className='download_wrap'>
            <div className='download-app'>
              <Link
                to={adjustUrlList[4]?.link?.[countryKey] ?? adjustUrlList[4].link.default}
                external
              >
                <Image src={appstore} alt='appstore' width={130} height={44} />
              </Link>
              <div className='download-app-hover'>
                <Link
                  to={'https://apps.apple.com/us/app/bossjob-chat-job-search/id1592073585?mt=8'}
                  external
                >
                  <Image src={appstoreHover} alt='appstore' width={130} height={44} />
                </Link>
              </div>
            </div>
            <div className='download-app download-app-google'>
              <Link
                to={adjustUrlList[4]?.link?.[countryKey] ?? adjustUrlList[4].link.default}
                external
              >
                <Image src={googleplay} alt='google' width={130} height={44} />
              </Link>
              <div className='download-app-hover'>
                <Link
                  to={'https://play.google.com/store/apps/details?id=com.poseidon.bossjobapp&hl=en'}
                  external
                >
                  <Image src={googleplayHover} alt='google' width={130} height={44} />
                </Link>
              </div>
            </div>
            <div className='download-app-text'>{downloadApp}</div>
          </div>
        </span>

        <span className='download_btn_qrcode'>
          <QrcodeIcon style={{ cursor: 'pointer' }} />
          <div className='qrcode_wrap'>
            <div className='qrcode_img_wrap'>
              <QrCodeDraw
                text={adjustUrlList[4]?.link?.[countryKey] ?? adjustUrlList[4].link.default}
                ecl='H'
                width={100}
                height={100}
              />
            </div>
            <p className='download_tip'>{scanDownloadApp}</p>
          </div>
        </span>
      </div>
    </DownloadAppStyle>
  )
}

export default DownloadApp

const DownloadAppStyle = styled.div`
  margin-left: 24px;
  .download_btn_wrap {
    display: flex;
    align-items: center;
  }
  .download_btn {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 5px 5px 5px 10px;
    background: #fff;
    border-radius: 8px;
    .download_btn_text {
      color: #000;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px; /* 166.667% */
      white-space: nowrap;
      flex: 1;
    }
    > svg {
      margin-left: 6px;
    }
    &:hover {
      .download_wrap {
        visibility: visible;
      }
    }
  }
  .download_wrap {
    position: absolute;
    z-index: 2;
    top: -167px;
    left: -14px;
    display: flex;
    visibility: hidden;
    padding: 14px;
    flex-direction: column;
    align-items: center;
    border-radius: 16px;
    background: #fff;
    box-shadow: 0px 2px 40px 0px rgba(0, 0, 0, 0.1);
    transition: visibility 0.2s ease-in-out; /* 添加过渡效果 */
    .download-app {
      position: relative;
      width: 130px;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      background: #fff;
      z-index: 1;

      &:hover .download-app-hover {
        display: block;
      }
    }

    .download-app-hover {
      position: absolute;
      top: 0;
      left: 0;
      display: none; /* 隐藏初始状态 */
      width: 130px;
      height: 44px;
      z-index: 2;
    }
    .download-app a {
      display: inline-block;
      width: 100%;
      height: 100%;
      /* padding: 6px 10px; */
    }
    .download-app-google {
      margin-top: 8px;
    }
    .download-app-text {
      margin-top: 8px;
      text-align: center;
      color: #000;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px; /* 142.857% */
    }
  }
  .download_btn_qrcode {
    position: relative;
    height: 32px;
    margin-left: 12px;
    &:hover {
      .qrcode_wrap {
        visibility: visible;
      }
    }
  }
  .qrcode_wrap {
    position: absolute;
    z-index: 2;
    visibility: hidden;
    top: -191px;
    left: -54px;
    display: flex;
    padding: 16px 16px 10px 16px;
    flex-direction: column;
    align-items: center;
    border-radius: 14px;
    background: #fff;
    box-shadow: 0px 2px 40px 0px rgba(0, 0, 0, 0.1);
    transition: visibility 0.2s ease-in-out; /* 添加过渡效果 */
    .qrcode_img_wrap {
      display: flex;
      width: 120px;
      padding: 6px;
      justify-content: center;
      align-items: center;
      background: #ffffff;
    }
    .download_tip {
      color: #000;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px; /* 142.857% */
    }
  }
`
