import React from "react";

export const CheckedIcon = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} fill='none' {...props}>
    <rect width={20} height={20} x={2} y={2} fill='#1C70EE' rx={10} />
    <path stroke='#fff' strokeWidth={2} d='m7.422 11.234 3.562 3.563 5.594-5.594' />
  </svg>
);

export const UncheckedIcon = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} fill='none' {...props}>
    <rect
      width={18.4}
      height={18.4}
      x={2.8}
      y={2.8}
      fill='#fff'
      stroke='#DBDFE4'
      strokeWidth={1.6}
      rx={9.2}
    />
  </svg>
);
