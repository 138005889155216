"use client";
import React, { useState } from "react";
import { CheckedIcon, UncheckedIcon } from "./icons";
import styles from "./index.module.scss";
import classNames from "classnames";

export const CustomRadio = ({
  label,
  value,
  checked,
  onChange,
  className = "",
  checkedRender = null,
  uncheckedRender = null,
  disabled = false
}) => {
  return (
    <div className={classNames(styles.customRadioWrapper, className)}>
      <label
        className={classNames([
          styles.customRadio,
          "yolo-custom-radio-label",
          checked ? "yolo-custom-radio-checked" : "",
          disabled ? styles.customRadioDisable : ""
        ])}
      >
        <input
          type='radio'
          value={value}
          checked={checked}
          onChange={onChange}
          style={{ display: "none" }}
          disabled={disabled}
        />
        {checked ? checkedRender || <CheckedIcon /> : uncheckedRender || <UncheckedIcon />}
        {label}
      </label>
    </div>
  );
};

const Example = () => {
  const [selectedValue, setSelectedValue] = useState("option1");

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  return (
    <div>
      <CustomRadio
        label='选项1'
        value='option1'
        checked={selectedValue === "option1"}
        onChange={handleChange}
      />
      <CustomRadio
        label='选项2'
        value='option2'
        checked={selectedValue === "option2"}
        onChange={handleChange}
      />
    </div>
  );
};

export default Example;
