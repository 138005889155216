import React, { useState, useEffect } from 'react'

/* Vendor */
import { Button } from 'antd'
import { useForm } from 'react-hook-form'
import classNames from 'classnames'
/* Components */
import Modal from 'components/Modal'
import Text from 'components/Text'

/* Styles */
import styles from './ModalReportJob.module.scss'
import { useSharedData } from 'bossjob-remote/dist/hooks'
/* Images */
import { ArrowForwardIcon } from 'images'
// import { languageContext } from 'app/components/providers/languageProvider'

interface ModalReportJobProps {
  isShowReportJob?: boolean
  handleShowReportJob?: Function
  reportJobReasonList?: any
  selectedJobId?: number | string
  handlePostReportJob?: Function
  postReportResponse: any
  isPostingReport: boolean
}

const ModalReportJob = ({
  isShowReportJob,
  handleShowReportJob,
  reportJobReasonList,
  selectedJobId,
  handlePostReportJob,
  postReportResponse,
  isPostingReport
}: ModalReportJobProps) => {
  // const {
  //   jobDetail: { reportModal, detailModal, feedbackModal }
  // } = useContext(languageContext) as any
  const {
    jobDetail: { reportModal, detailModal, feedbackModal }
  } = useSharedData('DICTIONARY')
  const [modalReportDetail, setModalReportDetail] = useState(false)
  const [modalReportSelected, setModalReportSelected] = useState(null)
  const [modalReportSelectedItem, setModalReportSelectedItem] = useState('')

  const [isShowConfirmation, setIsShowConfirmation] = useState(false)
  const [hasReportedJob, setHasReportedJob] = useState(false)

  let reportList = [
    { category: 'spam', description: reportModal?.category?.spam },
    { category: 'discrimination', description: reportModal?.category?.discrimination },
    { category: 'broken', description: reportModal?.category?.broken }
  ]

  const { register, handleSubmit } = useForm()

  useEffect(() => {
    if (postReportResponse?.message === 'success' && hasReportedJob) setIsShowConfirmation(true)
  }, [postReportResponse])

  const onSubmit = (data) => {
    reportList = []
    setHasReportedJob(true)
    setModalReportDetail(false)
    handlePostReportJob({ jobId: selectedJobId, jobReasonId: data.reportDetail })
  }

  const handleChange = (id) => {
    setModalReportSelectedItem(id)
  }

  const handleSelectedReportJob = (category) => {
    return reportJobReasonList?.filter((report) => report.category === category)
  }

  const onCloseModal = () => {
    handleShowReportJob(false)
    setIsShowConfirmation(false)
    setModalReportDetail(false)
    setHasReportedJob(false)
  }

  const isShowInitialModal = isShowReportJob && !modalReportDetail && !isShowConfirmation
  const isShowDetailModal = isShowReportJob && modalReportDetail && !isShowConfirmation
  const isShowConfirmationModal = isShowReportJob && !modalReportDetail && isShowConfirmation

  return (
    <>
      <Modal
        headerTitle={`${isPostingReport ? reportModal.title : reportModal.notReportedTitle}`}
        showModal={isShowInitialModal}
        handleModal={() => {
          onCloseModal()
        }}
        headerClass={styles.header}
        customClassName={styles.reportModal}
        className={styles.modalContent}
      >
        <div className={styles.modalReportJob}>
          <div className={styles.headers}>
            {`${isPostingReport ? reportModal.title : reportModal.notReportedTitle}`}
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='20'
              height='20'
              viewBox='0 0 20 20'
              fill='none'
              onClick={onCloseModal}
            >
              <path
                d='M15.3033 5.87523C15.6288 5.54979 15.6288 5.02215 15.3033 4.69671C14.9779 4.37128 14.4502 4.37128 14.1248 4.69671L10 8.8215L5.87523 4.69671C5.54979 4.37128 5.02215 4.37128 4.69671 4.69671C4.37128 5.02215 4.37128 5.54979 4.69671 5.87523L8.8215 10L4.69671 14.1248C4.37128 14.4502 4.37128 14.9779 4.69671 15.3033C5.02215 15.6288 5.54979 15.6288 5.87523 15.3033L10 11.1785L14.1248 15.3033C14.4502 15.6288 14.9779 15.6288 15.3033 15.3033C15.6288 14.9779 15.6288 14.4502 15.3033 14.1248L11.1785 10L15.3033 5.87523Z'
                fill='#ACAEB1'
              />
            </svg>
          </div>
          {isPostingReport && (
            <div className={styles.modalReportJobConfirmation}>
              <Text textStyle='xxl'>{reportModal.submitting}</Text>
            </div>
          )}
          {!isPostingReport &&
            reportList?.map((report, i) => (
              <div
                key={i}
                className={styles.modalReportJobItem}
                onClick={() => {
                  setModalReportDetail(true)
                  setModalReportSelected(report)
                  setModalReportSelectedItem(handleSelectedReportJob(report.category)[0].id)
                }}
              >
                <p className={styles.reportList}>
                  {report.description}
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='16'
                    height='17'
                    viewBox='0 0 16 17'
                    fill='none'
                  >
                    <path
                      fillRule='evenodd'
                      clipRule='evenodd'
                      d='M9.31124 8.35421L4.99142 4.03439C4.73107 3.77405 4.73107 3.35194 4.99142 3.09159L5.13285 2.95016C5.39319 2.68981 5.8153 2.68981 6.07565 2.95016L11.4797 8.35421L6.07565 13.7583C5.8153 14.0186 5.39319 14.0186 5.13285 13.7583L4.99142 13.6168C4.73107 13.3565 4.73107 12.9344 4.99142 12.674L9.31124 8.35421Z'
                      fill='#ACAEB1'
                    />
                  </svg>
                </p>
              </div>
            ))}
        </div>
      </Modal>
      <Modal
        headerTitle={detailModal.title}
        showModal={isShowDetailModal}
        handleModal={() => {
          onCloseModal()
        }}
        // firstButtonText={detailModal.btn1}
        // handleFirstButton={() => {
        //   setModalReportDetail(false)
        //   setModalReportSelected(modalReportSelected)
        // }}
        // secondButtonText={detailModal.btn2}
        // handleSecondButton={handleSubmit(onSubmit)}
        bodyClass={styles.bodyContent}
        headerClass={styles.header}
        customClassName={styles.reportModal}
        className={styles.modalContent}
      >
        <div
          className={styles.headers}
          style={{
            justifyContent: 'flex-end'
          }}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='20'
            height='20'
            viewBox='0 0 20 20'
            fill='none'
            onClick={onCloseModal}
          >
            <path
              d='M15.3033 5.87523C15.6288 5.54979 15.6288 5.02215 15.3033 4.69671C14.9779 4.37128 14.4502 4.37128 14.1248 4.69671L10 8.8215L5.87523 4.69671C5.54979 4.37128 5.02215 4.37128 4.69671 4.69671C4.37128 5.02215 4.37128 5.54979 4.69671 5.87523L8.8215 10L4.69671 14.1248C4.37128 14.4502 4.37128 14.9779 4.69671 15.3033C5.02215 15.6288 5.54979 15.6288 5.87523 15.3033L10 11.1785L14.1248 15.3033C14.4502 15.6288 14.9779 15.6288 15.3033 15.3033C15.6288 14.9779 15.6288 14.4502 15.3033 14.1248L11.1785 10L15.3033 5.87523Z'
              fill='#ACAEB1'
            />
          </svg>
        </div>
        <div className={styles.modalReportJobDetail}>
          {handleSelectedReportJob(modalReportSelected?.category)?.map((option, i) => (
            <div
              className={styles.modalReportJobDetailItem}
              key={i}
              onClick={() => handleChange(option.id)}
            >
              {/* <RadioGroup
                aria-label='reportDetail'
                name='controlled-radio-buttons-group'
                value={modalReportSelectedItem}
                onChange={handleChange}
                className={styles.modalReportJobDetailRadioGroup}
              >
                <FormControlLabel
                  {...register('reportDetail')}
                  value={option.id}
                  control={<Radio />}
                  label={
                    <div className={styles.modalReportJobDetailLabel}>
                      <Text textStyle='lg'>{option.title}</Text>
                      <Text textStyle='base' textColor='lightgrey'>
                        {option.description}
                      </Text>
                    </div>
                  }
                />
              </RadioGroup> */}
              <span
                className={classNames({
                  [styles.circle]: true,
                  [styles.checked]: modalReportSelectedItem === option.id
                })}
              >
                <svg
                  width='12'
                  height='9'
                  viewBox='0 0 12 9'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    id='Vector 4226'
                    d='M1.42188 3.23438L4.98438 6.79688L10.5781 1.20312'
                    stroke='white'
                    strokeWidth='2'
                  />
                </svg>
              </span>
              <div className={styles.modalReportJobDetailLabel}>
                <p className={styles.title}>{option.title}</p>
                <p className={styles.desc}>{option.description}</p>
              </div>
            </div>
          ))}
        </div>
        <div className={styles.button}>
          <Button
            className={styles.back}
            onClick={() => {
              setModalReportDetail(false)
              setModalReportSelected(modalReportSelected)
            }}
          >
            {detailModal.btn1}
          </Button>
          <Button
            className={styles.submit}
            type='primary'
            onClick={() => {
              setHasReportedJob(true)
              setModalReportDetail(false)
              handlePostReportJob({ jobId: selectedJobId, jobReasonId: modalReportSelectedItem })
            }}
          >
            {detailModal.btn2}
          </Button>
        </div>
      </Modal>
      <Modal
        headerTitle={feedbackModal.title}
        showModal={isShowConfirmationModal}
        handleModal={() => {
          onCloseModal()
        }}
        headerClass={styles.header}
        customClassName={styles.reportModal}
        className={styles.modalContent}
      >
        <div className={styles.headers}>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='20'
            height='20'
            viewBox='0 0 20 20'
            fill='none'
            onClick={onCloseModal}
          >
            <path
              d='M15.3033 5.87523C15.6288 5.54979 15.6288 5.02215 15.3033 4.69671C14.9779 4.37128 14.4502 4.37128 14.1248 4.69671L10 8.8215L5.87523 4.69671C5.54979 4.37128 5.02215 4.37128 4.69671 4.69671C4.37128 5.02215 4.37128 5.54979 4.69671 5.87523L8.8215 10L4.69671 14.1248C4.37128 14.4502 4.37128 14.9779 4.69671 15.3033C5.02215 15.6288 5.54979 15.6288 5.87523 15.3033L10 11.1785L14.1248 15.3033C14.4502 15.6288 14.9779 15.6288 15.3033 15.3033C15.6288 14.9779 15.6288 14.4502 15.3033 14.1248L11.1785 10L15.3033 5.87523Z'
              fill='#ACAEB1'
            />
          </svg>
        </div>
        <div className={styles.modalReportJob}>
          <h4>{feedbackModal.title}</h4>
          <div className={styles.modalReportJobConfirmation}>{feedbackModal.message}</div>
        </div>
      </Modal>
    </>
  )
}

export default ModalReportJob
