'use client'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { usePathname } from 'next/navigation'
import type { MenuProps } from 'antd'
import { Menu } from 'antd'
import classNames from 'classnames'
import styled from '@emotion/styled'
import { useLanguage } from 'app/components/providers/languageProvider'

/* Redux */
import { toggleMenu } from 'store/actions/navigationBar/toggleMenu'

/* Components */
import Link from 'components/Link'

/* Helpers */
import { getCookie } from 'helpers/cookies'
import styles from './HamburgerMenu.module.scss'
import { getCountryKey, getLang, getLanguageId } from 'helpers/country'
import { languages } from 'helpers/country.base'

import linkToHunt from 'helpers/linkToHunt'
import { ChangeLangAndRegion } from 'components/Header/Common/ChangeLangAndRegion'
import { S3BucketJobseeker } from 'images'
import { useUserAgent } from 'next-useragent'
import flagInterIcon from 'images/flags/flag_inter.png'
import adjustUrlList from 'helpers/adjustUrlList'

interface HamburgerMenuProps {
  openState?: boolean
  toggleMenu?: Function
  header: any
}

const HamburgerMenu = ({ header }: HamburgerMenuProps) => {
  const {
    downloadApp,
    findJobs,
    companies,
    careerGuide,
    hiring,
    home,
    remoteJobs,
    recommendedJobs,
    onSiteJobs,
    Web3Jobs,
    startGuide,
    web3LandingPage
  } = header || {}
  const pathname = usePathname()
  const dispatch = useDispatch()
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [_current, setCurrent] = useState('mail')
  const [openSwitchNationModal, setOpenSwitchNationModal] = useState<boolean>(false)
  const [show, setShow] = useState(false)
  const userDetail = useSelector((store: any) => store.users.fetchUserOwnDetail?.response ?? {})
  const [_showUnCompletedDot, setShowUnCompletedDot] = useState(false)
  const [countryKey, setCountryKey] = useState('ph')

  const langKey = getLang()
  const openState = useSelector((state: any) => state.navbar.toggleMenu.menu)
  const [clickAfter, setClickAfter] = useState(false)
  const userAgent = useUserAgent(globalThis?.navigator?.userAgent)
  const lang: any = useLanguage()
  const languageId = getLanguageId()

  const languageSlug = languages.find((item) => item.id === languageId)?.slug
  const handleToggleMenu = () => {
    dispatch(toggleMenu())
  }

  useEffect(() => {
    if (userDetail?.id) {
      const hasJobPreferences = userDetail?.job_preferences.length > 0
      setShowUnCompletedDot(!userDetail?.is_profile_completed || !hasJobPreferences)
    }
    const countryKey = getCountryKey()
    setCountryKey(countryKey)
  }, [userDetail])

  // useEffect(() => {
  //   setOpenSwitchNationModal(false)
  // }, [openState])

  useEffect(() => {
    setIsAuthenticated(getCookie('sessionid') ? true : false)
  }, [userDetail])

  useEffect(() => {
    if (openState) {
      if (userAgent.isSafari) {
        document.body.style.position = 'fixed'
        document.body.style.top = '0px'
        document.body.style.width = '100%'
      }
      document.body.style.overflow = 'hidden'
    } else {
      if (userAgent.isSafari) {
        document.body.style.position = ''
        document.body.style.top = '0px'
        document.body.style.width = '100%'
      }
      document.body.style.overflow = 'auto'
    }
  }, [openState])
  const handleClick = () => {
    // hide switch nation modal
    if (openSwitchNationModal) {
      setOpenSwitchNationModal(false)
      return
    }

    // enable body scroll again
    document.body.style.position = ''
    document.body.style.top = ''
    handleToggleMenu()
  }

  const onClick: MenuProps['onClick'] = (e) => {
    setCurrent(e.key)
  }

  const isOpenMenu =
    pathname.endsWith('/remote-jobs-hiring/jobs') ||
    pathname.endsWith('/my-jobs') ||
    pathname.endsWith('/jobs-hiring/job-search')

  const iconSwitch = () => (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
      <path
        d='M1.125 4.5C0.572715 4.5 0.125 4.94772 0.125 5.5C0.125 6.05228 0.572715 6.5 1.125 6.5H21C21.5523 6.5 22 6.05228 22 5.5C22 4.94772 21.5523 4.5 21 4.5H1.125Z'
        fill='#121212'
      />
      <path
        d='M6.5 12C6.5 11.4477 6.94772 11 7.5 11H20C20.5523 11 21 11.4477 21 12C21 12.5523 20.5523 13 20 13H7.5C6.94772 13 6.5 12.5523 6.5 12Z'
        fill='#121212'
      />
      <path
        d='M7.9375 18.5C7.9375 17.9477 8.38521 17.5 8.9375 17.5H20.4375C20.9898 17.5 21.4375 17.9477 21.4375 18.5C21.4375 19.0523 20.9898 19.5 20.4375 19.5H8.9375C8.38521 19.5 7.9375 19.0523 7.9375 18.5Z'
        fill='#121212'
      />
    </svg>
  )

  const menuItems: MenuProps['items'] = [
    {
      key: 'sub1',
      title: findJobs,
      label: findJobs,
      children: [
        {
          key: '1',
          label: (
            <Link
              {...(pathname.endsWith('/remote-jobs-hiring/jobs') && {
                style: {}
              })}
              className={styles.defaultLink}
              to={'/' + langKey + '/remote-jobs-hiring/jobs'}
              title='Remote Jobs'
              onClick={handleClick}
            >
              <p
                className={styles.menuList}
                {...(pathname.endsWith('/remote-jobs-hiring/jobs') && {
                  'data-gtag-event': 'rl_active_find_jobs_remote'
                })}
              >
                {remoteJobs}
              </p>
            </Link>
          )
        },
        {
          key: '2',
          label: (
            <Link
              className={styles.defaultLink}
              {...(pathname.endsWith('/jobs-hiring/job-search') && {
                style: {}
              })}
              to={'/' + langKey + '/jobs-hiring/job-search'}
              title='On-site Jobs'
              onClick={handleClick}
            >
              <p
                className={styles.menuList}
                {...(pathname.endsWith('/jobs-hiring/job-search') && {
                  'data-gtag-event': 'rl_active_find_jobs_onsite'
                })}
              >
                {onSiteJobs}
              </p>
            </Link>
          )
        },
        {
          key: '4',
          label: (
            <Link
              className={styles.defaultLink}
              {...(pathname.endsWith('/web3-jobs-hiring/job-search') && {
                style: {}
              })}
              to={'/' + langKey + '/web3-jobs-hiring/job-search'}
              title='Web3 Jobs'
              onClick={handleClick}
            >
              <p
                className={styles.menuList}
                {...(pathname.endsWith('/web3-jobs-hiring/job-search') && {
                  'data-gtag-event': 'rl_active_find_jobs_web3'
                })}
              >
                {Web3Jobs}
              </p>
            </Link>
          )
        },
        isAuthenticated && {
          key: '3',
          label: (
            <Link
              {...(pathname.endsWith('/my-jobs') && {
                style: {}
              })}
              className={styles.defaultLink}
              to={'/' + langKey + '/my-jobs?page=1'}
              title='Recommended Jobs'
              onClick={handleClick}
            >
              <p
                className={styles.menuList}
                {...(pathname.endsWith('/my-jobs') && {
                  'data-gtag-event': 'rl_active_find_jobs_recommend'
                })}
              >
                {recommendedJobs}
              </p>
            </Link>
          )
        }
      ].filter(Boolean)
    }
  ]

  return (
    <>
      <div
        className={classNames({
          [styles.maskshow]: openState,
          [styles.maskhide]: !openState && clickAfter
        })}
        onClick={() => {
          handleToggleMenu()
          setClickAfter(true)
        }}
      ></div>
      <div
        className={classNames({
          [styles.mobileFullPageMenu]: true,
          [styles.open]: openState,
          [styles.close]: !openState && clickAfter
        })}
      >
        {/* <div className={[styles.mobileFullPageMenu].join(' ') : styles.close}> */}
        <div className={styles.mobileFullPageMenuContainer}>
          <div
            style={{
              display: 'flex',
              height: 64,
              alignItems: 'center',
              justifyContent: 'flex-end',
              backgroundColor: '#F8F9FA'
            }}
          >
            <div
              className={classNames({
                [styles.closeIcon]: true,
                [styles.active]: openState
              })}
              onClick={() => {
                handleToggleMenu()
                setClickAfter(true)
              }}
            >
              {iconSwitch()}
            </div>
          </div>

          <div className={styles.menuListWrapper}>
            <div style={{ background: '#fff' }}>
              <Link
                className={styles.defaultLink}
                to='/'
                title='Home'
                {...((pathname.endsWith('/') || new RegExp(`\/${langKey}$`).test(pathname)) && {
                  style: {
                    fontWeight: 500
                  }
                })}
              >
                <p
                  className={styles.menuList}
                  onClick={handleClick}
                  {...(pathname.endsWith('/') && {
                    'data-gtag-event': 'rl_active_home'
                  })}
                >
                  {home}
                </p>
              </Link>

              {/* <Link className={styles.defaultLink} to='/jobs-hiring/job-search' title='Jobs'>
              <li className={styles.menuList} onClick={handleClick}>
                <Text textStyle={textStyle}>{findJobs}</Text>
              </li>
            </Link> */}
              <div>
                {/* <li className={styles.menuList} > */}
                {/* <Text textStyle={textStyle}>{findJobs}----</Text> */}
                <MenuStyled
                  onClick={onClick}
                  mode='inline'
                  defaultOpenKeys={isOpenMenu ? ['sub1'] : null}
                  selectable={false}
                  items={menuItems}
                />
                {/* </li> */}
              </div>
              <Link
                className={styles.defaultLink}
                to='/companies'
                title='Companies'
                {...(pathname.endsWith('/companies') && {
                  style: {
                    //fontWeight: 500
                  }
                })}
              >
                <p
                  className={styles.menuList}
                  onClick={handleClick}
                  {...(pathname.endsWith('/companies') && {
                    'data-gtag-event': 'rl_active_company'
                  })}
                >
                  <span>{companies}</span>
                </p>
              </Link>

              <Link
                className={styles.defaultLink}
                to='/web3-landing-page'
                title='web3 landing page'
                {...(pathname.endsWith('/web3-landing-page') && {
                  style: {
                    //fontWeight: 500
                  }
                })}
              >
                <p className={styles.menuList} onClick={handleClick}>
                  <span>{web3LandingPage || 'Web3 Jobs'}</span>
                </p>
              </Link>

              <Link
                className={styles.defaultLink}
                to={'/' + langKey + (countryKey === 'jp' ? '/talent' : '/talents')}
                title={startGuide}
                {...(pathname.includes('/talent') && {
                  style: {
                    // fontWeight: 500
                  }
                })}
              >
                <p className={styles.menuList} onClick={handleClick}>
                  {startGuide}
                </p>
              </Link>
              <Link
                className={styles.defaultLink}
                to='/remote-jobs'
                title='remote-jobs'
                {...(pathname.endsWith('/remote-jobs') && {
                  style: {
                    fontWeight: 500
                  }
                })}
              >
                <p className={styles.menuList} onClick={handleClick}>
                  {remoteJobs}
                </p>
              </Link>
              {/* {isAuthenticated && (
                <>
                  <Link
                    className={styles.defaultLink}
                    to='/manage-profile'
                    // to={
                    //   userCookie?.is_profile_completed
                    //     ? '/manage-profile'
                    //     : '/jobseeker-complete-profile'
                    // }
                    // to={userCookie?.is_profile_completed ? authPathToOldProject(null, '/dashboard/profile/jobseeker') : '/jobseeker-complete-profile/1'}
                    // aTag
                    title='Manage Resume'
                  >
                    <p className={styles.menuList} onClick={handleClick}>
                      <Text
                        textStyle={textStyle}
                        // className={
                        //   styles.activeLink + ' ' + (showUnCompletedDot ? styles.unCompleted : '')
                        // }
                        className={showUnCompletedDot ? styles.unCompleted : ''}
                      >
                        {manageResume}
                      </Text>
                    </p>
                  </Link>
                  <Link className={styles.defaultLink} to='/my-jobs?page=1' title='My Jobs'>
                    <p className={styles.menuList} onClick={handleClick}>
                      <Text textStyle={textStyle}>{recommendedJobs}</Text>
                    </p>
                  </Link>
                  <Link
                    className={styles.defaultLink}
                    to='/settings'
                    title='Account Settings'
                  >
                    <p className={styles.menuList} onClick={handleClick}>
                      <Text textStyle={textStyle}>{accountSettings}</Text>
                    </p>
                  </Link>
                   <Link className={styles.defaultLink} title='Jobs' to='/chat/list'>
                  <li className={styles.menuList} onClick={handleClick}>
                    <Text textStyle={textStyle}>{Chat}</Text>
                  </li>
                </Link> 
            </>
              )} */}
              {/* <Link
              className={`${styles.defaultLink}`}
              to='https://academy.bossjob.ph/courses/search-courses'
              aTag
              title='Courses'
            >
              <li className={styles.menuList} onClick={handleClick}>
                <Text textStyle={textStyle}>{courses}</Text>
              </li>
            </Link> */}

              {/* <Link
                className={styles.defaultLink}
                to={countryKey === 'jp' ? 'https://bossjob.jp/blog' : 'https://blog.bossjob.ph'}
                external
                title='Career Guide'
              >
                <p
                  className={styles.menuList}
                  onClick={handleClick}
                  {...(pathname.endsWith('/remote-jobs') && {
                    'data-gtag-event': 'rl_active_career_guide'
                  })}
                >
                  {careerGuide}
                </p>
              </Link> */}
              {/* <Link
                className={`${styles.defaultLink}`}
                to={linkToHunt('')}
                aTag
                title='Employers'
              >
                <p className={styles.menuList} onClick={handleClick}
                  {
                  ...(
                    pathname.endsWith('/remote-jobs') && {
                      'data-gtag-event': 'rl_active_employer'
                    })
                  }
                >
                  <Text textStyle={textStyle}>{hiring}</Text>
                </p>
              </Link> */}

              {/* {isAuthenticated && (
                <>
                  <Link
                    className={styles.defaultLink}
                    to='https://blog.bossjob.ph/'
                    aTag
                    title='Career Guide'
                  >
                    <p className={styles.menuList} onClick={handleClick}
                      {
                      ...(
                        pathname.endsWith('/remote-jobs') && {
                          'data-gtag-event': 'rl_active_career_guide'
                        })
                      }
                    >
                      <Text textStyle={textStyle}>{careerGuide}</Text>
                    </p>
                  </Link>





                </>
              )} */}

              <ChangeLangAndRegion
                zIndex={1002}
                show={show}
                onAction={({ show }) => {
                  setShow(show)
                }}
              />
            </div>
          </div>
        </div>
        <div
          style={{
            paddingBottom: 24
          }}
        >
          <Link
            className={styles.defaultLink}
            to={adjustUrlList?.[0]?.link?.[countryKey] ?? adjustUrlList?.[0]?.link?.default}
            target='_blank'
            title='Bossjob App'
          >
            <div
              className={styles.downloadApp}
              {...(pathname.endsWith('/remote-jobs') && {
                'data-gtag-event': 'rl_active_app'
              })}
            >
              <span style={{ color: '#fff' }}>{downloadApp}</span>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='15'
                height='14'
                viewBox='0 0 15 14'
                fill='none'
              >
                <path
                  d='M8.49589 1.92085C8.26809 1.69305 7.89874 1.69305 7.67094 1.92085C7.44313 2.14866 7.44313 2.51801 7.67094 2.74581L11.3418 6.41667L2.25008 6.41667C1.92792 6.41667 1.66675 6.67783 1.66675 7C1.66675 7.32217 1.92792 7.58333 2.25008 7.58333L11.3418 7.58333L7.67094 11.2542C7.44313 11.482 7.44313 11.8513 7.67094 12.0791C7.89874 12.307 8.26809 12.307 8.49589 12.0791L13.1626 7.41248C13.3904 7.18467 13.3904 6.81533 13.1626 6.58752L8.49589 1.92085Z'
                  fill='white'
                />
              </svg>
            </div>
          </Link>
          <div
            style={{
              width: 208,
              height: 1,
              margin: '8px auto',
              background: '#f6f6f6'
            }}
          ></div>
          <div style={{ padding: '0 24px', fontSize: 16 }}>
            <Link to={linkToHunt('')} aTag>
              <div
                {...(pathname.endsWith('/remote-jobs') && {
                  'data-gtag-event': 'rl_active_employer'
                })}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  height: 48
                }}
              >
                <span style={{ color: '#111212' }}>{hiring}</span>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='16'
                  height='16'
                  viewBox='0 0 16 16'
                  fill='none'
                >
                  <path
                    d='M5.22503 5.40815C5.22503 4.02648 6.34518 2.90635 7.72705 2.90635C9.10891 2.90635 10.2291 4.02648 10.2291 5.40815C10.2291 6.78982 9.10891 7.90996 7.72705 7.90996C6.34518 7.90996 5.22503 6.78982 5.22503 5.40815ZM7.72705 1.57581C5.61043 1.57581 3.89449 3.29157 3.89449 5.40815C3.89449 6.6469 4.48224 7.74835 5.39408 8.44892C3.39016 9.37655 2 11.4052 2 13.7585V14.4237H3.33054V13.7585C3.33054 11.2637 5.35308 9.24124 7.8481 9.24124C7.88031 9.24124 7.91199 9.23895 7.94298 9.23452C9.95915 9.12255 11.5596 7.45228 11.5596 5.40815C11.5596 3.29157 9.84367 1.57581 7.72705 1.57581ZM11.5361 9.37363C11.7314 9.5689 11.7313 9.88548 11.5361 10.0807L11.238 10.3788H13.486C13.7622 10.3788 13.986 10.6027 13.986 10.8788C13.986 11.155 13.7622 11.3788 13.486 11.3788H10.0308C9.82856 11.3788 9.64624 11.257 9.56886 11.0702C9.49147 10.8833 9.53425 10.6683 9.67726 10.5253L10.829 9.37361C11.0243 9.17836 11.3408 9.17837 11.5361 9.37363ZM11.9806 13.4035C11.7853 13.5987 11.7853 13.9153 11.9805 14.1106C12.1758 14.3059 12.4924 14.3059 12.6877 14.1106L13.8394 12.959C13.9824 12.816 14.0252 12.6009 13.9478 12.4141C13.8704 12.2272 13.6881 12.1054 13.4858 12.1054H10.0306C9.75449 12.1054 9.53063 12.3292 9.53063 12.6054C9.53063 12.8815 9.75449 13.1054 10.0306 13.1054H12.2787L11.9806 13.4035Z'
                    fill='#111212'
                  />
                </svg>
              </div>
            </Link>
          </div>
          <div
            onClick={() => {
              // postNotification('SHOW_SIDE_GUIDE_MODAL')
              // handleClick()
              setShow(true)
            }}
            style={{ padding: '12px 24px 0', fontSize: 16 }}
            {...(pathname.endsWith('/remote-jobs') && {
              'data-gtag-event': 'rl_active_country_language'
            })}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                height: 48
              }}
            >
              <div>
                <span style={{ color: '#111212' }}>{lang?.comm?.countryAndLanguage}</span>
                <span
                  style={{
                    display: 'flex',
                    padding: '6px 0',
                    alignItems: 'center',
                    fontSize: 16,
                    color: '#7D7D7D'
                  }}
                >
                  <span
                    style={{
                      width: 16,
                      height: 16,
                      // borderRadius: '50%',
                      backgroundPosition: 'center',
                      backgroundSize: 'cover',
                      backgroundImage:
                        getCountryKey() !== 'com'
                          ? `url(${S3BucketJobseeker}/flags1/${getCountryKey()?.toUpperCase()}.png)`
                          : `url(${flagInterIcon.src})`,
                      border: '1px solid #f6f6f6',
                      marginRight: 8
                    }}
                  />{' '}
                  |{' '}
                  <span style={{ marginLeft: 8, fontSize: 14 }}>{languageSlug?.toUpperCase()}</span>
                  {/* <span style={{ color: '#136FD3' }}>{change}</span> */}
                </span>
              </div>

              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='16'
                height='16'
                viewBox='0 0 16 16'
                fill='none'
              >
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M9.16549 7.99996L4.84567 3.68015C4.58532 3.4198 4.58532 2.99769 4.84567 2.73734L4.98709 2.59592C5.24744 2.33557 5.66955 2.33557 5.9299 2.59592L11.3339 7.99996L5.9299 13.404C5.66955 13.6644 5.24744 13.6644 4.98709 13.404L4.84567 13.2626C4.58532 13.0022 4.58532 12.5801 4.84567 12.3198L9.16549 7.99996Z'
                  fill='black'
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const MenuStyled = styled(Menu)`
  &.ant-menu&.ant-menu-root&.ant-menu-inline&.ant-menu-light {
    border-inline-end: none;
    margin: 0;
  }

  & .ant-menu-submenu-title {
    margin: 0;
    width: 100%;
    color: #111212;
    &:hover {
      background-color: #fff !important;
    }
  }
  & .ant-menu-submenu-open {
    & .ant-menu-submenu-title {
      color: #06f;
      font-weight: 700;
      line-height: 24px;
    }
  }

  .ant-menu-submenu {
    padding: 0;

    .ant-menu-submenu-title {
      height: 48px;

      /* span {
                      color: #353535;
      }

                    .ant-menu-submenu-arrow {
                      color: #353535;
      } */
    }

    & .ant-menu {
      background: #f8f9fa !important;
      & .ant-menu-item {
        display: flex;
        padding: 0;
        margin: 0;
        padding: 0 0 0 32px !important;
        justify-content: center;
        align-items: center;

        align-self: stretch;
        height: 48px;
        border-radius: 0;

        & .ant-menu-title-content {
          color: #111212 !important;
          a {
            padding: 0;
            color: #121212;
            font-size: 14px;
            font-weight: 400;
            margin: 0;
            display: block;
            height: 100%;
          }
        }
      }

      & .ant-menu-item:hover {
        background: #f6f6f6;
      }
    }
  }
`

export default HamburgerMenu
