export const efficiencySvg = <svg
  xmlns='http://www.w3.org/2000/svg'
  width='72'
  height='73'
  viewBox='0 0 72 73'
  fill='none'
>
  <circle cx='35.9908' cy='38.7526' r='34.1276' fill='url(#paint0_radial_14054_607)' />
  <mask
    id='mask0_14054_607'
    maskUnits='userSpaceOnUse'
    x='1'
    y='4'
    width='70'
    height='69'
  >
    <circle cx='35.9948' cy='38.7506' r='34.1276' fill='url(#paint1_radial_14054_607)' />
  </mask>
  <g mask='url(#mask0_14054_607)'>
    <rect
      x='18.7129'
      y='22.543'
      width='32.8132'
      height='34.6191'
      rx='3.20897'
      fill='url(#paint2_linear_14054_607)'
    />
    <rect
      x='22.4219'
      y='27.0605'
      width='25.8697'
      height='14.4565'
      rx='1.8337'
      fill='url(#paint3_linear_14054_607)'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M35.1202 34.6009C36.4932 34.6009 37.6062 33.4879 37.6062 32.1149C37.6062 30.7419 36.4932 29.6289 35.1202 29.6289C33.7472 29.6289 32.6342 30.7419 32.6342 32.1149C32.6342 33.4879 33.7472 34.6009 35.1202 34.6009ZM35.1197 38.9492C37.2094 38.9492 38.9035 38.2071 38.9035 37.2916C38.9035 36.3762 37.2094 35.6341 35.1197 35.6341C33.03 35.6341 31.3359 36.3762 31.3359 37.2916C31.3359 38.2071 33.03 38.9492 35.1197 38.9492Z'
      fill='white'
    />
    <path
      d='M23.041 47.6992H32.933'
      stroke='#FFB102'
      strokeWidth='1.5'
      strokeLinecap='round'
    />
    <path
      d='M23.041 51.3613H38.9041'
      stroke='#FFB102'
      strokeWidth='1.5'
      strokeLinecap='round'
    />
    <rect
      x='-19.5195'
      y='22.543'
      width='32.8132'
      height='34.6191'
      rx='3.20897'
      fill='url(#paint4_linear_14054_607)'
    />
    <rect
      x='-15.8105'
      y='27.0605'
      width='25.8697'
      height='14.4565'
      rx='1.8337'
      fill='url(#paint5_linear_14054_607)'
    />
    <rect
      x='56.9492'
      y='22.543'
      width='32.8132'
      height='34.6191'
      rx='3.20897'
      fill='url(#paint6_linear_14054_607)'
    />
  </g>
  <g filter='url(#filter0_b_14054_607)'>
    <circle
      cx='57.8433'
      cy='14.7495'
      r='13.9526'
      fill='url(#paint7_linear_14054_607)'
      fillOpacity='0.6'
    />
  </g>
  <path
    d='M51.6055 14.0741L56.9182 19.3239L65.3516 10.834'
    stroke='#FF9901'
    strokeWidth='1.5'
    strokeLinecap='round'
    strokeLinejoin='round'
  />
  <defs>
    <filter
      id='filter0_b_14054_607'
      x='39.8906'
      y='-3.20312'
      width='35.9043'
      height='35.9053'
      filterUnits='userSpaceOnUse'
      colorInterpolationFilters='sRGB'
    >
      <feFlood floodOpacity='0' result='BackgroundImageFix' />
      <feGaussianBlur in='BackgroundImageFix' stdDeviation='2' />
      <feComposite
        in2='SourceAlpha'
        operator='in'
        result='effect1_backgroundBlur_14054_607'
      />
      <feBlend
        mode='normal'
        in='SourceGraphic'
        in2='effect1_backgroundBlur_14054_607'
        result='shape'
      />
    </filter>
    <radialGradient
      id='paint0_radial_14054_607'
      cx='0'
      cy='0'
      r='1'
      gradientUnits='userSpaceOnUse'
      gradientTransform='translate(14.9232 4.625) rotate(55.6156) scale(75.0806 68.8334)'
    >
      <stop stopColor='#FFAB00' />
      <stop offset='1' stopColor='#FFCE00' />
    </radialGradient>
    <radialGradient
      id='paint1_radial_14054_607'
      cx='0'
      cy='0'
      r='1'
      gradientUnits='userSpaceOnUse'
      gradientTransform='translate(31.1057 14.2091) rotate(99.52) scale(67.7379 62.1016)'
    >
      <stop stopColor='#FAE0AA' />
      <stop offset='1' stopColor='#2378E5' />
    </radialGradient>
    <linearGradient
      id='paint2_linear_14054_607'
      x1='31.3704'
      y1='33.1545'
      x2='17.4037'
      y2='54.2695'
      gradientUnits='userSpaceOnUse'
    >
      <stop stopColor='white' />
      <stop offset='1' stopColor='#FFEBA2' />
    </linearGradient>
    <linearGradient
      id='paint3_linear_14054_607'
      x1='35.3567'
      y1='27.0605'
      x2='44.7873'
      y2='42.5022'
      gradientUnits='userSpaceOnUse'
    >
      <stop stopColor='#FFB001' />
      <stop offset='1' stopColor='#FFCD01' />
    </linearGradient>
    <linearGradient
      id='paint4_linear_14054_607'
      x1='10.8053'
      y1='14.1289'
      x2='-3.11293'
      y2='57.1621'
      gradientUnits='userSpaceOnUse'
    >
      <stop stopColor='#FFF9EB' />
      <stop offset='1' stopColor='#FFD538' />
    </linearGradient>
    <linearGradient
      id='paint5_linear_14054_607'
      x1='-2.87571'
      y1='27.0605'
      x2='6.55485'
      y2='42.5022'
      gradientUnits='userSpaceOnUse'
    >
      <stop stopColor='#FFB001' />
      <stop offset='1' stopColor='#FFCD01' />
    </linearGradient>
    <linearGradient
      id='paint6_linear_14054_607'
      x1='74.4803'
      y1='28.0956'
      x2='56.9492'
      y2='50.7503'
      gradientUnits='userSpaceOnUse'
    >
      <stop stopColor='#FFF9EB' />
      <stop offset='1' stopColor='#FFD538' />
    </linearGradient>
    <linearGradient
      id='paint7_linear_14054_607'
      x1='64.1706'
      y1='-0.633733'
      x2='47.9592'
      y2='24.0691'
      gradientUnits='userSpaceOnUse'
    >
      <stop stopColor='white' stopOpacity='0.7' />
      <stop offset='1' stopColor='white' />
    </linearGradient>
  </defs>
</svg>


export const accuracySvg = <svg
  xmlns='http://www.w3.org/2000/svg'
  width='71'
  height='69'
  viewBox='0 0 71 69'
  fill='none'
>
  <circle cx='35.9908' cy='34.7526' r='34.1276' fill='url(#paint0_radial_14054_635)' />
  <mask
    id='mask0_14054_635'
    style={{ maskType: 'alpha' }}
    maskUnits='userSpaceOnUse'
    x='1'
    y='0'
    width='70'
    height='69'
  >
    <circle cx='35.9948' cy='34.7506' r='34.1276' fill='url(#paint1_radial_14054_635)' />
  </mask>
  <g mask='url(#mask0_14054_635)'>
    <rect
      x='20.0703'
      y='13.3594'
      width='51.2208'
      height='41.1388'
      rx='3.4711'
      fill='url(#paint2_linear_14054_635)'
    />
    <path
      d='M23.9434 44.6406H36.5914M46.763 44.6406H54.6847'
      stroke='#377EFA'
      strokeWidth='1.5'
      strokeLinecap='round'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M26.6897 18.2989C26.6897 18.9516 26.1606 19.4807 25.5079 19.4807C24.8553 19.4807 24.3262 18.9516 24.3262 18.2989C24.3262 17.6463 24.8553 17.1172 25.5079 17.1172C26.1606 17.1172 26.6897 17.6463 26.6897 18.2989ZM31.0549 18.2989C31.0549 18.9516 30.5258 19.4807 29.8732 19.4807C29.2205 19.4807 28.6914 18.9516 28.6914 18.2989C28.6914 17.6463 29.2205 17.1172 29.8732 17.1172C30.5258 17.1172 31.0549 17.6463 31.0549 18.2989ZM33.9923 19.4807C34.645 19.4807 35.174 18.9516 35.174 18.2989C35.174 17.6463 34.645 17.1172 33.9923 17.1172C33.3396 17.1172 32.8105 17.6463 32.8105 18.2989C32.8105 18.9516 33.3396 19.4807 33.9923 19.4807Z'
      fill='#377EFA'
    />
    <rect
      opacity='0.4'
      x='23.5391'
      y='23.3086'
      width='17.7792'
      height='17.7792'
      rx='1.73555'
      fill='white'
    />
    <rect
      x='46.2227'
      y='23.3086'
      width='17.7792'
      height='17.7792'
      rx='1.73555'
      fill='white'
      fillOpacity='0.8'
    />
    <circle
      cx='32.4283'
      cy='32.4224'
      r='3.37704'
      stroke='#377EFA'
      strokeWidth='1.30166'
    />
  </g>
  <g filter='url(#filter0_b_14054_635)'>
    <circle
      cx='14.0737'
      cy='53.4194'
      r='13.9526'
      fill='url(#paint3_linear_14054_635)'
      fillOpacity='0.6'
    />
  </g>
  <path
    d='M16.7822 56.0101C17.4831 55.2453 17.9109 54.226 17.9109 53.1068C17.9109 50.733 15.9865 48.8086 13.6127 48.8086C11.2388 48.8086 9.31445 50.733 9.31445 53.1068C9.31445 55.4806 11.2388 57.405 13.6127 57.405C14.8673 57.405 15.9964 56.8674 16.7822 56.0101ZM16.7822 56.0101L18.8336 58.035'
    stroke='white'
    strokeWidth='1.5'
    strokeLinecap='round'
  />
  <defs>
    <filter
      id='filter0_b_14054_635'
      x='-3.87891'
      y='35.4668'
      width='35.9043'
      height='35.9053'
      filterUnits='userSpaceOnUse'
      colorInterpolationFilters='sRGB'
    >
      <feFlood floodOpacity='0' result='BackgroundImageFix' />
      <feGaussianBlur in='BackgroundImageFix' stdDeviation='2' />
      <feComposite
        in2='SourceAlpha'
        operator='in'
        result='effect1_backgroundBlur_14054_635'
      />
      <feBlend
        mode='normal'
        in='SourceGraphic'
        in2='effect1_backgroundBlur_14054_635'
        result='shape'
      />
    </filter>
    <radialGradient
      id='paint0_radial_14054_635'
      cx='0'
      cy='0'
      r='1'
      gradientUnits='userSpaceOnUse'
      gradientTransform='translate(14.9232 0.625001) rotate(68.4629) scale(70.0787 64.2477)'
    >
      <stop stopColor='#2853F7' />
      <stop offset='1' stopColor='#4BBEFD' />
    </radialGradient>
    <radialGradient
      id='paint1_radial_14054_635'
      cx='0'
      cy='0'
      r='1'
      gradientUnits='userSpaceOnUse'
      gradientTransform='translate(31.1057 10.2091) rotate(99.52) scale(67.7379 62.1016)'
    >
      <stop stopColor='#4BBEFD' />
      <stop offset='1' stopColor='#2378E5' />
    </radialGradient>
    <linearGradient
      id='paint2_linear_14054_635'
      x1='91.4792'
      y1='16.0183'
      x2='10.5663'
      y2='23.6946'
      gradientUnits='userSpaceOnUse'
    >
      <stop stopColor='white' />
      <stop offset='1' stopColor='white' stopOpacity='0' />
    </linearGradient>
    <linearGradient
      id='paint3_linear_14054_635'
      x1='20.4011'
      y1='38.0362'
      x2='4.18969'
      y2='62.739'
      gradientUnits='userSpaceOnUse'
    >
      <stop stopColor='white' stopOpacity='0.7' />
      <stop offset='1' stopColor='white' />
    </linearGradient>
  </defs>
</svg>
export const qualitySvg = <svg
  xmlns='http://www.w3.org/2000/svg'
  width='71'
  height='69'
  viewBox='0 0 71 69'
  fill='none'
>
  <circle cx='35.9908' cy='34.7526' r='34.1276' fill='url(#paint0_radial_14054_655)' />
  <mask
    id='mask0_14054_655'
    style={{ maskType: 'alpha' }}
    maskUnits='userSpaceOnUse'
    x='1'
    y='0'
    width='70'
    height='69'
  >
    <circle cx='35.9948' cy='34.7506' r='34.1276' fill='url(#paint1_radial_14054_655)' />
  </mask>
  <g mask='url(#mask0_14054_655)'>
    <rect
      x='-4.15039'
      y='21.1133'
      width='50.877'
      height='41.0329'
      rx='4.78497'
      fill='url(#paint2_linear_14054_655)'
    />
    <path
      d='M-4.15039 35.0938H46.7266V57.3605C46.7266 60.0032 44.5843 62.1455 41.9416 62.1455H0.634583C-2.00809 62.1455 -4.15039 60.0032 -4.15039 57.3605V35.0938Z'
      fill='url(#paint3_linear_14054_655)'
    />
    <g filter='url(#filter0_b_14054_655)'>
      <path
        d='M-0.699723 30.5836L0.936615 35.0936H43.0453L38.4133 19.139C37.6803 16.6142 35.0476 15.1537 32.5175 15.8682L2.49779 24.3467C-0.17731 25.1023 -1.64781 27.9705 -0.699723 30.5836Z'
        fill='white'
        fillOpacity='0.6'
      />
    </g>
  </g>
  <g filter='url(#filter1_b_14054_655)'>
    <circle
      cx='56.1675'
      cy='14.5776'
      r='13.9526'
      fill='url(#paint4_linear_14054_655)'
      fillOpacity='0.6'
    />
  </g>
  <path
    d='M56.8075 20.1279V9.02637M56.8075 9.02637L52.1758 13.4291M56.8075 9.02637L61.2096 13.4291'
    stroke='white'
    strokeWidth='1.5'
    strokeLinecap='round'
    strokeLinejoin='round'
  />
  <defs>
    <filter
      id='filter0_b_14054_655'
      x='-5.28946'
      y='11.3853'
      width='52.6356'
      height='28.0096'
      filterUnits='userSpaceOnUse'
      colorInterpolationFilters='sRGB'
    >
      <feFlood floodOpacity='0' result='BackgroundImageFix' />
      <feGaussianBlur in='BackgroundImageFix' stdDeviation='2.15059' />
      <feComposite
        in2='SourceAlpha'
        operator='in'
        result='effect1_backgroundBlur_14054_655'
      />
      <feBlend
        mode='normal'
        in='SourceGraphic'
        in2='effect1_backgroundBlur_14054_655'
        result='shape'
      />
    </filter>
    <filter
      id='filter1_b_14054_655'
      x='38.2148'
      y='-3.375'
      width='35.9043'
      height='35.9053'
      filterUnits='userSpaceOnUse'
      colorInterpolationFilters='sRGB'
    >
      <feFlood floodOpacity='0' result='BackgroundImageFix' />
      <feGaussianBlur in='BackgroundImageFix' stdDeviation='2' />
      <feComposite
        in2='SourceAlpha'
        operator='in'
        result='effect1_backgroundBlur_14054_655'
      />
      <feBlend
        mode='normal'
        in='SourceGraphic'
        in2='effect1_backgroundBlur_14054_655'
        result='shape'
      />
    </filter>
    <radialGradient
      id='paint0_radial_14054_655'
      cx='0'
      cy='0'
      r='1'
      gradientUnits='userSpaceOnUse'
      gradientTransform='translate(14.9232 0.625001) rotate(68.4629) scale(70.0787 64.2477)'
    >
      <stop stopColor='#51C25C' />
      <stop offset='1' stopColor='#D3FE93' />
    </radialGradient>
    <radialGradient
      id='paint1_radial_14054_655'
      cx='0'
      cy='0'
      r='1'
      gradientUnits='userSpaceOnUse'
      gradientTransform='translate(31.1057 10.2091) rotate(99.52) scale(67.7379 62.1016)'
    >
      <stop stopColor='#4BFD72' />
      <stop offset='1' stopColor='#2378E5' />
    </radialGradient>
    <linearGradient
      id='paint2_linear_14054_655'
      x1='21.2881'
      y1='21.1133'
      x2='21.2881'
      y2='62.1461'
      gradientUnits='userSpaceOnUse'
    >
      <stop stopColor='#9DF042' />
      <stop offset='1' stopColor='#A0F045' />
    </linearGradient>
    <linearGradient
      id='paint3_linear_14054_655'
      x1='21.2881'
      y1='35.0938'
      x2='21.2881'
      y2='62.1455'
      gradientUnits='userSpaceOnUse'
    >
      <stop stopColor='#CBF976' />
      <stop offset='1' stopColor='#A0F046' />
    </linearGradient>
    <linearGradient
      id='paint4_linear_14054_655'
      x1='62.4948'
      y1='-0.805608'
      x2='46.2834'
      y2='23.8972'
      gradientUnits='userSpaceOnUse'
    >
      <stop stopColor='#F9FFF8' stopOpacity='0.7' />
      <stop offset='1' stopColor='#D3FFA7' />
    </linearGradient>
  </defs>
</svg>

export const angleSvg = <svg
  xmlns='http://www.w3.org/2000/svg'
  width='14'
  height='7'
  viewBox='0 0 14 7'
  fill='none'
>
  <path
    d='M5.95159 1.33157L0.308594 6.625H13.9336L8.7408 1.38275C7.97838 0.613066 6.74173 0.590376 5.95159 1.33157Z'
    fill='white'
  />
</svg>

export const chatSvg = <svg xmlns="http://www.w3.org/2000/svg" width="26" height="30" viewBox="0 0 26 30" fill="none">
  <ellipse cx="10.1012" cy="12.074" rx="8.40589" ry="8.40509" fill="url(#paint0_linear_14054_7821)" />
  <g filter="url(#filter0_d_14054_7821)">
    <path fillRule="evenodd" clipRule="evenodd" d="M13.4532 19.2204C13.6313 19.3017 13.7648 19.4648 13.8648 19.6635C14.1635 20.2572 14.1635 21.169 14.1635 21.169L14.6914 22.573L12.6635 23.3354L12.6635 21.1736L12.6635 21.1724L12.6629 21.1447C12.662 21.1168 12.6601 21.072 12.6562 21.0157C12.6494 20.9194 12.6373 20.8022 12.6173 20.6837C7.81831 20.2608 4.05469 16.2313 4.05469 11.3226C4.05469 6.1322 8.26269 1.9248 13.4532 1.9248C18.6437 1.9248 22.8518 6.1322 22.8518 11.3226C22.8518 13.1461 22.2822 15.3202 21.3126 16.8248C19.9337 18.9643 18.3096 20.3882 17.0236 21.2798C16.3802 21.7259 15.8181 22.0412 15.4077 22.2484C15.2023 22.3521 15.0342 22.4291 14.9122 22.4822C14.8511 22.5088 14.8015 22.5294 14.7643 22.5444C14.7458 22.5519 14.7303 22.558 14.7181 22.5628L14.7023 22.5689L14.6962 22.5712L14.6937 22.5722L14.6925 22.5726C14.6919 22.5728 14.6914 22.573 14.1635 21.169C14.1635 21.169 14.7475 20.9494 15.6097 20.4144C16.8234 19.6612 18.5883 18.283 20.0517 16.0122C20.8455 14.7806 21.3518 12.8967 21.3518 11.3226C21.3518 6.96076 17.8155 3.4248 13.4532 3.4248C9.09098 3.4248 5.55469 6.96076 5.55469 11.3226C5.55469 15.6844 9.09098 19.2204 13.4532 19.2204ZM12.5211 20.3309C12.5212 20.3308 12.5224 20.3328 12.5247 20.3373C12.5222 20.3333 12.5211 20.331 12.5211 20.3309ZM13.7164 10.4307C13.3022 10.4307 12.9664 10.7665 12.9664 11.1807C12.9664 11.5949 13.3022 11.9307 13.7164 11.9307H18.0205C18.4347 11.9307 18.7705 11.5949 18.7705 11.1807C18.7705 10.7665 18.4347 10.4307 18.0205 10.4307H13.7164Z" fill="#2378E5" />
  </g>
  <defs>
    <filter id="filter0_d_14054_7821" x="1.05469" y="1.9248" width="24.7969" height="27.4102" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
      <feFlood floodOpacity="0" result="BackgroundImageFix" />
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
      <feOffset dy="3" />
      <feGaussianBlur stdDeviation="1.5" />
      <feComposite in2="hardAlpha" operator="out" />
      <feColorMatrix type="matrix" values="0 0 0 0 0.591667 0 0 0 0 0.770576 0 0 0 0 1 0 0 0 0.5 0" />
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_14054_7821" />
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_14054_7821" result="shape" />
    </filter>
    <linearGradient id="paint0_linear_14054_7821" x1="10.1012" y1="3.66895" x2="0.768553" y2="21.5051" gradientUnits="userSpaceOnUse">
      <stop stopColor="#C4DDFE" />
      <stop offset="1" stopColor="#C4DDFE" stopOpacity="0" />
    </linearGradient>
  </defs>
</svg>

export const recommendationsSvg = <svg xmlns="http://www.w3.org/2000/svg" width="26" height="29" viewBox="0 0 26 29" fill="none">
  <ellipse cx="10.3649" cy="13.5945" rx="8.40589" ry="8.40509" fill="url(#paint0_linear_14054_7829)" />
  <g filter="url(#filter0_d_14054_7829)">
    <path fillRule="evenodd" clipRule="evenodd" d="M6.67502 12.5746L20.9881 4.58308L17.3588 20.5225L14.2389 14.18C14.0393 13.7741 13.65 13.4947 13.2015 13.4356L6.67502 12.5746ZM22.5237 4.59517C22.7839 3.45245 21.5675 2.5416 20.5442 3.11294L5.55796 11.4803C4.42042 12.1154 4.74911 13.8335 6.04075 14.0039L12.9276 14.9124L16.1953 21.5557C16.7582 22.6999 18.4464 22.5021 18.7295 21.2588L22.5237 4.59517ZM18.6189 9.1506C18.897 8.84363 18.8736 8.36934 18.5666 8.09123C18.2597 7.81312 17.7854 7.83651 17.5073 8.14348L14.4836 11.4809C14.2055 11.7878 14.2289 12.2621 14.5359 12.5402C14.8428 12.8183 15.3171 12.7949 15.5952 12.488L18.6189 9.1506Z" fill="#2378E5" />
  </g>
  <defs>
    <filter id="filter0_d_14054_7829" x="1.86328" y="2.9375" width="23.6953" height="25.375" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
      <feFlood floodOpacity="0" result="BackgroundImageFix" />
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
      <feOffset dy="3" />
      <feGaussianBlur stdDeviation="1.5" />
      <feComposite in2="hardAlpha" operator="out" />
      <feColorMatrix type="matrix" values="0 0 0 0 0.591667 0 0 0 0 0.770576 0 0 0 0 1 0 0 0 0.5 0" />
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_14054_7829" />
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_14054_7829" result="shape" />
    </filter>
    <linearGradient id="paint0_linear_14054_7829" x1="10.3649" y1="5.18945" x2="1.03222" y2="23.0256" gradientUnits="userSpaceOnUse">
      <stop stopColor="#C4DDFE" />
      <stop offset="1" stopColor="#C4DDFE" stopOpacity="0" />
    </linearGradient>
  </defs>
</svg>

export const informationSvg = <svg xmlns="http://www.w3.org/2000/svg" width="26" height="27" viewBox="0 0 26 27" fill="none">
  <ellipse cx="10.3649" cy="13.5945" rx="8.40589" ry="8.40509" fill="url(#paint0_linear_14054_7837)" />
  <g filter="url(#filter0_d_14054_7837)">
    <path fillRule="evenodd" clipRule="evenodd" d="M7.78711 2.62695C7.78711 2.48888 7.89904 2.37695 8.03711 2.37695H20.8176C20.9556 2.37695 21.0676 2.48888 21.0676 2.62695V18.5401C21.0676 18.7254 20.873 18.8463 20.7068 18.7643L15.0272 15.9593C14.5275 15.7125 13.9401 15.7185 13.4456 15.9754L8.15235 18.7248C7.98593 18.8113 7.78711 18.6905 7.78711 18.503V2.62695ZM8.03711 0.876953C7.07061 0.876953 6.28711 1.66045 6.28711 2.62695V18.503C6.28711 19.8157 7.67883 20.6611 8.84379 20.056L14.137 17.3065C14.2077 17.2698 14.2916 17.2689 14.3629 17.3042L20.0426 20.1092C21.2057 20.6836 22.5676 19.8373 22.5676 18.5401V2.62695C22.5676 1.66045 21.784 0.876953 20.8176 0.876953H8.03711ZM10.5545 7.69302L18.365 7.69302C18.7792 7.69302 19.115 7.35723 19.115 6.94302C19.115 6.5288 18.7792 6.19302 18.365 6.19302L10.5545 6.19302C10.1402 6.19302 9.80446 6.52881 9.80446 6.94302C9.80446 7.35723 10.1402 7.69302 10.5545 7.69302Z" fill="#2378E5" />
  </g>
  <ellipse cx="10.3649" cy="13.5945" rx="8.40589" ry="8.40509" fill="url(#paint1_linear_14054_7837)" />
  <g filter="url(#filter1_d_14054_7837)">
    <path fillRule="evenodd" clipRule="evenodd" d="M7.78711 2.62695C7.78711 2.48888 7.89904 2.37695 8.03711 2.37695H20.8176C20.9556 2.37695 21.0676 2.48888 21.0676 2.62695V18.5401C21.0676 18.7254 20.873 18.8463 20.7068 18.7643L15.0272 15.9593C14.5275 15.7125 13.9401 15.7185 13.4456 15.9754L8.15235 18.7248C7.98593 18.8113 7.78711 18.6905 7.78711 18.503V2.62695ZM8.03711 0.876953C7.07061 0.876953 6.28711 1.66045 6.28711 2.62695V18.503C6.28711 19.8157 7.67883 20.6611 8.84379 20.056L14.137 17.3065C14.2077 17.2698 14.2916 17.2689 14.3629 17.3042L20.0426 20.1092C21.2057 20.6836 22.5676 19.8373 22.5676 18.5401V2.62695C22.5676 1.66045 21.784 0.876953 20.8176 0.876953H8.03711ZM10.5545 7.69302L18.365 7.69302C18.7792 7.69302 19.115 7.35723 19.115 6.94302C19.115 6.5288 18.7792 6.19302 18.365 6.19302L10.5545 6.19302C10.1402 6.19302 9.80446 6.52881 9.80446 6.94302C9.80446 7.35723 10.1402 7.69302 10.5545 7.69302Z" fill="#2378E5" />
  </g>
  <defs>
    <filter id="filter0_d_14054_7837" x="3.28711" y="0.876953" width="22.2812" height="25.415" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
      <feFlood floodOpacity="0" result="BackgroundImageFix" />
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
      <feOffset dy="3" />
      <feGaussianBlur stdDeviation="1.5" />
      <feComposite in2="hardAlpha" operator="out" />
      <feColorMatrix type="matrix" values="0 0 0 0 0.591667 0 0 0 0 0.770576 0 0 0 0 1 0 0 0 0.71 0" />
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_14054_7837" />
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_14054_7837" result="shape" />
    </filter>
    <filter id="filter1_d_14054_7837" x="3.28711" y="0.876953" width="22.2812" height="25.415" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
      <feFlood floodOpacity="0" result="BackgroundImageFix" />
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
      <feOffset dy="3" />
      <feGaussianBlur stdDeviation="1.5" />
      <feComposite in2="hardAlpha" operator="out" />
      <feColorMatrix type="matrix" values="0 0 0 0 0.591667 0 0 0 0 0.770576 0 0 0 0 1 0 0 0 0.05 0" />
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_14054_7837" />
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_14054_7837" result="shape" />
    </filter>
    <linearGradient id="paint0_linear_14054_7837" x1="10.3649" y1="5.18945" x2="1.03222" y2="23.0256" gradientUnits="userSpaceOnUse">
      <stop stopColor="#C4DDFE" />
      <stop offset="1" stopColor="#C4DDFE" stopOpacity="0" />
    </linearGradient>
    <linearGradient id="paint1_linear_14054_7837" x1="10.3649" y1="5.18945" x2="1.03222" y2="23.0256" gradientUnits="userSpaceOnUse">
      <stop stopColor="#C4DDFE" />
      <stop offset="1" stopColor="#C4DDFE" stopOpacity="0" />
    </linearGradient>
  </defs>
</svg>

export const personSvg = <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path fillRule="evenodd" clipRule="evenodd" d="M11.7637 4.25C9.66082 4.25 7.95605 5.95476 7.95605 8.05769C7.95605 10.1606 9.66082 11.8654 11.7637 11.8654C13.8667 11.8654 15.5714 10.1606 15.5714 8.05769C15.5714 5.95476 13.8667 4.25 11.7637 4.25ZM5.95605 8.05769C5.95605 4.85019 8.55625 2.25 11.7637 2.25C14.9712 2.25 17.5714 4.85019 17.5714 8.05769C17.5714 11.2652 14.9712 13.8654 11.7637 13.8654C8.55625 13.8654 5.95605 11.2652 5.95605 8.05769Z" fill="white" />
  <path fillRule="evenodd" clipRule="evenodd" d="M11.7637 13.8729C7.56976 13.8729 4.16992 17.2728 4.16992 21.4667H2.16992C2.16992 16.1682 6.46519 11.8729 11.7637 11.8729C17.0622 11.8729 21.3574 16.1682 21.3574 21.4667H19.3574C19.3574 17.2728 15.9576 13.8729 11.7637 13.8729Z" fill="white" />
</svg>
