'use client'
import React, { useState, useEffect, useRef } from 'react'
import styles from './index.module.scss'
import { isMobile } from 'react-device-detect'
const MessageModal = (props: { message: string }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isTextOverflow, setIsTextOverflow] = useState(props.message?.length > 100)
  const textRef = useRef<HTMLParagraphElement>(null)
  useEffect(() => {
    const modalText = document.getElementById('modalText')
    if (modalText) {
      textRef.current = modalText as HTMLParagraphElement
    }
    const checkOverflow = () => {
      if (textRef.current) {
        // 检查是否为移动设备
        if (isMobile) {
          // 在移动设备上,检查文本是否超过2行
          const lineHeight = parseInt(window.getComputedStyle(textRef.current).lineHeight)
          const height = textRef.current.offsetHeight
          setIsTextOverflow(height >= lineHeight * 2)
        } else {
          // 在桌面设备上创建一个临时元素来检测溢出
          const tempSpan = document.createElement('span')
          tempSpan.style.visibility = 'hidden'
          tempSpan.style.position = 'absolute'
          tempSpan.style.whiteSpace = 'nowrap'
          tempSpan.style.font = window.getComputedStyle(textRef.current).font
          tempSpan.innerText = props.message
          document.body.appendChild(tempSpan)

          const textWidth = tempSpan.offsetWidth
          const containerWidth = textRef.current.offsetWidth

          document.body?.removeChild(tempSpan)
          setIsTextOverflow(textWidth > containerWidth)
        }
      }
    }

    checkOverflow()
    window.addEventListener('resize', checkOverflow)
    return () => window.removeEventListener('resize', checkOverflow)
  }, [props.message])
  return (
    <>
      {isTextOverflow && (
        <div className={styles.viewMoreBtn} onClick={() => setIsModalOpen(true)}>
          <svg
            width='20'
            height='20'
            viewBox='0 0 20 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M11.457 10.0001L6.05727 4.60031C5.73184 4.27487 5.73184 3.74723 6.05727 3.42179L6.23405 3.24502C6.55949 2.91958 7.08712 2.91958 7.41256 3.24502L14.1676 10.0001L7.41256 16.7551C7.08712 17.0806 6.55949 17.0806 6.23405 16.7551L6.05727 16.5784C5.73184 16.2529 5.73184 15.7253 6.05727 15.3998L11.457 10.0001Z'
              fill='#FF9000'
            />
          </svg>
        </div>
      )}

      {isModalOpen && (
        <>
          <div className={styles.overlay} onClick={() => setIsModalOpen(false)} />
          <div className={styles.modal}>
            <button className={styles.closeBtn} onClick={() => setIsModalOpen(false)}>
              ✕
            </button>
            <div className={styles.titleContainer}>
              <svg
                width='20'
                height='20'
                viewBox='0 0 20 20'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M18.3334 9.99984C18.3334 14.6022 14.6025 18.3332 10.0001 18.3332C5.39771 18.3332 1.66675 14.6022 1.66675 9.99984C1.66675 5.39746 5.39771 1.6665 10.0001 1.6665C14.6025 1.6665 18.3334 5.39746 18.3334 9.99984ZM10.8342 4.99984V11.6665H9.16756V4.99984H10.8342ZM10.8334 14.6125V12.9458H9.16675V14.6125H10.8334Z'
                  fill='#FF9000'
                />
              </svg>
              <div className={styles.title}>Notice</div>
            </div>
            <div className={styles.modalContent}>{props.message}</div>
          </div>
        </>
      )}
    </>
  )
}

export default MessageModal
