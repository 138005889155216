'use client'
import React, { useMemo } from 'react'
import { isMobileDevice } from 'helpers/utilities'
import { useSharedData } from 'bossjob-remote/dist/hooks'
import classNames from 'classnames'

import { CardIcon, MobileCardIcon } from './CardIcon'
import ClientButton, { PTag } from './ClientButton'
// import ClientClose from './ClientClose'

import styles from './index.module.scss'

export const CompleteProfileCard = ({ cardType = 'column' }) => {
  const isMobile = isMobileDevice()
  const userDetail = useSharedData('ME')
  const dic = useSharedData('DICTIONARY') || {}
  const { profile = {} } = dic

  const show = useMemo(() => {
    if (!userDetail) {
      return false
    }
    return !userDetail?.is_profile_completed
  }, [userDetail])

  if (isMobile && show) {
    return (
      <div className={classNames([styles.mobile, 'complete-profile-card'])}>
        <div className={styles.mobile_content}>
          <div className={styles.title}>{profile.completedCard?.title}</div>
          <p className={styles.description + ' ' + styles.mobile_description}>
            {profile.completedCard?.description}
          </p>
          <div className={styles.mobile_button}>
            <ClientButton dictionary={dic} userDetail={userDetail} />
          </div>
        </div>
        <div className={styles.mobile_icon}>
          <MobileCardIcon />
        </div>
      </div>
    )
  }

  return show ? (
    <div
      className={classNames({
        [styles.newMain]: true,
        [styles.newMainRow]: cardType === 'row'
      })}
    >
      <div className={styles.newMain_left}>
        {profile.completedCard?.title}
        <p>{profile.completedCard?.description}</p>
      </div>

      <div className={styles.newMain_right}>
        <ClientButton dictionary={dic} userDetail={userDetail} />
      </div>

      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='106'
        height='94'
        viewBox='0 0 106 94'
        fill='none'
        className={styles.newMainSvg}
      >
        <g opacity='0.06'>
          <path
            d='M20.6498 76.3007V-6.29932H96.2003L100.3 45.416L70.7998 76.3007H20.6498Z'
            fill='#DFE5ED'
            fill-opacity='0.1'
          />
          <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M14.75 0.583496C14.75 -7.56271 21.3538 -14.1665 29.5 -14.1665H88.5C96.6462 -14.1665 103.25 -7.56268 103.25 0.583513V42.8567C103.25 46.5139 101.891 50.0407 99.4377 52.7527L75.407 79.3128C72.6107 82.4035 68.6373 84.1668 64.4694 84.1668H29.5C21.3538 84.1668 14.75 77.563 14.75 69.4168V0.583496ZM29.5 -4.33317C26.7846 -4.33317 24.5833 -2.1319 24.5833 0.583497L24.5833 69.4168C24.5833 72.1322 26.7846 74.3335 29.5 74.3335H63.9167V54.6668C63.9167 46.5206 70.5205 39.9168 78.6667 39.9168L93.4167 39.9168V0.583512C93.4167 -2.1319 91.2154 -4.33317 88.5 -4.33317L29.5 -4.33317ZM88.8934 49.7502L78.6667 49.7502C75.9513 49.7502 73.75 51.9514 73.75 54.6668V66.4876L88.8934 49.7502Z'
            fill='url(#paint0_linear_4031_56107)'
          />
          <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M39.3333 10.4165H78.6666V20.2498H39.3333V10.4165ZM39.3333 30.0832H63.9166V39.9165H39.3333V30.0832Z'
            fill='url(#paint1_linear_4031_56107)'
          />
        </g>
        <defs>
          <linearGradient
            id='paint0_linear_4031_56107'
            x1='-26.1477'
            y1='35.0002'
            x2='103.25'
            y2='35.0002'
            gradientUnits='userSpaceOnUse'
          >
            <stop stop-color='#82DEFF' />
            <stop offset='1' stop-color='#0066FF' />
          </linearGradient>
          <linearGradient
            id='paint1_linear_4031_56107'
            x1='21.1565'
            y1='25.1665'
            x2='78.6666'
            y2='25.1665'
            gradientUnits='userSpaceOnUse'
          >
            <stop stop-color='#82DEFF' />
            <stop offset='1' stop-color='#0066FF' />
          </linearGradient>
        </defs>
      </svg>
    </div>
  ) : null
}

export default CompleteProfileCard
