'use client'
import React, { useEffect, useState } from 'react'
import styles from './index.module.scss'
import { fetchSystemMaintainService } from 'store/services/config/fetchNotify'
import MessageModal from './modal'

const MaintainNotification = (props: { langKey: string; lang: any }) => {
  const [is_expired, set_is_expired] = useState(false)
  const [message, setMessage] = useState('')

  useEffect(() => {
    fetchSystemMaintainService(props.langKey || 'en').then((res) => {
      const data = res?.data
      set_is_expired(!data?.is_expired)
      setMessage(data?.value || '')
    })
  }, [props.langKey])

  if (is_expired) {
    return (
      <div className={styles.maintainNotification}>
        <div className={styles.content}>
          {/* <svg
            className={styles.icon}
            xmlns='http://www.w3.org/2000/svg'
            width='18'
            height='18'
            viewBox='0 0 18 18'
            fill='none'
          >
            <path
              d='M10.3023 2.27923C9.72649 1.27151 8.27344 1.27151 7.6976 2.27923L1.28237 13.5059C0.710951 14.5059 1.433 15.7501 2.58474 15.7501H15.4152C16.5669 15.7501 17.289 14.5059 16.7176 13.5059L10.3023 2.27923ZM9.7507 6.75009V10.5001H8.2507V6.75009H9.7507ZM8.24997 11.6251H9.74997V13.1251H8.24997V11.6251Z'
              fill='#D25F00'
            />
          </svg> */}

          <p className={styles.text}>{message}</p>
          <MessageModal message={message} />
        </div>
      </div>
    )
  }

  return null
}

export default MaintainNotification
