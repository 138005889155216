'use client'
import { getCookie } from 'helpers/cookies'
import { removeEmptyOrNullValues } from 'helpers/formatter'
import React, { useEffect } from 'react'
import { utmLogsService } from 'store/services/auth/utmLog'
import { fetchCheckFirstRegistration } from 'store/services/newIndex'

const utm_log_key = 'utm_logs_key'

const UtmTrackerEventClient = () => {
  const params = new URLSearchParams(window.location.search)
  const utm_source = params.get('utm_source')
  const utm_medium = params.get('utm_medium')
  const utm_campaign = params.get('utm_campaign')
  const utm_content = params.get('utm_content')
  const utm_id = params.get('utm_id')
  const utm_term = params.get('utm_term')
  const utm = {
    utm_source,
    utm_medium,
    utm_campaign,
    utm_content,
    utm_term,
    utm_id
  }
  const values = removeEmptyOrNullValues(utm)
  const sessionid = getCookie('sessionid')
  const user = getCookie('user')

  function checkUtmValues(values) {
    if (values?.utm_source && values?.utm_medium && values?.utm_campaign) {
      return true
    }
    return false
  }

  useEffect(() => {
    if (typeof window != 'undefined') {
      try {
        const check = checkUtmValues(values)
        if (check) {
          // set local storage
          localStorage.setItem(utm_log_key, JSON.stringify(values))
        }

        // user is logged in
        if (sessionid && user?.id) {
          // get from local storage
          const utm = localStorage.getItem(utm_log_key)
          const utmValues = JSON.parse(utm) || {}
          const check = checkUtmValues(utmValues)
          if (check) {
            fetchCheckFirstRegistration().then((res) => {
              if (res?.data?.data?.is_first_registration) {
                const payload = {
                  user_id: user?.id || '',
                  utm_source: utmValues?.utm_source || '',
                  utm_medium: utmValues?.utm_medium || '',
                  utm_campaign: utmValues?.utm_campaign || '',
                  utm_content: utmValues?.utm_content || '',
                  utm_term: utmValues?.utm_term || '',
                  utm_id: utmValues?.utm_id || '',
                  company_id: 0
                }
                // send to server
                utmLogsService(payload)
                  // .then(() => {
                  //   localStorage.removeItem(utm_log_key)
                  // })
                  .catch(() => {})
                  .finally(() => {
                    localStorage.removeItem(utm_log_key)
                  })
              }
            })
          }
        }
      } catch (error) {}
    }
  }, [user])

  return <></>
}

export default UtmTrackerEventClient
