'use client'
import React from 'react'
// import { isMobile } from 'react-device-detect'

import Link from 'components/Link'
import styles from '../Header.module.scss'
import { getCountryKey } from 'helpers/country'
import { getCookie } from 'helpers/cookies'

interface IProps {
  langKey: string
  pageType?: string
  isMobile?: boolean
  isLoginPage?: boolean
}

export const LogoIcon = ({
  isMobile,
  height = 32,
  width = 114,
  isLoginPage = false
}: {
  isMobile?: boolean
  height?: number
  width?: number
  isLoginPage?: boolean
}) => {
  const isLogin = getCookie('sessionid') ? true : false
  if (isLoginPage && isMobile) {
    return (
      <svg
        width='40'
        height='40'
        viewBox='0 0 40 40'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <rect width='40' height='40' rx='9.14286' fill='#0066FF' />
        <path
          d='M8.11719 18.5855C8.11719 19.5243 8.80503 20.2189 9.75298 20.2189C9.96923 20.2242 10.1843 20.1855 10.3851 20.1053C10.586 20.0251 10.7684 19.905 10.9214 19.7522C11.0743 19.5995 11.1946 19.4173 11.275 19.2168C11.3553 19.0162 11.394 18.8015 11.3888 18.5855C11.3888 17.6053 10.6837 16.9521 9.75298 16.9521C8.80503 16.9521 8.11719 17.6053 8.11719 18.5855Z'
          fill='#FFC53D'
        />
        <path
          d='M18.5273 15.7074C20.4859 15.7074 21.6673 14.2696 21.6673 12.597C21.6673 10.9245 20.4843 9.50784 18.5273 9.50784C16.5703 9.50784 15.4093 10.9238 15.4093 12.597C15.4093 14.2703 16.5923 15.7074 18.5273 15.7074ZM18.5273 14.3126C17.5598 14.3126 17.0224 13.5194 17.0224 12.597C17.0224 11.6747 17.5598 10.8807 18.5273 10.8807C19.5168 10.8807 20.0542 11.6747 20.0542 12.597C20.0542 13.5194 19.5168 14.3118 18.5273 14.3118V14.3126ZM25.2914 15.7074C26.9256 15.7074 27.872 14.9134 27.872 13.8197C27.872 11.4385 24.3466 12.1676 24.3466 11.3102C24.3466 10.9879 24.7117 10.7305 25.271 10.7305C26.002 10.7305 26.6694 11.0528 27.0345 11.4174L27.6613 10.3222C26.9794 9.7881 26.1361 9.50098 25.2695 9.50784C23.7026 9.50784 22.8408 10.3683 22.8408 11.3743C22.8408 13.6907 26.3467 12.8974 26.3467 13.862C26.3467 14.2273 26.0239 14.4878 25.3572 14.4878C24.6263 14.4878 23.7441 14.0802 23.314 13.6727L22.6458 14.7812C23.2913 15.364 24.2808 15.7074 25.2914 15.7074ZM31.5023 15.7074C33.1365 15.7074 34.0829 14.9134 34.0829 13.8197C34.0829 11.4385 30.5575 12.1676 30.5575 11.3102C30.5575 10.9879 30.9233 10.7305 31.4827 10.7305C32.2136 10.7305 32.8803 11.0528 33.2454 11.4174L33.8721 10.3222C33.1916 9.78893 32.3501 9.50161 31.4851 9.50706C29.9143 9.50706 29.0564 10.3676 29.0564 11.3736C29.0564 13.6899 32.5615 12.8967 32.5615 13.8612C32.5615 14.2265 32.2387 14.487 31.572 14.487C30.8411 14.487 29.9589 14.0795 29.5288 13.6719L28.8574 14.7812C29.481 15.364 30.4697 15.7074 31.5023 15.7074Z'
          fill='white'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M8.42578 6.83594H10.0004V9.54577H11.1308C11.5369 9.54577 11.939 9.62563 12.3142 9.78081C12.6894 9.93598 13.0303 10.1634 13.3174 10.4502C13.6046 10.7369 13.8323 11.0773 13.9877 11.4519C14.1431 11.8265 14.2231 12.228 14.2231 12.6335C14.2231 13.4524 13.8973 14.2378 13.3174 14.8169C12.7375 15.396 11.951 15.7213 11.1308 15.7213H8.42578V6.83594ZM9.99987 13.8767V14.3554H10.8987L10.8982 14.3544C11.128 14.3545 11.3556 14.3093 11.5679 14.2215C11.7802 14.1337 11.9732 14.005 12.1357 13.8428C12.2982 13.6805 12.4271 13.4878 12.515 13.2758C12.6029 13.0638 12.6481 12.8366 12.6481 12.6071C12.6481 12.1437 12.4637 11.6992 12.1355 11.3715C11.8074 11.0438 11.3623 10.8597 10.8982 10.8597H10.0004V13.8767H9.99987Z'
          fill='white'
        />
        <path
          d='M7.70891 33.1641C6.88974 33.1641 6.33719 33.0299 5.91797 32.8765L6.41349 30.7877C6.69508 30.8869 6.99115 30.9388 7.28969 30.9412C8.20363 30.9412 8.47106 30.3282 8.47106 29.1216V21.3941H11.2715V29.0477C11.2715 31.3461 10.3945 33.1641 7.70891 33.1641Z'
          fill='white'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M17.8041 31.7777C21.1434 31.7777 23.1616 29.3211 23.1616 26.4619C23.1616 23.6028 21.1418 21.1822 17.8041 21.1822C14.4663 21.1822 12.4818 23.6044 12.4818 26.4619C12.4818 29.3195 14.5001 31.7777 17.8041 31.7777ZM17.8041 29.3942C16.1521 29.3942 15.2341 28.0381 15.2341 26.4611C15.2341 24.8841 16.1513 23.528 17.8041 23.528C19.4906 23.528 20.4094 24.8849 20.4094 26.4611C20.4094 28.0373 19.4906 29.3942 17.8041 29.3942Z'
          fill='white'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M24.3767 16.9141H27.0021V21.4402H28.887C29.5641 21.4402 30.2345 21.5736 30.8601 21.8328C31.4856 22.092 32.054 22.4719 32.5328 22.9508C33.0116 23.4297 33.3914 23.9983 33.6505 24.624C33.9096 25.2498 34.043 25.9204 34.043 26.5977C34.043 27.9656 33.4997 29.2774 32.5328 30.2447C31.5659 31.2119 30.2544 31.7553 28.887 31.7553H24.3767V16.9141ZM27.0013 28.6744V29.4738H28.4999L28.4991 29.4722C28.8823 29.4723 29.2617 29.3968 29.6158 29.2502C29.9698 29.1036 30.2915 28.8886 30.5624 28.6175C30.8334 28.3465 31.0483 28.0247 31.1949 27.6706C31.3415 27.3164 31.4169 26.9369 31.4168 26.5535C31.4168 25.7795 31.1094 25.0371 30.5622 24.4898C30.015 23.9424 29.2729 23.6349 28.4991 23.6349H27.0021V28.6744H27.0013Z'
          fill='white'
        />
      </svg>
    )
  }
  if (isLogin && isMobile) {
    return (
      <svg
        width='40'
        height='40'
        viewBox='0 0 40 40'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <rect width='40' height='40' rx='9.14286' fill='#1C70EE' />
        <path
          d='M8.11792 18.5853C8.11792 19.524 8.80577 20.2187 9.75371 20.2187C9.96996 20.2239 10.185 20.1853 10.3859 20.1051C10.5867 20.0248 10.7691 19.9047 10.9221 19.752C11.0751 19.5992 11.1954 19.4171 11.2757 19.2165C11.3561 19.016 11.3948 18.8012 11.3895 18.5853C11.3895 17.6051 10.6844 16.9519 9.75371 16.9519C8.80577 16.9519 8.11792 17.6051 8.11792 18.5853Z'
          fill='#FFC53D'
        />
        <path
          d='M18.5268 15.7074C20.4854 15.7074 21.6668 14.2696 21.6668 12.597C21.6668 10.9245 20.4838 9.50784 18.5268 9.50784C16.5698 9.50784 15.4088 10.9238 15.4088 12.597C15.4088 14.2703 16.5918 15.7074 18.5268 15.7074ZM18.5268 14.3126C17.5593 14.3126 17.0219 13.5194 17.0219 12.597C17.0219 11.6747 17.5593 10.8807 18.5268 10.8807C19.5163 10.8807 20.0537 11.6747 20.0537 12.597C20.0537 13.5194 19.5163 14.3118 18.5268 14.3118V14.3126ZM25.2909 15.7074C26.9251 15.7074 27.8715 14.9134 27.8715 13.8197C27.8715 11.4385 24.3461 12.1676 24.3461 11.3102C24.3461 10.9879 24.7112 10.7305 25.2705 10.7305C26.0015 10.7305 26.669 11.0528 27.034 11.4174L27.6608 10.3222C26.9789 9.7881 26.1356 9.50098 25.269 9.50784C23.7021 9.50784 22.8404 10.3683 22.8404 11.3743C22.8404 13.6907 26.3462 12.8974 26.3462 13.862C26.3462 14.2273 26.0234 14.4878 25.3567 14.4878C24.6258 14.4878 23.7437 14.0802 23.3135 13.6727L22.6453 14.7812C23.2908 15.364 24.2803 15.7074 25.2909 15.7074ZM31.5018 15.7074C33.136 15.7074 34.0824 14.9134 34.0824 13.8197C34.0824 11.4385 30.557 12.1676 30.557 11.3102C30.557 10.9879 30.9228 10.7305 31.4822 10.7305C32.2131 10.7305 32.8798 11.0528 33.2449 11.4174L33.8717 10.3222C33.1911 9.78893 32.3496 9.50161 31.4846 9.50706C29.9138 9.50706 29.0559 10.3676 29.0559 11.3736C29.0559 13.6899 32.561 12.8967 32.561 13.8612C32.561 14.2265 32.2382 14.487 31.5715 14.487C30.8406 14.487 29.9584 14.0795 29.5283 13.6719L28.857 14.7812C29.4806 15.364 30.4692 15.7074 31.5018 15.7074Z'
          fill='white'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M8.42529 6.83594H9.99986V9.54577H11.1303C11.5364 9.54577 11.9385 9.62563 12.3137 9.78081C12.6889 9.93598 13.0298 10.1634 13.3169 10.4502C13.6041 10.7369 13.8318 11.0773 13.9872 11.4519C14.1427 11.8265 14.2226 12.228 14.2226 12.6335C14.2226 13.4524 13.8968 14.2378 13.3169 14.8169C12.737 15.396 11.9505 15.7213 11.1303 15.7213H8.42529V6.83594ZM9.99938 13.8767V14.3554H10.8982L10.8977 14.3544C11.1275 14.3545 11.3551 14.3093 11.5674 14.2215C11.7798 14.1337 11.9727 14.005 12.1352 13.8428C12.2977 13.6805 12.4266 13.4878 12.5145 13.2758C12.6024 13.0638 12.6476 12.8366 12.6476 12.6071C12.6476 12.1437 12.4632 11.6992 12.1351 11.3715C11.8069 11.0438 11.3618 10.8597 10.8977 10.8597H9.99986V13.8767H9.99938Z'
          fill='white'
        />
        <path
          d='M7.70842 33.1641C6.88925 33.1641 6.33671 33.0299 5.91748 32.8765L6.413 30.7877C6.69459 30.8869 6.99066 30.9388 7.2892 30.9412C8.20314 30.9412 8.47058 30.3282 8.47058 29.1216V21.3941H11.271V29.0477C11.271 31.3461 10.394 33.1641 7.70842 33.1641Z'
          fill='white'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M17.8036 31.7777C21.1429 31.7777 23.1612 29.3211 23.1612 26.4619C23.1612 23.6028 21.1413 21.1822 17.8036 21.1822C14.4659 21.1822 12.4814 23.6044 12.4814 26.4619C12.4814 29.3195 14.4996 31.7777 17.8036 31.7777ZM17.8036 29.3942C16.1516 29.3942 15.2336 28.0381 15.2336 26.4611C15.2336 24.8841 16.1508 23.528 17.8036 23.528C19.4901 23.528 20.4089 24.8849 20.4089 26.4611C20.4089 28.0373 19.4901 29.3942 17.8036 29.3942Z'
          fill='white'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M24.3762 16.9141H27.0016V21.4402H28.8865C29.5636 21.4402 30.2341 21.5736 30.8596 21.8328C31.4852 22.092 32.0535 22.4719 32.5323 22.9508C33.0111 23.4297 33.3909 23.9983 33.65 24.624C33.9091 25.2498 34.0425 25.9204 34.0425 26.5977C34.0425 27.9656 33.4993 29.2774 32.5323 30.2447C31.5654 31.2119 30.2539 31.7553 28.8865 31.7553H24.3762V16.9141ZM27.0008 28.6744V29.4738H28.4994L28.4986 29.4722C28.8818 29.4723 29.2612 29.3968 29.6153 29.2502C29.9693 29.1036 30.291 28.8886 30.562 28.6175C30.8329 28.3465 31.0478 28.0247 31.1944 27.6706C31.341 27.3164 31.4164 26.9369 31.4163 26.5535C31.4163 25.7795 31.1089 25.0371 30.5617 24.4898C30.0145 23.9424 29.2724 23.6349 28.4986 23.6349H27.0016V28.6744H27.0008Z'
          fill='white'
        />
      </svg>
    )
  }
  if (isMobile) {
    return (
      <svg
        width='80'
        height='20'
        viewBox='0 0 80 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M49.6736 19.1627C48.7158 19.1627 48.0699 19.0052 47.5798 18.8252L48.1592 16.3738C48.4885 16.4902 48.8346 16.5511 49.1835 16.5539C50.2518 16.5539 50.5643 15.8345 50.5643 14.4184V4.98267H53.8381V14.3317C53.8381 17.0291 52.813 19.1627 49.6736 19.1627Z'
          fill='#1C70EE'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M61.2915 17.5357C65.195 17.5357 67.5543 14.6526 67.5543 11.2971C67.5543 7.94156 65.1933 5.10083 61.2915 5.10083C57.3898 5.10083 55.0701 7.94345 55.0701 11.2971C55.0701 14.6507 57.4294 17.5357 61.2915 17.5357ZM61.2915 14.7384C59.3603 14.7384 58.2875 13.1469 58.2875 11.2961C58.2875 9.44537 59.3595 7.85388 61.2915 7.85388C63.2629 7.85388 64.3372 9.4463 64.3372 11.2961C64.3372 13.1459 63.2629 14.7384 61.2915 14.7384Z'
          fill='#1C70EE'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M68.7007 0H71.7696V5.40353H73.9731C74.7644 5.40353 75.5481 5.56008 76.2793 5.86427C77.0106 6.16846 77.675 6.61433 78.2347 7.17639C78.7944 7.73845 79.2385 8.40571 79.5414 9.1401C79.8443 9.87446 80.0001 10.6615 80.0001 11.4564C80.0001 13.0618 79.3651 14.6013 78.2347 15.7365C77.1043 16.8716 75.5714 17.5093 73.9731 17.5093H68.7007V0ZM71.7688 13.8936V14.8317H73.5203L73.5195 14.8298C73.9675 14.83 74.411 14.7415 74.8248 14.5694C75.2388 14.3973 75.6147 14.145 75.9316 13.8269C76.2482 13.5088 76.4994 13.1311 76.6709 12.7155C76.8422 12.2999 76.9303 11.8544 76.9303 11.4046C76.9303 10.4961 76.5707 9.62491 75.9313 8.98256C75.2916 8.34018 74.4242 7.97932 73.5195 7.97932H71.7696V13.8936H71.7688Z'
          fill='#1C70EE'
        />
        <path
          d='M18.6971 17.5766C22.5347 17.5766 24.8496 14.7428 24.8496 11.4464C24.8496 8.15001 22.5317 5.35781 18.6971 5.35781C14.8626 5.35781 12.5876 8.14849 12.5876 11.4464C12.5876 14.7443 14.9056 17.5766 18.6971 17.5766ZM18.6971 14.8276C16.8013 14.8276 15.7483 13.2642 15.7483 11.4464C15.7483 9.62863 16.8013 8.06369 18.6971 8.06369C20.6359 8.06369 21.6889 9.62863 21.6889 11.4464C21.6889 13.2642 20.6359 14.826 18.6971 14.826V14.8276ZM30.9165 17.5766C34.1188 17.5766 35.9731 16.0117 35.9731 13.8562C35.9731 9.16298 29.0653 10.5999 29.0653 8.91013C29.0653 8.27491 29.7805 7.76767 30.8767 7.76767C32.3088 7.76767 33.6169 8.40289 34.3321 9.12135L35.5602 6.96282C34.224 5.91018 32.5715 5.34431 30.8736 5.35781C27.8035 5.35781 26.1149 7.05379 26.1149 9.03655C26.1149 13.6018 32.9842 12.0385 32.9842 13.9395C32.9842 14.6595 32.3517 15.1729 31.0456 15.1729C29.6132 15.1729 27.8849 14.3697 27.0421 13.5664L25.7327 15.7511C26.9976 16.8998 28.9364 17.5766 30.9165 17.5766ZM41.9756 17.5766C45.1776 17.5766 47.0319 16.0117 47.0319 13.8562C47.0319 9.16298 40.1244 10.5999 40.1244 8.91013C40.1244 8.27491 40.841 7.76767 41.9371 7.76767C43.3693 7.76767 44.6757 8.40289 45.3909 9.12135L46.619 6.96282C45.2856 5.91184 43.6368 5.34555 41.9416 5.35629C38.864 5.35629 37.1832 7.05227 37.1832 9.03503C37.1832 13.6003 44.0508 12.0369 44.0508 13.938C44.0508 14.658 43.4184 15.1714 42.112 15.1714C40.6799 15.1714 38.9516 14.3681 38.1087 13.5648L36.7933 15.7511C38.0152 16.8998 39.9523 17.5766 41.9756 17.5766Z'
          fill='#1C70EE'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M0 0H3.08522V5.43255H5.30027C6.09597 5.43255 6.88385 5.58995 7.61898 5.89581C8.35411 6.20163 9.02204 6.64989 9.58467 7.21502C10.1473 7.78013 10.5936 8.45099 10.8981 9.18936C11.2026 9.92772 11.3594 10.7191 11.3594 11.5183C11.3594 13.1323 10.721 14.6802 9.58467 15.8215C8.44839 16.9628 6.90723 17.604 5.30027 17.604H0V0ZM3.08427 13.9685V14.9119H4.84536L4.84444 14.91C5.29474 14.9101 5.74066 14.8211 6.15669 14.6481C6.57274 14.4751 6.95079 14.2214 7.26919 13.9016C7.58762 13.5817 7.84017 13.2021 8.01244 12.7842C8.1847 12.3663 8.27332 11.9184 8.27318 11.4661C8.27318 10.5528 7.91194 9.67682 7.26891 9.03098C6.62591 8.38513 5.7538 8.02229 4.84444 8.02229H3.08522V13.9685H3.08427Z'
          fill='#1C70EE'
        />
        <path
          d='M50.1555 1.96157C50.1555 3.06325 50.9594 3.8785 52.0677 3.8785C52.3203 3.88467 52.5718 3.8393 52.8065 3.74517C53.0414 3.65101 53.2545 3.51002 53.4333 3.33078C53.6121 3.15152 53.7527 2.93773 53.8467 2.70238C53.9408 2.46702 53.986 2.21498 53.9798 1.96157C53.9798 0.811212 53.1557 0.0446167 52.0677 0.0446167C50.9594 0.0446167 50.1555 0.811212 50.1555 1.96157Z'
          fill='#FFC53D'
        />
      </svg>
    )
  }
  return (
    <svg width='96' height='24' viewBox='0 0 96 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M59.6082 22.9952C58.4589 22.9952 57.6838 22.8063 57.0957 22.5902L57.7909 19.6486C58.1861 19.7883 58.6014 19.8614 59.0201 19.8647C60.3021 19.8647 60.677 19.0015 60.677 17.3021V5.97923H64.6056V17.198C64.6056 20.4349 63.3754 22.9952 59.6082 22.9952Z'
        fill='#1C70EE'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M73.5498 21.0428C78.2339 21.0428 81.065 17.5831 81.065 13.5565C81.065 9.52986 78.2319 6.12099 73.5498 6.12099C68.8676 6.12099 66.084 9.53213 66.084 13.5565C66.084 17.5808 68.9151 21.0428 73.5498 21.0428ZM73.5498 17.686C71.2323 17.686 69.9449 15.7762 69.9449 13.5553C69.9449 11.3344 71.2313 9.42465 73.5498 9.42465C75.9154 9.42465 77.2045 11.3355 77.2045 13.5553C77.2045 15.7751 75.9154 17.686 73.5498 17.686Z'
        fill='#1C70EE'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M82.4409 0H86.1236V6.48423H88.7678C89.7174 6.48423 90.6578 6.67209 91.5353 7.03712C92.4128 7.40216 93.2101 7.93719 93.8818 8.61166C94.5534 9.28613 95.0863 10.0868 95.4498 10.9681C95.8132 11.8493 96.0002 12.7939 96.0002 13.7477C96.0002 15.6741 95.2382 17.5216 93.8818 18.8838C92.5253 20.246 90.6858 21.0112 88.7678 21.0112H82.4409V0ZM86.1226 16.6724V17.7981H88.2245L88.2235 17.7958C88.7611 17.796 89.2933 17.6898 89.7898 17.4832C90.2866 17.2767 90.7377 16.974 91.118 16.5922C91.4979 16.2105 91.7994 15.7574 92.0052 15.2586C92.2107 14.7599 92.3165 14.2253 92.3165 13.6855C92.3165 12.5954 91.885 11.5499 91.1176 10.7791C90.35 10.0082 89.3091 9.57518 88.2235 9.57518H86.1236V16.6724H86.1226Z'
        fill='#1C70EE'
      />
      <path
        d='M22.4363 21.0919C27.0415 21.0919 29.8193 17.6913 29.8193 13.7357C29.8193 9.77998 27.0378 6.42934 22.4363 6.42934C17.8349 6.42934 15.105 9.77816 15.105 13.7357C15.105 17.6931 17.8865 21.0919 22.4363 21.0919ZM22.4363 17.7931C20.1614 17.7931 18.8978 15.917 18.8978 13.7357C18.8978 11.5543 20.1614 9.6764 22.4363 9.6764C24.7629 9.6764 26.0265 11.5543 26.0265 13.7357C26.0265 15.917 24.7629 17.7912 22.4363 17.7912V17.7931ZM37.0996 21.0919C40.9423 21.0919 43.1675 19.214 43.1675 16.6275C43.1675 10.9955 34.8781 12.7199 34.8781 10.6921C34.8781 9.92986 35.7364 9.32117 37.0518 9.32117C38.7704 9.32117 40.34 10.0834 41.1983 10.9456L42.672 8.35536C41.0686 7.09218 39.0856 6.41314 37.0481 6.42934C33.364 6.42934 31.3377 8.46452 31.3377 10.8438C31.3377 16.3222 39.5808 14.4461 39.5808 16.7274C39.5808 17.5914 38.8219 18.2075 37.2546 18.2075C35.5357 18.2075 33.4617 17.2436 32.4504 16.2796L30.8791 18.9013C32.3969 20.2797 34.7235 21.0919 37.0996 21.0919ZM50.3705 21.0919C54.2129 21.0919 56.4381 19.214 56.4381 16.6275C56.4381 10.9955 48.149 12.7199 48.149 10.6921C48.149 9.92986 49.009 9.32117 50.3244 9.32117C52.0429 9.32117 53.6106 10.0834 54.4689 10.9456L55.9426 8.35536C54.3426 7.09418 52.364 6.41463 50.3298 6.42752C46.6366 6.42752 44.6196 8.4627 44.6196 10.842C44.6196 16.3203 52.8608 14.4443 52.8608 16.7255C52.8608 17.5895 52.1019 18.2056 50.5342 18.2056C48.8157 18.2056 46.7417 17.2417 45.7302 16.2778L44.1517 18.9013C45.618 20.2797 47.9426 21.0919 50.3705 21.0919Z'
        fill='#1C70EE'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0 0H3.70227V6.51906H6.36032C7.31517 6.51906 8.26061 6.70794 9.14277 7.07497C10.0249 7.44196 10.8264 7.97987 11.5016 8.65803C12.1768 9.33615 12.7124 10.1412 13.0778 11.0272C13.4431 11.9133 13.6312 12.8629 13.6312 13.8219C13.6312 15.7588 12.8652 17.6163 11.5016 18.9858C10.1381 20.3554 8.28867 21.1248 6.36032 21.1248H0V0ZM3.70112 16.7622V17.8942H5.81443L5.81332 17.8919C6.35368 17.8921 6.88879 17.7853 7.38803 17.5777C7.88729 17.3701 8.34095 17.0657 8.72303 16.6819C9.10515 16.2981 9.4082 15.8425 9.61492 15.341C9.82164 14.8396 9.92798 14.3021 9.92782 13.7594C9.92782 12.6633 9.49433 11.6122 8.7227 10.8372C7.95109 10.0622 6.90456 9.62675 5.81332 9.62675H3.70227V16.7622H3.70112Z'
        fill='#1C70EE'
      />
      <path
        d='M60.1865 2.35387C60.1865 3.6759 61.1512 4.65419 62.4811 4.65419C62.7842 4.6616 63.0861 4.60715 63.3677 4.4942C63.6496 4.38121 63.9053 4.21202 64.1198 3.99694C64.3344 3.78182 64.5032 3.52528 64.616 3.24285C64.7288 2.96042 64.7831 2.65798 64.7757 2.35387C64.7757 0.97345 63.7867 0.0535355 62.4811 0.0535355C61.1512 0.0535355 60.1865 0.97345 60.1865 2.35387Z'
        fill='#FFC53D'
      />
    </svg>
  )
}

const Logo = (props: IProps) => {
  const { langKey, isMobile, pageType, isLoginPage } = props
  const imageMap = {
    sg: 'Bossjob Singapore',
    ph: 'Bossjob Philippines',
    com: 'Bossjob Global & Remote',
    id: 'Bossjob Indonesia',
    jp: 'Bossjob 日本',
    my: 'Bossjob Malaysia',
    hk: 'Bossjob 香港',
    tw: 'Bossjob 台湾',
    tr: 'Bossjob Turkiye'
  }
  const countryKey = getCountryKey()
  const imageTitle = imageMap[countryKey]
  let logoWidth = 124
  let logoHeight = 32
  if (isMobile) {
    logoWidth = 80
    logoHeight = 20
  }
  if (isMobile && pageType === 'h5') {
    logoWidth = 80
    logoHeight = 20
  }

  return (
    <div className={styles.headerLogo}>
      <Link title='Home' to={'/' + langKey}>
        <div className={styles.headerLogoTitle} title={imageTitle}>
          <LogoIcon
            width={logoWidth}
            height={logoHeight}
            isMobile={isMobile}
            isLoginPage={isLoginPage}
          />
        </div>
      </Link>
    </div>
  )
}

export default Logo
