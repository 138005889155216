'use client'
import React, { useEffect, useState } from 'react'
import { FloatButton } from 'antd'
import { useSupportScript } from 'hooks/useSupportScript'
import { pushToResume, pushToBossjobAI } from 'helpers/push'
import { getCrispUnreadMessage, setCrispDisplayState, toggleCustomerService } from 'helpers/crisp'
import styled from '@emotion/styled'
import { Global, css } from '@emotion/react'
import FeedBack from './FeedBack'
import { getCurLanguage } from 'helpers/country'
import { getCookie } from 'helpers/cookies'
import { fetchInterviewDot } from 'store/services/newIndex'

let messageCount = getCrispUnreadMessage()
let crispLoaded = false

const activeIconColor = '#1C70EE'
const iconColor = '#121212'

const RightFloatButtons = (props: any) => {
  const { blogAddressUrl, data, langKey } = props
  const { companyDirectory, supportOnline, AICareerCoach, scamPrevention, backTop } =
    data?.foot || {}
  const sessionid = getCookie('sessionid')
  let ServiceIcon = CustomerServiceIcon
  const [chatStatus, setChatStatus] = useState(false)
  const [showBaxkTop, setShowBackTop] = useState(false)
  const [iconType, setIconType] = useState('')
  const [crispStatus, setCrispStatus] = useState({
    show: false,
    loaded: crispLoaded,
    messageCount
  })
  const [isModalOpen, setIsModalOpen] = useState(false)

  const [isShow, setIsShow] = useState(false)

  const canUseCrisp = useSupportScript(true)
  useEffect(() => {
    // 当前距离顶部的高度
    const handleScroll = () => {
      const showBaxkTop = window.scrollY > window.innerHeight
      setShowBackTop(showBaxkTop)
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])
  useEffect(() => {
    const listener = (window as any).$crisp
    let unmounted = false
    listener.push(
      [
        'on',
        'session:loaded',
        function () {
          crispLoaded = true
          if (!unmounted) {
            // hidden on loaded
            setCrispDisplayState(false)
            messageCount = getCrispUnreadMessage()
            setCrispStatus((pre) => ({ ...pre, messageCount, loaded: true }))
          }
        }
      ],
      ['do', 'chat:hide'],
      ['do', 'chat:close']
    )

    let timer
    const autoFreshUnreadMsg = () => {
      timer = setTimeout(() => {
        if (!(window as any)?.$crisp?.is) {
          autoFreshUnreadMsg()
          return
        }

        if ((window as any)?.$crisp?.is?.('chat:opened')) {
          messageCount = 0
        } else {
          messageCount = getCrispUnreadMessage()
        }

        setCrispStatus((pre) => ({ ...pre, messageCount }))
      }, 10 * 1000)
    }

    autoFreshUnreadMsg()

    return () => {
      setCrispDisplayState(false)
      unmounted = true
      clearTimeout(timer)
      ;['session:loaded', 'chat:closed', 'chat:opened', 'message:received'].forEach((v) => {
        ;(window as any)?.$crisp?.off?.(v)
      })
    }
  }, [])

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // 平滑滚动
    })
  }

  const handleHoverIcon = (iconType) => {
    setIconType(iconType)
  }

  const handletToggleCustomerService = () => {
    toggleCustomerService({ onVisibleChange: setChatStatus })
  }

  ServiceIcon = chatStatus ? CustomerServiceCloseIcon : CustomerServiceIcon

  useEffect(() => {
    if (sessionid) {
      fetchInterviewDot().then((res) => {
        const isShow = res?.data?.data?.quantity > 0
        setIsShow(isShow)
      })
    }
  }, [sessionid])

  return (
    <>
      <Global styles={GlobalStyles} />
      <div>
        <RightFloatButtonsStyle data-role='rightFloatButtonGroup'>
          {canUseCrisp && (
            <FloatButton.Group
              shape='square'
              style={{ right: 16, bottom: showBaxkTop ? 454 : 404 }}
            >
              <FloatButton
                tooltip={!chatStatus ? <div>{supportOnline}</div> : null}
                onClick={() => handletToggleCustomerService()}
                // badge={{ count: crispStatus.messageCount || 10 }}
                onMouseEnter={() => handleHoverIcon('customer-service')}
                onMouseLeave={() => handleHoverIcon('')}
                icon={
                  <ServiceIcon
                    color={iconType === 'customer-service' ? activeIconColor : iconColor}
                  />
                }
              />
            </FloatButton.Group>
          )}

          <FloatButton.Group shape='square' style={{ right: 16, bottom: showBaxkTop ? 392 : 342 }}>
            <FloatButton
              tooltip={<div>{data?.feedback?.title}</div>}
              onClick={() => setIsModalOpen(true)}
              onMouseEnter={() => handleHoverIcon('feedback')}
              onMouseLeave={() => handleHoverIcon('')}
              icon={<FeedBackIcon color={iconType === 'feedback' ? activeIconColor : iconColor} />}
            />
          </FloatButton.Group>
          <div onMouseLeave={() => handleHoverIcon('')}>
            <FloatButton.Group shape='square' style={{ right: 16, bottom: 140 }}>
              <FloatButton
                onMouseEnter={() => handleHoverIcon('Interview')}
                icon={
                  <InterviewIcon color={iconType === 'Interview' ? activeIconColor : iconColor} />
                }
                badge={{ dot: isShow }}
                onClick={(e) => {
                  e.preventDefault()
                  window.open(
                    sessionid
                      ? `/${langKey}/my-jobs/communicated?type=interview`
                      : `/${langKey}/get-started`
                  )
                }}
                target='_blank'
                tooltip={<div>{data?.myJobs?.Interview}</div>}
              />

              <FloatButton
                onMouseEnter={() => handleHoverIcon('home')}
                icon={<HomeIcon color={iconType === 'home' ? activeIconColor : iconColor} />}
                // href='https://www.bossjob.ai/'
                onClick={(e) => {
                  e.preventDefault()
                  pushToBossjobAI()
                }}
                target='_blank'
                tooltip={<div>{companyDirectory}</div>}
              />
              <FloatButton
                onMouseEnter={() => handleHoverIcon('ai')}
                onClick={(e) => {
                  e.preventDefault()
                  pushToResume()
                }}
                target='_blank'
                icon={<AIIcon color={iconType === 'ai' ? activeIconColor : iconColor} />}
                tooltip={<div>{AICareerCoach}</div>}
              />
              <FloatButton
                onMouseEnter={() => handleHoverIcon('safe')}
                href={blogAddressUrl?.ScamPrevention}
                target='_blank'
                icon={<SafeIcon color={iconType === 'safe' ? activeIconColor : iconColor} />}
                tooltip={<div>{scamPrevention}</div>}
              />
              {showBaxkTop ? (
                <FloatButton
                  onMouseEnter={() => handleHoverIcon('backtop')}
                  onClick={() => scrollToTop()}
                  tooltip={<div>{backTop}</div>}
                  icon={<TopIcon color={iconType === 'backtop' ? activeIconColor : iconColor} />}
                />
              ) : null}
            </FloatButton.Group>
          </div>
        </RightFloatButtonsStyle>
      </div>
      {isModalOpen && (
        <FeedBack
          langKey={langKey}
          lang={data}
          open={isModalOpen}
          onCancel={() => setIsModalOpen(false)}
        />
      )}
    </>
  )
}

export default RightFloatButtons

const GlobalStyles = css`
  html,
  body {
    .ant-float-btn-group {
      width: 50px;
      /* border: 1px solid #f6f6f6; */
      border-radius: 50px;
      z-index: 997 !important;
    }
    .ant-badge-dot {
      top: 8px !important;
      left: 18px;
    }
    .ant-float-btn-group-square .ant-float-btn-square:first-child {
      border-start-start-radius: 50px;
      border-start-end-radius: 50px;
    }

    .ant-float-btn-group-square .ant-float-btn-square:last-child {
      border-end-start-radius: 50px;
      border-end-end-radius: 50px;
    }

    .ant-float-btn-group-square-shadow .ant-float-btn-square .ant-float-btn-body {
      border-radius: 50px;
    }

    .ant-float-btn {
      width: 48px;
    }

    .ant-float-btn-group-square-shadow .ant-float-btn-square {
      padding: 8px;
    }

    .ant-float-btn-group-square .ant-float-btn-square:not(:last-child) {
      border: none;
      border-bottom: none;
    }

    .ant-float-btn-group-square-shadow .ant-float-btn-square:not(:last-child) {
      .ant-float-btn-body {
        position: relative;

        &::after {
          content: ' ';
          position: absolute;
          left: 50%;
          /* 距离左侧 50% */
          transform: translateX(-50%);
          /* 水平居中 */
          width: 16px;
          /* 宽度为父元素宽度的一半 */
          height: 1px;
          bottom: -7px;
          margin-top: 4px;
          background: #f6f6f6;
        }
      }
    }

    .ant-float-btn-square,
    .ant-float-btn-square .ant-float-btn-body {
      width: 48px;
      height: 48px;
      border-radius: 50%;
    }
  }
`

const RightFloatButtonsStyle = styled.div`
  /* max-width: 1136px;
  margin: 0 auto; */

  .ant-float-btn-default .ant-float-btn-body:hover {
    background-color: #d8e9ff;
  }

  .ant-float-btn-group-square-shadow {
    box-shadow: 0px 8px 14px -2px rgba(162, 183, 203, 0.15);
  }

  .ant-float-btn {
    box-shadow: none;
  }

  .ant-float-btn .ant-float-btn-body .ant-float-btn-content .ant-float-btn-icon {
    width: auto;
  }
`

const HomeIcon = ({ color = '#515151' }: { color?: string }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='21' viewBox='0 0 20 21' fill='none'>
      <path d='M10 9.71885H5.83333V11.3855H10V9.71885Z' fill={color} />
      <path d='M5.83333 13.0522H10V14.7189H5.83333V13.0522Z' fill={color} />
      <path
        d='M15.8333 18.8855L5 18.8855C3.61929 18.8855 2.5 17.7662 2.5 16.3855V6.72997C2.5 5.62281 3.22823 4.64751 4.28978 4.33298L10.1231 2.60458C11.7254 2.12984 13.3333 3.33047 13.3333 5.00158V5.55218C13.5641 5.55216 13.7956 5.60002 14.0128 5.69695L16.8525 6.96468C17.7532 7.36682 18.3333 8.26105 18.3333 9.24752V16.3855C18.3333 17.7662 17.214 18.8855 15.8333 18.8855ZM15.8333 17.2189C16.2936 17.2189 16.6667 16.8458 16.6667 16.3855V9.24752C16.6667 8.9187 16.4733 8.62062 16.173 8.48658L13.3333 7.21885V17.2189L15.8333 17.2189ZM11.6667 17.2189V5.00158C11.6667 4.44454 11.1307 4.04433 10.5966 4.20258L4.76326 5.93097C4.40941 6.03582 4.16667 6.36092 4.16667 6.72997V16.3855C4.16667 16.8458 4.53976 17.2189 5 17.2189H11.6667Z'
        fill={color}
      />
    </svg>
  )
}

const AIIcon = ({ color = '#515151' }: { color?: string }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='21' viewBox='0 0 20 21' fill='none'>
      <path
        d='M5 2.63551C3.61929 2.63551 2.5 3.7548 2.5 5.13551V15.9688C2.5 17.3496 3.61929 18.4688 5 18.4688H8.21429V16.8022H5C4.53976 16.8022 4.16667 16.4291 4.16667 15.9688V5.13551C4.16667 4.67528 4.53976 4.30218 5 4.30218H15C15.4602 4.30218 15.8333 4.67528 15.8333 5.13551V8.72926H17.5V5.13551C17.5 3.7548 16.3807 2.63551 15 2.63551H5Z'
        fill={color}
      />
      <path
        d='M6.66667 6.80218C6.20643 6.80218 5.83333 7.17528 5.83333 7.63551C5.83333 8.09575 6.20643 8.46885 6.66667 8.46885L12.9167 8.46885C13.3769 8.46885 13.75 8.09575 13.75 7.63551C13.75 7.17528 13.3769 6.80218 12.9167 6.80218L6.66667 6.80218Z'
        fill={color}
      />
      <path
        d='M5.83333 10.9688C5.83333 10.5086 6.20643 10.1355 6.66667 10.1355H8.33333C8.79357 10.1355 9.16667 10.5086 9.16667 10.9688C9.16667 11.4291 8.79357 11.8022 8.33333 11.8022H6.66667C6.20643 11.8022 5.83333 11.4291 5.83333 10.9688Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.2033 11.9516C13.0971 11.709 12.8574 11.5522 12.5925 11.5522C12.3277 11.5522 12.0879 11.709 11.9817 11.9516L9.38915 17.8776C9.24158 18.2149 9.39539 18.608 9.73271 18.7555C10.07 18.9031 10.4631 18.7493 10.6107 18.412L11.1651 17.1447H14.0199L14.5743 18.412C14.7219 18.7493 15.115 18.9031 15.4523 18.7555C15.7896 18.608 15.9435 18.2149 15.7959 17.8776L13.2033 11.9516ZM13.4366 15.8114L12.5925 13.8821L11.7484 15.8114H13.4366Z'
        fill={color}
      />
      <path
        d='M17.0835 11.5522C17.4517 11.5522 17.7502 11.8507 17.7502 12.2188V18.1448C17.7502 18.513 17.4517 18.8114 17.0835 18.8114C16.7153 18.8114 16.4168 18.513 16.4168 18.1448V12.2188C16.4168 11.8507 16.7153 11.5522 17.0835 11.5522Z'
        fill={color}
      />
    </svg>
  )
}

const SafeIcon = ({ color = '#515151' }: { color?: string }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
      <path
        d='M9.37289 12.9414C9.37633 12.9415 9.37976 12.9415 9.3832 12.9415H9.36654C9.36865 12.9415 9.37077 12.9415 9.37289 12.9414Z'
        fill={color}
      />
      <path
        d='M9.05683 12.8736C9.15678 12.9167 9.26415 12.9397 9.37289 12.9414C9.48559 12.9392 9.59669 12.9141 9.69944 12.8676C9.80413 12.8203 9.8979 12.7518 9.97487 12.6665L14.6415 7.43321L13.3915 6.32488L9.3332 10.8749L7.0832 8.49988L5.9082 9.64155L8.7832 12.6832C8.86066 12.7645 8.95373 12.8292 9.05683 12.8736Z'
        fill={color}
      />
      <path
        d='M9.56672 19.0417C9.69693 19.1224 9.84687 19.1656 10.0001 19.1667C10.1532 19.1656 10.3032 19.1224 10.4334 19.0417C10.5751 18.9583 13.8001 16.9583 14.9251 16.125C16.0066 15.3291 16.8929 14.2975 17.5167 13.1083C17.7551 12.6246 17.8913 12.097 17.9167 11.5583V3.91666C17.9251 3.73284 17.8725 3.55141 17.767 3.40067C17.6614 3.24992 17.509 3.13834 17.3334 3.08333L10.2501 0.833328C10.0885 0.774755 9.91158 0.774755 9.75006 0.833328L2.66673 3.08333C2.49113 3.13834 2.33868 3.24992 2.23316 3.40067C2.12764 3.55141 2.07498 3.73284 2.08339 3.91666V11.5833C2.11244 12.1134 2.2485 12.6322 2.48339 13.1083C3.10891 14.2963 3.99488 15.3276 5.07506 16.125C6.23339 16.9917 9.42506 18.9583 9.56672 19.0417ZM3.75006 11.5583V4.52499L10.0001 2.54166L16.2501 4.52499V11.5083C16.2233 11.8159 16.1385 12.1157 16.0001 12.3917C15.4933 13.3324 14.7813 14.147 13.9167 14.775C13.0917 15.4083 10.9251 16.7583 10.0001 17.3333C9.07506 16.7583 6.91673 15.4083 6.08339 14.775C5.22074 14.1449 4.5091 13.3308 4.00006 12.3917C3.86866 12.1306 3.78404 11.8486 3.75006 11.5583Z'
        fill={color}
      />
    </svg>
  )
}

const TopIcon = ({ color = '#515151' }: { color?: string }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
      <path
        d='M17.0059 8.64279C16.6898 8.97726 16.1623 8.99212 15.8279 8.67597L10.8334 3.95517L10.8334 13.8333H9.16674L9.16674 3.92254L4.17429 8.674C3.84091 8.99129 3.31343 8.97825 2.99614 8.64487C2.67885 8.31148 2.69189 7.78401 3.02528 7.46671L9.40353 1.39635C9.72451 1.09086 10.2284 1.08999 10.5505 1.39438L16.9727 7.46475C17.3072 7.78089 17.3221 8.30832 17.0059 8.64279Z'
        fill={color}
      />
      <path
        d='M10.8334 15.1667V17.5C10.8334 17.9602 10.4603 18.3333 10.0001 18.3333C9.53984 18.3333 9.16674 17.9602 9.16674 17.5V15.1667H10.8334Z'
        fill={color}
      />
    </svg>
  )
}

const CustomerServiceIcon = ({ color = '#515151' }: { color?: string }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
      <path
        d='M10 3.33332C7.69882 3.33332 5.83334 5.1988 5.83334 7.49999V13.3333C5.83334 13.7936 5.46024 14.1667 5 14.1667C2.69882 14.1667 0.833336 12.3012 0.833336 9.99999C0.833336 7.90411 2.38078 6.16968 4.39546 5.87686C5.09853 3.44494 7.3416 1.66666 10 1.66666C12.6584 1.66666 14.9015 3.44494 15.6045 5.87686C17.6192 6.16968 19.1667 7.90411 19.1667 9.99999C19.1667 12.0331 17.7106 13.7261 15.7843 14.093C15.412 16.9557 12.9642 19.1667 10 19.1667H9.16667V17.5H10C12.3012 17.5 14.1667 15.6345 14.1667 13.3333V7.49999C14.1667 5.1988 12.3012 3.33332 10 3.33332ZM15.8333 7.64224V12.3577C16.8043 12.0145 17.5 11.0885 17.5 9.99999C17.5 8.91147 16.8043 7.98544 15.8333 7.64224ZM4.16667 7.64224C3.19567 7.98544 2.5 8.91147 2.5 9.99999C2.5 11.0885 3.19567 12.0145 4.16667 12.3577V7.64224Z'
        fill={color}
      />
    </svg>
  )
}

const CustomerServiceCloseIcon = ({ color = '#515151' }: { color?: string }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
      <path
        d='M15.3033 5.8752C15.6287 5.54976 15.6287 5.02212 15.3033 4.69668C14.9778 4.37125 14.4502 4.37125 14.1248 4.69668L9.99998 8.82147L5.8752 4.69668C5.54976 4.37125 5.02212 4.37125 4.69668 4.69668C4.37125 5.02212 4.37125 5.54976 4.69668 5.8752L8.82147 9.99998L4.69668 14.1248C4.37125 14.4502 4.37125 14.9778 4.69668 15.3033C5.02212 15.6287 5.54976 15.6287 5.8752 15.3033L9.99998 11.1785L14.1248 15.3033C14.4502 15.6287 14.9778 15.6287 15.3033 15.3033C15.6287 14.9778 15.6287 14.4502 15.3033 14.1248L11.1785 9.99998L15.3033 5.8752Z'
        fill={color}
      />
    </svg>
  )
}

const FeedBackIcon = ({ color = '#515151' }: { color?: string }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
      <path
        d='M2.5 15.4167C2.5 16.7974 3.61929 17.9167 5 17.9167H15C16.3807 17.9167 17.5 16.7974 17.5 15.4167V7.91666H15.8333V15.4167C15.8333 15.8769 15.4602 16.25 15 16.25H5C4.53976 16.25 4.16667 15.8769 4.16667 15.4167V4.58332C4.16667 4.12309 4.53976 3.74999 5 3.74999H11.6667V2.08332H5C3.61929 2.08332 2.5 3.20261 2.5 4.58332V15.4167Z'
        fill={color}
      />
      <path
        d='M5.83333 12.9167C5.83333 13.3769 6.20643 13.75 6.66667 13.75L12.9167 13.75C13.3769 13.75 13.75 13.3769 13.75 12.9167C13.75 12.4564 13.3769 12.0833 12.9167 12.0833L6.66667 12.0833C6.20643 12.0833 5.83333 12.4564 5.83333 12.9167Z'
        fill={color}
      />
      <path
        d='M5.83333 9.58332C5.83333 10.0436 6.20643 10.4167 6.66667 10.4167H9.16667C9.6269 10.4167 10 10.0436 10 9.58332C10 9.12309 9.6269 8.74999 9.16667 8.74999H6.66667C6.20643 8.74999 5.83333 9.12309 5.83333 9.58332Z'
        fill={color}
      />
      <rect
        x='16.1381'
        y='1.66666'
        width='1.66667'
        height='7.73104'
        rx='0.833333'
        transform='rotate(42.9919 16.1381 1.66666)'
        fill={color}
      />
    </svg>
  )
}

const InterviewIcon = ({ color = '#515151' }: { color?: string }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
      <path
        d='M17.125 4.16748H2.87504C2.43782 4.16748 2.08337 4.54058 2.08337 5.00081V15.8341C2.08337 16.2944 2.43782 16.6675 2.87504 16.6675H17.125C17.5623 16.6675 17.9167 16.2944 17.9167 15.8341V5.00081C17.9167 4.54058 17.5623 4.16748 17.125 4.16748Z'
        stroke={color}
        strokeWidth='1.66667'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M5.83325 2.5V5.83333' stroke={color} strokeWidth='1.66667' strokeLinecap='square' />
      <path
        d='M10.4165 9.58496H5.83313'
        stroke={color}
        strokeWidth='1.66667'
        strokeLinecap='square'
      />
      <path
        d='M14.1665 12.9175H5.83313'
        stroke={color}
        strokeWidth='1.66667'
        strokeLinecap='square'
      />
      <path d='M14.1663 2.5V5.83333' stroke={color} strokeWidth='1.66667' strokeLinecap='square' />
    </svg>
  )
}
