'use client'
import React from 'react'
import { postNotification } from 'bossjob-remote/dist/clientStorage'
import { useSharedData } from 'bossjob-remote/dist/hooks'
import { getStartedRecord } from 'store/services/eventTrack/record'
import { setCookie } from 'helpers/cookies'

import styles from './index.module.scss'
import classNames from 'classnames'

const JobLoginCard = ({ cardType = 'column' }) => {
  const langDic = useSharedData('DICTIONARY') || {}

  const handleStartedRecord = (params = {}) => {
    getStartedRecord(params)
    const oneHourFromNow = new Date(Date.now() + 60 * 60 * 1000)
    setCookie('loginBtnClick', 'Click_Find', oneHourFromNow)
  }

  return (
    <div
      className={classNames({
        [styles.loginCard]: true,
        [styles.loginCardRow]: cardType === 'row'
      })}
      onClick={() => {
        postNotification('SHOW_LOGIN_MODAL')
        sessionStorage.removeItem('chatJobId')
        handleStartedRecord({ source: 'Click_Find' })
      }}
    >
      <span style={{ position: 'absolute', top: 0, right: 10 }}>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='216'
          height='32'
          viewBox='0 0 216 32'
          fill='none'
        >
          <path
            d='M4.98413 31.4318V25.0947H13.6298C15.0671 25.0947 16.1744 24.8033 16.9517 24.2206C17.7436 23.6233 18.1396 22.771 18.1396 21.6639V21.6202C18.1396 20.9063 17.9563 20.2945 17.5896 19.7846C17.2377 19.2747 16.7097 18.8886 16.0057 18.6264C15.3164 18.3642 14.4584 18.2331 13.4318 18.2331H4.98413V12.6607H12.9918C14.3851 12.6607 15.4411 12.3694 16.1597 11.7866C16.8783 11.1893 17.2377 10.3808 17.2377 9.36102V9.31731C17.2377 8.37038 16.893 7.62012 16.2037 7.06653C15.5144 6.51293 14.5758 6.23614 13.3878 6.23614H4.98413V-0.10103H15.9177C18.103 -0.10103 19.9655 0.219471 21.5055 0.860472C23.0601 1.4869 24.248 2.39742 25.0693 3.59201C25.8906 4.77203 26.3013 6.19243 26.3013 7.85321V7.89691C26.3013 9.12064 26.0153 10.2205 25.4433 11.1966C24.8714 12.1727 24.0867 12.9739 23.0894 13.6004C22.1068 14.2122 20.9995 14.5983 19.7676 14.7585V14.9334C21.1902 14.9625 22.4954 15.3048 23.6834 15.9604C24.886 16.616 25.8467 17.4974 26.5653 18.6045C27.2839 19.6972 27.6432 20.9282 27.6432 22.2976V22.3413C27.6432 24.1623 27.1813 25.7575 26.2573 27.1269C25.3333 28.4818 24.0354 29.538 22.3635 30.2955C20.6915 31.0531 18.7189 31.4318 16.4457 31.4318H4.98413ZM0.320312 31.4318V-0.10103H9.64795V31.4318H0.320312Z'
            fill='url(#paint0_linear_473_19012)'
            fillOpacity='0.8'
          />
          <path
            d='M45.9245 32C42.654 32 39.8234 31.3444 37.4329 30.0333C35.0423 28.7076 33.1944 26.821 31.8891 24.3735C30.5985 21.9261 29.9532 19.027 29.9532 15.6763V15.6326C29.9532 12.2819 30.6058 9.39015 31.9111 6.95726C33.2164 4.50981 35.0643 2.63051 37.4549 1.31937C39.8454 -0.00633633 42.6687 -0.669189 45.9245 -0.669189C49.2097 -0.669189 52.0476 -0.00633633 54.4382 1.31937C56.8434 2.64508 58.6914 4.52437 59.982 6.95726C61.2873 9.39015 61.9399 12.2819 61.9399 15.6326V15.6763C61.9399 19.027 61.2873 21.9261 59.982 24.3735C58.6914 26.8064 56.8508 28.6857 54.4602 30.0114C52.0696 31.3371 49.2244 32 45.9245 32ZM45.9245 24.4391C47.2738 24.4391 48.4324 24.0822 49.4004 23.3683C50.3684 22.6545 51.1163 21.642 51.6443 20.3309C52.1723 19.0197 52.4363 17.4682 52.4363 15.6763V15.6326C52.4363 13.8262 52.165 12.2747 51.6223 10.9781C51.0943 9.66695 50.339 8.66174 49.3564 7.96247C48.3884 7.24863 47.2445 6.89171 45.9245 6.89171C44.6339 6.89171 43.4973 7.24134 42.5147 7.94062C41.5467 8.63989 40.7914 9.6451 40.2488 10.9562C39.7208 12.2528 39.4568 13.8116 39.4568 15.6326V15.6763C39.4568 17.4828 39.7208 19.0416 40.2488 20.3527C40.7914 21.6639 41.5467 22.6764 42.5147 23.3902C43.4826 24.0895 44.6193 24.4391 45.9245 24.4391Z'
            fill='url(#paint1_linear_473_19012)'
            fillOpacity='0.8'
          />
          <path
            d='M78.3733 32C75.6307 32 73.2548 31.6212 71.2456 30.8637C69.251 30.1061 67.689 29.0062 66.5597 27.564C65.4451 26.1072 64.8291 24.3444 64.7118 22.2757L64.6898 21.8605H73.2695L73.3135 22.0572C73.4455 22.6254 73.7534 23.1207 74.2374 23.5432C74.7214 23.9656 75.3301 24.3007 76.0634 24.5484C76.7967 24.7815 77.6033 24.898 78.4833 24.898C79.4219 24.898 80.2212 24.7815 80.8812 24.5484C81.5558 24.3153 82.0765 23.9948 82.4431 23.5869C82.8098 23.179 82.9931 22.7128 82.9931 22.1883V22.1665C82.9931 21.4235 82.6338 20.8626 81.9151 20.4838C81.1965 20.1051 79.9572 19.7554 78.1973 19.4349L75.0074 18.8668C71.8542 18.3132 69.4343 17.257 67.7477 15.6982C66.0758 14.1394 65.2398 12.1363 65.2398 9.6888V9.66695C65.2398 7.55456 65.7971 5.72625 66.9117 4.18202C68.041 2.63779 69.5883 1.4432 71.5535 0.598244C73.5188 -0.246712 75.7554 -0.669189 78.2633 -0.669189C81.0938 -0.669189 83.4771 -0.275848 85.413 0.510835C87.3489 1.29752 88.8302 2.41927 89.8568 3.87609C90.8835 5.31834 91.4408 7.02282 91.5288 8.98953L91.5508 9.47028H82.9711L82.9491 9.25176C82.8904 8.66903 82.6558 8.17371 82.2451 7.7658C81.8491 7.34332 81.3138 7.01554 80.6392 6.78245C79.9792 6.54935 79.2239 6.43281 78.3733 6.43281C77.5226 6.43281 76.804 6.54935 76.2174 6.78245C75.6454 7.00097 75.2127 7.3069 74.9194 7.70024C74.6261 8.07901 74.4794 8.50878 74.4794 8.98953V9.01138C74.4794 9.72522 74.8387 10.2788 75.5574 10.6722C76.276 11.0509 77.4273 11.386 79.0112 11.6774L82.2011 12.2455C84.4744 12.6534 86.3516 13.2434 87.8329 14.0156C89.3142 14.7877 90.4141 15.7783 91.1328 16.9875C91.8661 18.1821 92.2327 19.6316 92.2327 21.3361V21.3579C92.2327 23.5286 91.6828 25.4152 90.5828 27.0177C89.4975 28.6056 87.9209 29.8366 85.853 30.7107C83.7851 31.5702 81.2918 32 78.3733 32Z'
            fill='url(#paint2_linear_473_19012)'
            fillOpacity='0.8'
          />
          <path
            d='M108.512 32C105.77 32 103.394 31.6212 101.384 30.8637C99.3898 30.1061 97.8279 29.0062 96.6986 27.564C95.5839 26.1072 94.968 24.3444 94.8506 22.2757L94.8286 21.8605H103.408L103.452 22.0572C103.584 22.6254 103.892 23.1207 104.376 23.5432C104.86 23.9656 105.469 24.3007 106.202 24.5484C106.935 24.7815 107.742 24.898 108.622 24.898C109.561 24.898 110.36 24.7815 111.02 24.5484C111.695 24.3153 112.215 23.9948 112.582 23.5869C112.949 23.179 113.132 22.7128 113.132 22.1883V22.1665C113.132 21.4235 112.773 20.8626 112.054 20.4838C111.335 20.1051 110.096 19.7554 108.336 19.4349L105.146 18.8668C101.993 18.3132 99.5731 17.257 97.8865 15.6982C96.2146 14.1394 95.3786 12.1363 95.3786 9.6888V9.66695C95.3786 7.55456 95.9359 5.72625 97.0505 4.18202C98.1798 2.63779 99.7271 1.4432 101.692 0.598244C103.658 -0.246712 105.894 -0.669189 108.402 -0.669189C111.233 -0.669189 113.616 -0.275848 115.552 0.510835C117.488 1.29752 118.969 2.41927 119.996 3.87609C121.022 5.31834 121.58 7.02282 121.668 8.98953L121.69 9.47028H113.11L113.088 9.25176C113.029 8.66903 112.795 8.17371 112.384 7.7658C111.988 7.34332 111.453 7.01554 110.778 6.78245C110.118 6.54935 109.363 6.43281 108.512 6.43281C107.661 6.43281 106.943 6.54935 106.356 6.78245C105.784 7.00097 105.352 7.3069 105.058 7.70024C104.765 8.07901 104.618 8.50878 104.618 8.98953V9.01138C104.618 9.72522 104.978 10.2788 105.696 10.6722C106.415 11.0509 107.566 11.386 109.15 11.6774L112.34 12.2455C114.613 12.6534 116.49 13.2434 117.972 14.0156C119.453 14.7877 120.553 15.7783 121.272 16.9875C122.005 18.1821 122.372 19.6316 122.372 21.3361V21.3579C122.372 23.5286 121.822 25.4152 120.722 27.0177C119.636 28.6056 118.06 29.8366 115.992 30.7107C113.924 31.5702 111.431 32 108.512 32Z'
            fill='url(#paint3_linear_473_19012)'
            fillOpacity='0.8'
          />
          <path
            d='M136.561 32C134.654 32 132.931 31.7378 131.391 31.2133C129.866 30.6889 128.553 29.924 127.453 28.9188C126.368 27.9136 125.532 26.6899 124.945 25.2476C124.373 23.8054 124.087 22.1665 124.087 20.3309V20.2653H133.019V20.309C133.019 21.1394 133.158 21.8532 133.437 22.4505C133.716 23.0333 134.112 23.4776 134.625 23.7835C135.138 24.0895 135.74 24.2424 136.429 24.2424C137.47 24.2424 138.255 23.9219 138.783 23.2809C139.326 22.6254 139.597 21.6711 139.597 20.4183V-0.10103H148.925V20.4838C148.925 22.9167 148.441 24.9927 147.473 26.7117C146.505 28.4308 145.097 29.7419 143.249 30.6452C141.415 31.5484 139.186 32 136.561 32Z'
            fill='url(#paint4_linear_473_19012)'
            fillOpacity='0.8'
          />
          <path
            d='M169.032 32C165.761 32 162.931 31.3444 160.54 30.0333C158.149 28.7076 156.302 26.821 154.996 24.3735C153.706 21.9261 153.06 19.027 153.06 15.6763V15.6326C153.06 12.2819 153.713 9.39015 155.018 6.95726C156.324 4.50981 158.171 2.63051 160.562 1.31937C162.953 -0.00633633 165.776 -0.669189 169.032 -0.669189C172.317 -0.669189 175.155 -0.00633633 177.545 1.31937C179.951 2.64508 181.799 4.52437 183.089 6.95726C184.394 9.39015 185.047 12.2819 185.047 15.6326V15.6763C185.047 19.027 184.394 21.9261 183.089 24.3735C181.799 26.8064 179.958 28.6857 177.567 30.0114C175.177 31.3371 172.332 32 169.032 32ZM169.032 24.4391C170.381 24.4391 171.54 24.0822 172.508 23.3683C173.476 22.6545 174.224 21.642 174.752 20.3309C175.279 19.0197 175.543 17.4682 175.543 15.6763V15.6326C175.543 13.8262 175.272 12.2747 174.73 10.9781C174.202 9.66695 173.446 8.66174 172.464 7.96247C171.496 7.24863 170.352 6.89171 169.032 6.89171C167.741 6.89171 166.605 7.24134 165.622 7.94062C164.654 8.63989 163.899 9.6451 163.356 10.9562C162.828 12.2528 162.564 13.8116 162.564 15.6326V15.6763C162.564 17.4828 162.828 19.0416 163.356 20.3527C163.899 21.6639 164.654 22.6764 165.622 23.3902C166.59 24.0895 167.726 24.4391 169.032 24.4391Z'
            fill='url(#paint5_linear_473_19012)'
            fillOpacity='0.8'
          />
          <path
            d='M193.341 31.4318V25.0947H201.986C203.424 25.0947 204.531 24.8033 205.308 24.2206C206.1 23.6233 206.496 22.771 206.496 21.6639V21.6202C206.496 20.9063 206.313 20.2945 205.946 19.7846C205.594 19.2747 205.066 18.8886 204.362 18.6264C203.673 18.3642 202.815 18.2331 201.788 18.2331H193.341V12.6607H201.348C202.742 12.6607 203.798 12.3694 204.516 11.7866C205.235 11.1893 205.594 10.3808 205.594 9.36102V9.31731C205.594 8.37038 205.25 7.62012 204.56 7.06653C203.871 6.51293 202.932 6.23614 201.744 6.23614H193.341V-0.10103H204.274C206.46 -0.10103 208.322 0.219471 209.862 0.860472C211.417 1.4869 212.605 2.39742 213.426 3.59201C214.247 4.77203 214.658 6.19243 214.658 7.85321V7.89691C214.658 9.12064 214.372 10.2205 213.8 11.1966C213.228 12.1727 212.443 12.9739 211.446 13.6004C210.463 14.2122 209.356 14.5983 208.124 14.7585V14.9334C209.547 14.9625 210.852 15.3048 212.04 15.9604C213.243 16.616 214.203 17.4974 214.922 18.6045C215.641 19.6972 216 20.9282 216 22.2976V22.3413C216 24.1623 215.538 25.7575 214.614 27.1269C213.69 28.4818 212.392 29.538 210.72 30.2955C209.048 31.0531 207.076 31.4318 204.802 31.4318H193.341ZM188.677 31.4318V-0.10103H198.005V31.4318H188.677Z'
            fill='url(#paint6_linear_473_19012)'
            fillOpacity='0.8'
          />
          <defs>
            <linearGradient
              id='paint0_linear_473_19012'
              x1='108.16'
              y1='-0.669189'
              x2='108.16'
              y2='32'
              gradientUnits='userSpaceOnUse'
            >
              <stop stopColor='white' stopOpacity='0.19' />
              <stop offset='1' stopColor='white' stopOpacity='0.06' />
            </linearGradient>
            <linearGradient
              id='paint1_linear_473_19012'
              x1='108.16'
              y1='-0.669189'
              x2='108.16'
              y2='32'
              gradientUnits='userSpaceOnUse'
            >
              <stop stopColor='white' stopOpacity='0.19' />
              <stop offset='1' stopColor='white' stopOpacity='0.06' />
            </linearGradient>
            <linearGradient
              id='paint2_linear_473_19012'
              x1='108.16'
              y1='-0.669189'
              x2='108.16'
              y2='32'
              gradientUnits='userSpaceOnUse'
            >
              <stop stopColor='white' stopOpacity='0.19' />
              <stop offset='1' stopColor='white' stopOpacity='0.06' />
            </linearGradient>
            <linearGradient
              id='paint3_linear_473_19012'
              x1='108.16'
              y1='-0.669189'
              x2='108.16'
              y2='32'
              gradientUnits='userSpaceOnUse'
            >
              <stop stopColor='white' stopOpacity='0.19' />
              <stop offset='1' stopColor='white' stopOpacity='0.06' />
            </linearGradient>
            <linearGradient
              id='paint4_linear_473_19012'
              x1='108.16'
              y1='-0.669189'
              x2='108.16'
              y2='32'
              gradientUnits='userSpaceOnUse'
            >
              <stop stopColor='white' stopOpacity='0.19' />
              <stop offset='1' stopColor='white' stopOpacity='0.06' />
            </linearGradient>
            <linearGradient
              id='paint5_linear_473_19012'
              x1='108.16'
              y1='-0.669189'
              x2='108.16'
              y2='32'
              gradientUnits='userSpaceOnUse'
            >
              <stop stopColor='white' stopOpacity='0.19' />
              <stop offset='1' stopColor='white' stopOpacity='0.06' />
            </linearGradient>
            <linearGradient
              id='paint6_linear_473_19012'
              x1='108.16'
              y1='-0.669189'
              x2='108.16'
              y2='32'
              gradientUnits='userSpaceOnUse'
            >
              <stop stopColor='white' stopOpacity='0.19' />
              <stop offset='1' stopColor='white' stopOpacity='0.06' />
            </linearGradient>
          </defs>
        </svg>
      </span>
      <div className={styles.cardTop}>
        <p className={styles.cardTitle}>{langDic?.loginCardTitle || 'Smarter Job Matches Await'}</p>
        <p className={styles.cardDesc}>
          {langDic?.loginCardDesc ||
            'Log in to discover AI-curated job opportunities tailored to your skills and goals.'}
        </p>
      </div>
      <div className={styles.cardBottom}>
        <div className={styles.loginButton}>
          {langDic?.loginCardBtn || 'Find Your Dream Job Now'}
        </div>
      </div>
    </div>
  )
}

export default JobLoginCard
