'use client'
import dynamic from 'next/dynamic'
import 'react-quill/dist/quill.snow.css'
import styled from '@emotion/styled'
import { useEffect, useRef, useMemo, useState, useTransition } from 'react'

interface TextEditorInterface {
  value?: string
  onChange?: (value?: string) => void
  placeholder?: string
  className?: string
  maxCounter?: number
  handleEditorChange?: (a?: number, b?: number) => void
}

const QuillNoSSRWrapper = dynamic(() => import('react-quill'), {
  ssr: false,
  loading: () => <p>Loading ...</p>
})

const modules = {
  toolbar: [[{ list: 'ordered' }, { list: 'bullet' }, 'bold']]
}

const formats = ['list', 'bullet', 'bold']

const TextEditor = (props: TextEditorInterface) => {
  const {
    value,
    onChange,
    placeholder = 'Please enter',
    maxCounter,
    handleEditorChange,
    ...restProps
  } = props
  const ref = useRef(null)
  const [total, setTotal] = useState(0)
  const [isPending, startTransition] = useTransition()

  const handleChange = (content: string) => {
    const parser = new DOMParser()
    const doc = parser.parseFromString(content, 'text/html')

    const text = doc.body.textContent || ''
    if (maxCounter) {
      setTotal(text.length)
    }
    onChange && onChange(content)
    if (handleEditorChange) {
      startTransition(() => {
        handleEditorChange(text.length, maxCounter)
      })
    }
  }

  useEffect(() => {
    //
    if (maxCounter) {
      //
      const parser = new DOMParser()
      const doc = parser.parseFromString(value || '', 'text/html')
      const text = doc.body.textContent || ''

      setTotal(text.length)
    }
  }, [value, maxCounter])

  const rangeError = useMemo(() => total > maxCounter, [total, maxCounter])

  return (
    <TextEditorWrap className={rangeError ? 'range-error' : ''}>
      {QuillNoSSRWrapper ? (
        <QuillNoSSRWrapper
          // ref={ref}
          theme='snow'
          modules={modules}
          formats={formats}
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
          {...restProps}
        />
      ) : null}
      {Boolean(maxCounter) && (
        <div className='counter'>
          {total} / {maxCounter}
        </div>
      )}
    </TextEditorWrap>
  )
}

export default TextEditor

const TextEditorWrap = styled.div`
  position: relative;
  .quill {
    .ql-toolbar.ql-snow {
      border-bottom-left-radius: 0px !important;
      border-bottom-right-radius: 0px !important;
      border-top-left-radius: 8px !important;
      border-top-right-radius: 8px !important;
    }
    .ql-container.ql-snow {
      background: #ffffff;
      border-bottom-left-radius: 8px !important;
      border-bottom-right-radius: 8px !important;
      border-top-left-radius: 0 !important;
      border-top-right-radius: 0 !important;
      padding-bottom: 16px;
    }

    .ql-editor {
      min-height: 150px;
      li::before {
        display: inline;
      }
      counter-reset: global-list; /* 全局计数器 */
    }
    .ql-editor ol,
    .ql-editor ul {
      padding-left: 0.5em;
    }
    .ql-editor ol li {
      counter-increment: global-list; /* 递增全局计数器 */
    }
    .ql-editor ol li:before {
      content: counter(global-list, decimal) '. ';
    }
    &:hover > * {
      border-color: #4096ff;
    }
    // class name: quill
    & > div {
      // class name: ql-toolbar ql-snow
      border-top-left-radius: 8px !important;
      border-top-right-radius: 8px !important;
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      & > div:first-child {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }

      // class name: ql-container ql-snow
      & > div:nth-child(2) {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        height: 150px;
      }
    }
  }
  &.range-error {
    .ql-toolbar.ql-snow {
      border-color: red;
    }
    .ql-container.ql-snow {
      border-color: red;
    }
  }
  .counter {
    position: absolute;
    bottom: 1px;
    right: 1px;
    width: calc(100% - 2px);
    background-color: #fff;
    padding: 4px 10px;
    font-size: 14px;
    border-radius: 10px;
    text-align: right;
    color: var(--text-icon-04646566, #646566);

    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }
`
