import React from 'react'
import styles from '../../Footer.module.scss'

import SocialList from '../common/SocialList'
import { getCountryKey } from 'helpers/country'

const SocialLink = (props: any) => {
  const { data, isMobile, web3LandingPage } = props
  const { followUs, workingDate, workTitle, workingDateForJp, social } = data?.foot || {}

  const countryKey = getCountryKey()

  let workingInfo = `${workTitle}: ${workingDate}`

  if (countryKey == 'jp') {
    workingInfo = workingDateForJp
  }

  return (
    <div className={styles.footerMobileSocialLinks}>
      <div className={styles.footerMobileSocialList}>
        <div className={styles.footerMobileSocialLinksText}>Social</div>
        <SocialList isMobile={isMobile} web3LandingPage={web3LandingPage} />
      </div>
    </div>
  )
}

export default SocialLink
