'use client'
import React from 'react'
import { usePathname } from 'next/navigation'

import styles from '../Footer.module.scss'
import Copyright from './Mobile/Copyright'
import About from './Mobile/About'
import SocialLink from './Mobile/SocialLink'
import classNames from 'classnames'
const Mobile = (props: any) => {
  const { data, countryKey, langKey, blogAddressUrl, web3LandingPage } = props
  const pathName = usePathname()

  return (
    <div
      className={classNames([
        styles.footerMobileContainer,
        /\/job\/.*/.test(pathName) && styles.footerMobileContainerJob
      ])}
    >
      {/* <TalentsLink data={data} {...restProps} /> */}
      {/* <RecruiterLink data={data} {...restProps} /> */}
      {/* <PopularJobsLink data={data} {...restProps} /> */}
      {/* <Company data={data} /> */}
      {/* <DownloadApp data={data} /> */}
      <About
        langKey={langKey}
        data={data}
        countryKey={countryKey}
        blogAddressUrl={blogAddressUrl}
      />
      <SocialLink data={data} isMobile={true} web3LandingPage={web3LandingPage} />
      <Copyright data={data} countryKey={countryKey} />
    </div>
  )
}

export default Mobile
