import React, { useState, useRef, useEffect } from 'react'

/* Vendors */
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  EmailShareButton
} from 'react-share'

/* Components */
import Modal from 'components/Modal'
import Text from 'components/Text'

/* Service */
import { fetchUserShare } from 'store/services/users/share'

/* Styles */
import styles from './ModalShare.module.scss'

/* Images */
import { FacebookIcon, LinkedinIcon, CopyIcon } from 'images'
import { useSharedData } from 'bossjob-remote/dist/hooks'
interface ModalShareProps {
  jobDetailUrl?: string
  isShowModalShare?: boolean
  handleShowModalShare?: Function
  selectedJob?: any
  dictionary?: any
}
const ModalShare = ({
  jobDetailUrl: url,
  isShowModalShare,
  handleShowModalShare,
  selectedJob,
  dictionary
}: ModalShareProps) => {
  const dic = useSharedData('DICTIONARY') ?? dictionary ?? { jobDetail: {} }
  const shareModal = dic?.jobDetail?.shareModal ?? {}
  const email = dic?.jobDetail?.accountSetting?.email ?? 'Email'
  const jobLinkRef = useRef(null)
  const [isDoneCopy, setIsDoneCopy] = useState(false)
  // this share feature is no needed login, but it's better if user login
  const meInfo = useSharedData('ME')
  const [jobDetailUrl, setJobDetailUrl] = useState('')
  useEffect(() => {
    if (typeof window != 'undefined') {
      setJobDetailUrl(`${location?.origin}${url}`)
    }
  }, [url])

  const handleCopyLink = (link) => {
    navigator.clipboard.writeText(link)
    setIsDoneCopy(true)
    setTimeout(() => {
      setIsDoneCopy(false)
    }, 5000)
  }

  const tokenData = () => {
    if (!meInfo?.id) {
      return
    }
    const data = {
      jobseeker_id: meInfo.id,
      recruiter_id: selectedJob.recruiter?.id,
      company_id: selectedJob.company.id,
      job_id: selectedJob.id
    }

    fetchUserShare(data)
      .then(({ status }: any) => {
        if (status === 200) {
          //
        }
      })
      .catch(() => {
        //
      })
  }

  return (
    <Modal
      headerTitle={shareModal.title}
      showModal={isShowModalShare}
      handleModal={handleShowModalShare}
    >
      <div className={styles.ModalShare}>
        <div className={styles.ModalShareList}>
          <div className={styles.ModalShareItem} onClick={tokenData}>
            <FacebookShareButton url={jobDetailUrl} className={styles.ModalShareItemLink}>
              <img
                src={FacebookIcon}
                alt='facebook'
                height='56px'
                width='56px'
                className={styles.ModalShareItemImg}
              />
              <Text textStyle='base'>{shareModal.facebook}</Text>
            </FacebookShareButton>
          </div>
          <div className={styles.ModalShareItem} onClick={tokenData}>
            <TwitterShareButton url={jobDetailUrl} className={styles.ModalShareItemLink}>
              <TwitterIcon />
              <Text textStyle='base' textColor='warmgrey'>
                {shareModal.twitter}
              </Text>
            </TwitterShareButton>
          </div>
          <div className={styles.ModalShareItem} onClick={tokenData}>
            <LinkedinShareButton url={jobDetailUrl} className={styles.ModalShareItemLink}>
              <img
                src={LinkedinIcon}
                alt='linkedIn'
                height='56px'
                width='56px'
                className={styles.ModalShareItemImg}
              />
              <Text textStyle='base' textColor='warmgrey'>
                {shareModal.linkedin}
              </Text>
            </LinkedinShareButton>
          </div>

          <div className={styles.ModalShareItem} onClick={tokenData}>
            <WhatsappShareButton url={jobDetailUrl} className={styles.ModalShareItemLink}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='50'
                height='50'
                viewBox='0 0 50 50'
                fill='none'
              >
                <g clip-path='url(#clip0_3544_881)'>
                  <path
                    d='M25 50C11.195 50 0 38.805 0 25C0 11.195 11.195 0 25 0C38.805 0 50 11.195 50 25C50 38.805 38.805 50 25 50Z'
                    fill='#25D366'
                  />
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M36.695 13.36C33.595 10.255 29.465 8.54504 25.07 8.54004C16.015 8.54004 8.64 15.91 8.64 24.97C8.64 27.865 9.395 30.695 10.835 33.185L8.505 41.7L17.215 39.415C19.615 40.725 22.315 41.415 25.065 41.415H25.07C34.125 41.415 41.495 34.045 41.5 24.985C41.505 20.595 39.8 16.465 36.695 13.36ZM25.075 38.64H25.07C22.62 38.64 20.215 37.98 18.12 36.735L17.62 36.44L12.45 37.795L13.83 32.755L13.505 32.24C12.14 30.065 11.415 27.555 11.415 24.97C11.42 17.44 17.545 11.315 25.08 11.315C28.73 11.315 32.155 12.74 34.735 15.32C37.315 17.9 38.735 21.335 38.73 24.98C38.73 32.515 32.6 38.64 25.075 38.64ZM32.565 28.41C32.155 28.205 30.135 27.21 29.76 27.075C29.385 26.94 29.11 26.87 28.835 27.28C28.56 27.69 27.775 28.615 27.535 28.89C27.295 29.165 27.055 29.2 26.645 28.995C26.235 28.79 24.91 28.355 23.345 26.955C22.125 25.865 21.3 24.52 21.06 24.11C20.82 23.7 21.035 23.475 21.24 23.27C21.425 23.085 21.65 22.79 21.855 22.55C22.06 22.31 22.13 22.14 22.265 21.865C22.4 21.59 22.335 21.35 22.23 21.145C22.125 20.94 21.305 18.92 20.965 18.095C20.63 17.295 20.295 17.405 20.04 17.39C19.8 17.38 19.525 17.375 19.255 17.375C18.98 17.375 18.535 17.48 18.16 17.89C17.785 18.3 16.725 19.295 16.725 21.315C16.725 23.335 18.195 25.29 18.4 25.56C18.605 25.835 21.295 29.98 25.415 31.76C26.395 32.185 27.16 32.435 27.755 32.625C28.74 32.94 29.635 32.895 30.34 32.79C31.13 32.67 32.77 31.795 33.11 30.84C33.45 29.88 33.45 29.06 33.35 28.89C33.25 28.72 32.975 28.62 32.565 28.41Z'
                    fill='white'
                  />
                </g>
                <defs>
                  <clipPath id='clip0_3544_881'>
                    <rect width='50' height='50' fill='white' />
                  </clipPath>
                </defs>
              </svg>
              <Text textStyle='base' textColor='warmgrey'>
                {shareModal.whatsapp}
              </Text>
            </WhatsappShareButton>
          </div>
          <div className={styles.ModalShareItem} onClick={tokenData}>
            <EmailShareButton url={jobDetailUrl} className={styles.ModalShareItemLink}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='50'
                height='50'
                viewBox='0 0 50 50'
                fill='none'
              >
                <g clip-path='url(#clip0_3544_894)'>
                  <path
                    d='M0 25C0 38.7851 11.2149 50 25 50C38.7851 50 50 38.7851 50 25C50 11.2149 38.7851 0 25 0C11.2149 0 0 11.2149 0 25Z'
                    fill='white'
                  />
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M25 50C11.2149 50 0 38.7851 0 25C0 11.2149 11.2149 0 25 0C38.7851 0 50 11.2149 50 25C50 38.7851 38.7851 50 25 50ZM37.9646 33.7823C38.2729 33.7823 38.5237 33.5315 38.5237 33.2232V17.5282L25.804 27.629C25.5687 27.816 25.2842 27.9095 24.9999 27.9095C24.7156 27.9095 24.4312 27.8159 24.1958 27.629L11.4762 17.5282V33.2232C11.4762 33.5315 11.7271 33.7823 12.0353 33.7823H37.9646ZM36.0154 16.2177L25 24.9652L13.9845 16.2177H36.0154ZM41.11 16.7768V33.2234C41.11 34.9577 39.6989 36.3687 37.9646 36.3687H12.0353C10.3009 36.3687 8.89004 34.9577 8.89004 33.2234V16.7768C8.89004 15.0425 10.301 13.6315 12.0353 13.6315H37.9646C39.6989 13.6314 41.11 15.0425 41.11 16.7768Z'
                    fill='#BCBCBC'
                  />
                </g>
                <defs>
                  <clipPath id='clip0_3544_894'>
                    <rect width='50' height='50' fill='white' />
                  </clipPath>
                </defs>
              </svg>
              <Text textStyle='base' textColor='warmgrey'>
                {email}
              </Text>
            </EmailShareButton>
          </div>
        </div>
        <div className={styles.ModalShareFooter} onClick={tokenData}>
          <Text textStyle='lg'> {shareModal.link}</Text>
          {isDoneCopy ? (
            <div className={styles.ModalShareFooterTooltip}>
              <Text textStyle='sm' textColor='white'>
                {shareModal.linkCopied}
              </Text>
            </div>
          ) : null}
          <div className={styles.ModalShareFooterLink}>
            <input
              value={jobDetailUrl}
              ref={jobLinkRef}
              onClick={() => jobLinkRef.current.select()}
              className={styles.ModalShareFooterLinkText}
              readOnly
            />
            <div
              onClick={() => handleCopyLink(jobDetailUrl)}
              className={styles.ModalShareFooterCopy}
            >
              <img src={CopyIcon} alt='close' height='18px' width='18px' />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}
const TwitterIcon = () => (
  <svg width='55' height='55' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M0 27.5C0 42.6878 12.3122 55 27.5 55C42.6878 55 55 42.6878 55 27.5C55 12.3122 42.6878 0 27.5 0C12.3122 0 0 12.3122 0 27.5Z'
      fill='black'
    />
    <path
      d='M15.5518 16.3281H23.2179L28.662 23.7851L35.2681 16.3281H37.6826L29.7523 25.278L39.5312 38.6719H31.8669L25.5495 30.0202L17.8833 38.6719H15.4688L24.4592 28.5274L15.5518 16.3281ZM19.249 18.1901L32.8743 36.8099H35.834L22.2087 18.1901H19.249Z'
      fill='white'
    />
  </svg>
)

export default ModalShare
